import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Note } from '../shared';
import { removeUnderscores, chunkArray, addKeys } from '../../lib/utils';
import { pump_pm_checklist_fields, pump_pm_fluid_fields, pump_pm_power_fields }
  from '../../config/constants/pump_pm_checklist';

const Notes = (props) => {
  const {
    form,
    per_row,
    work_order,
    updateWorkOrderValue,
    work_order_call_types,
    selected_labor_codes,
    calltype_notetypes,
    read_only,
  } = props;

  const defaults = work_order?.workorder_note_defaults ?? [];

  const [note_types, setNoteTypes] = useState([]);
  const [form_rows, setFormRows] = useState([]);
  const [note_mapping, setNoteMapping] = useState({});

  // these note fields are already included on each PumpPMChecklistSection
  const pump_note_types = [
    ...pump_pm_checklist_fields,
    ...pump_pm_power_fields,
    ...pump_pm_fluid_fields,
  ];
  const ignore = pump_note_types.map((ppnf) => ppnf.note_type_id);

  const handleChange = async () => {
    // retrieve note fields from the form by name
    const note_field_names = note_types.map((nt) => nt.note_type);
    const note_fields = form.getFieldsValue(note_field_names);

    // map the form state to what the db is expecting (ignore nulls so rows aren't created)
    const new_notes = Object.keys(note_fields)
      .filter((key) => note_fields[key])
      .map((key) => (
        {
          note_type_id: note_mapping[key],
          text: note_fields[key],
        }
      ));

    await updateWorkOrderValue(work_order.localId, ['workorder_notes'], new_notes);

    // keep track in its own object separately to make textarea population cleaner
    await updateWorkOrderValue(work_order.localId, ['workorder_note_defaults'], note_fields);
  };

  const filterNoteTypes = () => calltype_notetypes.reduce((acc, note_type) => {
    const time_adj_labor_code = selected_labor_codes.some((code) => (code.name === 'Time Adjustment (Positive)') || code.name === 'Time Adjustment (Negative)');
    if (note_type.note_type === 'time_adjustment_reason' && !time_adj_labor_code) return [...acc];

    if (work_order_call_types.includes(note_type.call_type_id) && !ignore.includes(note_type.note_type_id)) {
      if (!acc.find((field) => field.note_type_id === note_type.note_type_id)) return [...acc, note_type];
      return [...acc];
    }
    return [...acc];
  }, []);

  useEffect(() => {
    // filter out notes for this call type
    const fields = filterNoteTypes();

    // store an accessible mapping to avoid nested looping to match note_type_id in onChange()
    const note_type_mapping = fields.reduce((acc, field) => {
      acc[field.note_type] = field.note_type_id;
      return acc;
    }, {});

    setNoteMapping(note_type_mapping);
    setNoteTypes(fields);
  }, [work_order_call_types, calltype_notetypes, selected_labor_codes]);

  useEffect(() => {
    const note_fields = note_types.map((note) => (
      <Note
        // set the field key so we can search and retrieve it from the form when saving
        name={note.note_type}
        title={removeUnderscores(note.note_type)}
        onChange={(e) => handleChange(note, e.target.value)}
        validation_rules={note.validation_rules}
        defaultValue={defaults[note.note_type]}
        disabled={read_only}
      />
    ));

    // chunk fields into rows of 4 elements
    const chunked_fields = chunkArray(note_fields, per_row);
    const rows = chunked_fields.map((chunk) => (
      <Row gutter={[24, 24]}>
        {addKeys(chunk.map((field) => (
          <Col span={24}>{field}</Col>)), false)}
      </Row>
    ));
    setFormRows(rows);
  }, [note_types]);

  return (
    <>
      {addKeys(form_rows, false)}
    </>
  );
};

export default Notes;
