import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import 'moment-timezone';
import {
  Alert, Button, Drawer, List, Tabs, notification as Notification,
} from 'antd';
import { useLiveQuery } from 'dexie-react-hooks';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { sortObjectsBy } from '../../lib/utils';
import { deleteSiteLog, siteLogsLiveQuery, notificationsLiveQuery } from '../../db/site_logs';

const { TabPane } = Tabs;

const NotificationDrawer = (props) => {
  const { visible, setDrawerVisible } = props;

  const { user_settings } = useContext(UserSettingsContext);
  const [activeTab, setActiveTab] = useState('notifications');
  const logs = useLiveQuery(() => siteLogsLiveQuery()) || [];
  const notifications = useLiveQuery(() => notificationsLiveQuery()) || [];

  const timezone = user_settings?.timezone ?? 'America/Chicago';

  // put errors at the top, then the rest can stay in original order
  const error_notifications = notifications.filter((n) => n.type === 'error');
  const other_notifications = notifications.filter((n) => n.type !== 'error');
  const sorted_notfications = [...error_notifications, ...other_notifications];

  const sorted_logs = sortObjectsBy(logs, 'timestamp').reverse();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const clearAllNotifications = () => {
    sorted_notfications.forEach((notification) => {
      deleteSiteLog(notification.id);
    });
  };

  // clear all notifications when opening
  useEffect(() => {
    if (visible) Notification.destroy();
  }, [visible]);

  const tabBarExtraContent = () => {
    if (activeTab === 'notifications' && sorted_notfications.length > 0) return { right: <Button onClick={() => clearAllNotifications()}>Clear All</Button> };
    return null;
  };

  return (
    <Drawer
      width="33%"
      visible={visible}
      onClose={() => setDrawerVisible(false)}
      destroyOnClose
    >
      <Tabs defaultActiveKey="notifications" style={{ paddingTop: '1rem' }} tabBarExtraContent={tabBarExtraContent()} onChange={handleTabChange}>
        <TabPane tab="Notifications" key="notifications">
          {sorted_notfications.length > 0 ? sorted_notfications.map((notification) => (
            <Alert
              style={{ marginBottom: '15px' }}
              key={notification.key}
              type={notification.type}
              message={(notification.type === 'error' ? 'Error' : notification.message)}
              description={(notification.type === 'error' ? notification.message : '')}
              closable
              showIcon
              onClose={() => deleteSiteLog(notification.id)}
            />
          )) : <div>You do not have any notifications at this time</div>}
        </TabPane>
        <TabPane tab="Logs" key="logs">
          <List>
            {sorted_logs.map((log) => (
              <List.Item key={log.key}>
                <List.Item.Meta
                  title={moment.tz(log.timestamp, timezone).format('MM/DD/YYYY HH:mm:ss.SS')}
                  description={log.message}
                />
              </List.Item>
            ))}
          </List>
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default withRouter(NotificationDrawer);
