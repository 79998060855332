import notificationTypes from './notificationTypes';

export { default as STATUS_TO_OWNERSHIP } from './ownership_status_enum';
export { TIMEZONES, DATE_FORMAT } from './date-time';
export { default as TIME_ADJUSTMENT_LABOR_CODES } from './labor-codes';

export { notificationTypes };

export const NETWORK_ERROR = 'Network Error';
export const potentialHazards = [
  { label: 'Body Position', value: 'body_position' },
  { label: 'Lifting/Hazard', value: 'lifting_hazard' },
  { label: 'Hazardous Chemical Exposure', value: 'chemical_exposure' },
  { label: 'Elevated Load or Work', value: 'elevated_load' },
  { label: 'Pinch, Crush, or Striking Hazard', value: 'pinch_crush' },
  { label: 'Elevated Body Temperature', value: 'elevated_body_temp' },
  { label: 'Fall from Elevation', value: 'fall_from_elevation' },
  { label: 'Equipment Hazards', value: 'equipment_hazard' },
  { label: 'Sharp Edges', value: 'sharp_edges' },
  { label: 'H2S', value: 'h2s' },
  { label: 'Elevated Equipment Temps', value: 'elevated_equipment_temps' },
  { label: 'Fire or Explosion Potential', value: 'fire_potential' },
  { label: 'Material Handling', value: 'materials_handling' },
  { label: 'Hot/Cold Surfaces', value: 'hot_cold_surfaces' },
  { label: 'Slick/Slippery Surfaces', value: 'slick_surfaces' },
  { label: 'Electrical Shock', value: 'electrical_shock' },
  { label: 'Ladders/Fall Protection', value: 'ladders_fall_protection' },
  { label: 'Temporary Employee/Subs', value: 'temp_employees' },
  { label: 'Excavation Hazard', value: 'excavation_hazard' },
  { label: 'Confined Spaces', value: 'confined_spaces' },
  { label: 'Environmental Extremes', value: 'enviromental_extremes' },
  { label: 'Laceration Potential', value: 'laceration_potential' },
  { label: 'Slips, Trips, Falls', value: 'slips_trips_falls' },
];

export const controlsPractices = [
  { label: 'Confined Space Entry Procedure', value: 'confined_space_entry_procedure' },
  { label: 'Use of PPE', value: 'use_of_ppe' },
  { label: 'Lockout/Tagout', value: 'loto' },
  { label: 'Site/Job Orientation', value: 'site_orientation' },
  { label: 'Use of Barricades', value: 'use_of_barricades' },
  { label: 'Review Emergency Action Plan', value: 'review_emergency_plan' },
  { label: 'Contact "One Call"', value: 'contact_one_call' },
  { label: 'Sampling/Monitoring', value: 'sample_monitoring' },
  { label: 'Spill Kit/Containment', value: 'spill_kit' },
  { label: 'Drinking Water/Fluids', value: 'drinking_water' },
  { label: 'Fall Protection/Ladder', value: 'fall_protection' },
  { label: 'Equipment Cool-down', value: 'equipment_cool_down' },
  { label: 'Cut Resistant Gloves', value: 'cut_resistant_gloves' },
  { label: 'Heat Resistant Gloves/Sleeves', value: 'heat_resistant_gloves' },
  { label: 'Chemical Gloves', value: 'chemical_gloves' },
  { label: 'Restrict Access', value: 'restrict_access' },
  { label: 'Electrician Needed', value: 'electrician_needed' },
  { label: 'Depressurize', value: 'depressurize' },
  { label: 'Fire Extinguisher', value: 'fire_extinguisher' },
];

export const cylinderCompressionData = [
  { label: 'Cylinder 1', name: 'cylinder1_compression' },
  { label: 'Cylinder 2', name: 'cylinder2_compression' },
  { label: 'Cylinder 3', name: 'cylinder3_compression' },
  { label: 'Cylinder 4', name: 'cylinder4_compression' },
  { label: 'Cylinder 5', name: 'cylinder5_compression' },
  { label: 'Cylinder 6', name: 'cylinder6_compression' },
  { label: 'Cylinder 7', name: 'cylinder7_compression' },
  { label: 'Cylinder 8', name: 'cylinder8_compression' },
  { label: 'Cylinder 9', name: 'cylinder9_compression' },
  { label: 'Cylinder 10', name: 'cylinder10_compression' },
  { label: 'Cylinder 11', name: 'cylinder11_compression' },
  { label: 'Cylinder 12', name: 'cylinder12_compression' },
];

export const cylinderExhaustData = [
  { label: 'Cylinder 1', name: 'engine_valve_recession_cylinder1_exhaust' },
  { label: 'Cylinder 2', name: 'engine_valve_recession_cylinder2_exhaust' },
  { label: 'Cylinder 3', name: 'engine_valve_recession_cylinder3_exhaust' },
  { label: 'Cylinder 4', name: 'engine_valve_recession_cylinder4_exhaust' },
  { label: 'Cylinder 5', name: 'engine_valve_recession_cylinder5_exhaust' },
  { label: 'Cylinder 6', name: 'engine_valve_recession_cylinder6_exhaust' },
  { label: 'Cylinder 7', name: 'engine_valve_recession_cylinder7_exhaust' },
  { label: 'Cylinder 8', name: 'engine_valve_recession_cylinder8_exhaust' },
  { label: 'Cylinder 9', name: 'engine_valve_recession_cylinder9_exhaust' },
  { label: 'Cylinder 10', name: 'engine_valve_recession_cylinder10_exhaust' },
  { label: 'Cylinder 11', name: 'engine_valve_recession_cylinder11_exhaust' },
  { label: 'Cylinder 12', name: 'engine_valve_recession_cylinder12_exhaust' },
];

export const cylinderIntakeData = [
  { label: 'Cylinder 1', name: 'engine_valve_recession_cylinder1_intake' },
  { label: 'Cylinder 2', name: 'engine_valve_recession_cylinder2_intake' },
  { label: 'Cylinder 3', name: 'engine_valve_recession_cylinder3_intake' },
  { label: 'Cylinder 4', name: 'engine_valve_recession_cylinder4_intake' },
  { label: 'Cylinder 5', name: 'engine_valve_recession_cylinder5_intake' },
  { label: 'Cylinder 6', name: 'engine_valve_recession_cylinder6_intake' },
  { label: 'Cylinder 7', name: 'engine_valve_recession_cylinder7_intake' },
  { label: 'Cylinder 8', name: 'engine_valve_recession_cylinder8_intake' },
  { label: 'Cylinder 9', name: 'engine_valve_recession_cylinder9_intake' },
  { label: 'Cylinder 10', name: 'engine_valve_recession_cylinder10_intake' },
  { label: 'Cylinder 11', name: 'engine_valve_recession_cylinder11_intake' },
  { label: 'Cylinder 12', name: 'engine_valve_recession_cylinder12_intake' },
];

export const applicationType = [
  { label: 'N/A', value: 2 },
  { label: 'Flowing Wellhead', value: 1 },
  { label: 'Vapor Recovery', value: 8 },
  { label: 'Plunger Lift', value: 5 },
  { label: 'Backside Application', value: 9 },
  { label: '(CMB) Coal Bed Methane', value: 6 },
  { label: 'Gas Lift', value: 4 },
  { label: 'TriPlex Pump', value: 11 },
  { label: 'Flash Gas', value: 7 },
  { label: 'CDP', value: 10 },
  { label: 'Pipeline Booster', value: 3 },
];

export const minutes = [
    ...[...Array(12)].map((_, i) => ({ label: i * 5, value: i *  5 })) // eslint-disable-line
];

export const hours = [
    ...[...Array(72)].map((_, i) => ({ label: i, value: i })) // eslint-disable-line
];

export const WORK_ORDER_CALL_TYPES = [
  { label: 'Corrective Maintenance', value: 4 },
  { label: 'Emissions Testing', value: 11 },
  { label: 'New Set', value: 6 },
  { label: 'Preventative Maintenance (PM)', value: 1 },
  { label: 'Release', value: 8 },
  { label: 'Pump Work Order', value: 12 },
  { label: 'Trouble Call', value: 2 },
];

export const WORK_ORDER_BILLING_OPTIONS = [
  { label: 'Billable to Customer', value: 'billable' },
  { label: 'Warranty Work', value: 'warranty' },
  { label: 'AFE', value: 'afe' },
];

export const yes_no_options = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const WORK_ORDER_UNIT_OWNERSHIP_TYPES = [
  { label: 'Rental', value: 'R' },
  { label: 'Customer Owned', value: 'CO' },
];
