import React from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const Note = (props) => {
  const {
    defaultValue,
    name,
    title,
    onChange,
    validation_rules,
    disabled,
  } = props;
  return (
    <Form.Item
      name={name || title}
      label={title}
      rules={validation_rules}
      initialValue={defaultValue}
    >
      <TextArea onChange={onChange} disabled={disabled} rows="4" />
    </Form.Item>
  );
};

export default Note;
