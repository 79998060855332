import { useEffect, useState } from 'react';

const useCheckBoxWithOther = ({ initial_values = {}, update = () => {} } = {}) => {
  const [values, setValues] = useState(initial_values);
  const [desc, setDesc] = useState('');

  const handleChange = (checkboxes) => {
    const data = checkboxes.reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});

    if (Object.keys(data).includes('other') || desc) data.other_description = desc;

    setValues(data);
    update({ data });
  };

  const handleOtherDescriptionChange = (e) => {
    const { value } = e.target;

    const data = {
      ...values,
      other_description: value,
    };

    setDesc(value);
    setValues(data);
    update({ data });
  };

  useEffect(() => {
    if (initial_values?.other_description) setDesc(initial_values.other_description);
  }, [initial_values]);

  return {
    handleChange,
    handleOtherDescriptionChange,
  };
};

export default useCheckBoxWithOther;
