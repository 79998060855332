export const displayInternetRequiredMessage = () => {
  const now = Date.now();
  const internetRequiredMessageLastDisplayed = localStorage.getItem('internet_required_notification_today');
  const timeDifference = now - internetRequiredMessageLastDisplayed;
  const oneHourInMilliseconds = 1 * 60 * 60 * 1000;

  if (timeDifference >= oneHourInMilliseconds) {
    localStorage.setItem('internet_required_notification_today', Date.now());
    return true;
  }

  return false;
};
