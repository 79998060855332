import db from '.';

export const createLocalInventoryTransfer = async (data) => {
  await db.inventory_transfers.put(data);
};

export const updateInventoryTransfer = ({ nextInventoryTransfer }) => db.inventory_transfers.put(nextInventoryTransfer);

/**
 * Gets all postgres inventory transfers completed between two points in time
 * @param {object} start - the starting date
 * @param {object} end - the ending date
 */
export const getInventoryTransfersByDate = (start, end) => db
  .recent_inventory_transfers
  .where('date_submitted')
  .between(start, end, true, true)
  .toArray();

/**
 * Gets all local inventory transfers completed between two points in time
 * @param {object} start - the starting date
 * @param {object} end - the ending date
 */
export const getLocalInventoryTransfersByDate = (start, end) => db
  .inventory_transfers
  .where('date_submitted')
  .between(start, end, true, true)
  .toArray();
