import { useEffect, useState } from 'react';
import BasicInformation from '../VehicleInspection/BasicInformation';
import ExteriorChecklist from '../VehicleInspection/ExteriorChecklist';
import InteriorChecklist from '../VehicleInspection/InteriorChecklist';
import Comments from '../VehicleInspection/Comments';

const useVehicleInspectionTabs = (props) => {
  const {
    vehicle_inspection, resources, resource_loading,
  } = props;

  const [tabs, setTabs] = useState([]);
  const [data, setData] = useState([]);

  const {
    mechanics, trucks, vehicle_inspection_fields, fleet_vehicles,
  } = data;

  useEffect(() => {
    if (resource_loading === true) return;

    setData(resources);
  }, [resource_loading]);

  useEffect(() => {
    // don't render tabs until all the resources are loaded
    if (resource_loading || !vehicle_inspection) return setTabs([]);

    const tabs_data = [
      {
        anchor: 'basic',
        title: 'Basic Information',
        comp: BasicInformation,
        props: {
          ...props,
          mechanics,
          trucks,
          fleet_vehicles,
        },
      },
      {
        anchor: 'exterior',
        title: 'Exterior',
        comp: ExteriorChecklist,
        props: {
          ...props,
          vehicle_inspection_fields,
        },
      },
      {
        anchor: 'interior',
        title: 'Interior',
        comp: InteriorChecklist,
        props: {
          ...props,
          vehicle_inspection_fields,
        },
      },
      {
        anchor: 'comments',
        title: 'Comments',
        comp: Comments,
        props: {
          ...props,
          mechanics,
        },
      },
    ];
    setTabs(tabs_data);
  }, [resource_loading, vehicle_inspection]);

  return { tabs };
};

export default useVehicleInspectionTabs;
