import { useEffect, useState } from 'react';
import BasicInformation from '../JSA/BasicInformation';
import Controls from '../JSA/Controls';
import PotentialHazards from '../JSA/PotentialHazards';
import HazardPlanning from '../JSA/HazardPlanning';
import WorkConditions from '../JSA/WorkConditions';
import Members from '../JSA/Members';

const useJsaTabs = (props) => {
  const {
    jsa, resources, resource_loading,
  } = props;

  const [tabs, setTabs] = useState([]);
  const [data, setData] = useState([]);

  const { units, customers, customer_locations } = data;

  useEffect(() => {
    if (resource_loading === true) return;

    setData(resources);
  }, [resource_loading]);

  useEffect(() => {
    // don't render tabs until all the resources are loaded
    if (resource_loading || !jsa) return setTabs([]);

    const tabs_data = [
      {
        anchor: 'basic',
        title: 'Basic Information',
        comp: BasicInformation,
        props: {
          ...props,
          units,
          customers,
          customer_locations,
        },
      },
      {
        anchor: 'controls',
        title: 'Controls & Practices',
        comp: Controls,
        props: {
          ...props,
        },
      },
      {
        anchor: 'potential_hazards',
        title: 'Identify Potential Hazards',
        comp: PotentialHazards,
        props: {
          ...props,
        },
      },
      {
        anchor: 'hazard_planning',
        title: 'Hazard Planning',
        comp: HazardPlanning,
        props: {
          ...props,
        },
      },
      {
        anchor: 'work_conditions',
        title: 'Work Site Conditions',
        comp: WorkConditions,
        props: {
          ...props,
        },
      },
      {
        anchor: 'members',
        title: 'JSA Team Members',
        comp: Members,
        props: {
          ...props,
        },
      },
    ];
    setTabs(tabs_data);
  }, [jsa]);

  return { tabs };
};

export default useJsaTabs;
