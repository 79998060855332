import { createSlice } from '@reduxjs/toolkit';
import {
  GET_USER_SETTINGS, GET_USER_SETTINGS_SUCCESS, GET_USER_SETTINGS_FAILED,
  GET_MECHANIC, GET_MECHANIC_SUCCESS, GET_MECHANIC_FAILED,
} from '../actionTypes';

// updateUserSettings - how to handle -> 8 instances?

const initialState = {
  isPending: false,
  settings: {
    displayTimeAdjustments: false,
    localId: '',
    truck_id: null,
    truck_label: '',
    truck_number: '',
  },
  mechanic: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    mechanic_number: '',
    timezone: '',
    truck_number: '',
    zone_id: '',
    zone_name: '',
    zone_abbr: '',
    user_id: '',
    number: '',
    dept_id: '',
    h2s_certified: true,
  },
};

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  extraReducers(builder) {
    builder.addCase(GET_USER_SETTINGS, (state) => {
      state.isPending = true;
    });
    builder.addCase(GET_USER_SETTINGS_SUCCESS, (state, action) => {
      state.isPending = false;
      [state.settings] = action.response;
    });
    builder.addCase(GET_USER_SETTINGS_FAILED, (state) => {
      state.isPending = false;
    });

    builder.addCase(GET_MECHANIC, (state) => {
      state.isPending = true;
    });
    builder.addCase(GET_MECHANIC_SUCCESS, (state, action) => {
      state.isPending = false;
      state.mechanic = action.response;
    });
    builder.addCase(GET_MECHANIC_FAILED, (state) => {
      state.isPending = false;
    });
  },
});

export default userSettingsSlice.reducer;
