import React, { useState, useEffect, createContext } from 'react';
import {
  getUserSettings,
  updateUserSettings as updateUS,
  getUserMechanic,
  updateUserMechanic as updateMech,
  getMechanics,
} from '../../db/api';

export const UserSettingsContext = createContext();

const UserSettingsProvider = (props) => {
  const { children } = props;
  const [user_settings, setUserSettings] = useState(null);
  const [user_mechanic, setUserMechanic] = useState({});
  const [user_settings_loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getUserSettings().then((res) => {
      setUserSettings(res[0]);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
      setLoading(false);
    });

    Promise.all([getMechanics(), getUserMechanic()])
      .then(([mechanicList, userMechanicRes]) => {
        /* getMechanics calls the http://flogistix.com/mechanic/info endpoint.
         * This response appears to be set outside of the API possible firebase. Therefore,
         * We are merging that data with the fieldservice.mechanic table to have all
         * neccessary user/mechanic info available
        */
        const userMech = userMechanicRes.at(0);
        if (!userMech) console.error('No user created');

        const additionalMechData = mechanicList.find((m) => m.user_id === userMech.id);
        const fullMechRecord = { ...userMech, ...additionalMechData };
        if (process.env.NODE_ENV !== 'production' && !userMech?.mechanic_number) {
          fullMechRecord.mechanic_number = 1259;
        }

        setUserMechanic(fullMechRecord);
      });
  }, []);

  const updateUserMechanic = async (mechanicRec) => {
    await updateMech(mechanicRec);
    setUserMechanic(mechanicRec);
  };

  const updateUserSettings = async (settings) => {
    await updateUS(settings);
    setUserSettings(settings);
  };

  return (
    <UserSettingsContext.Provider
      value={{
        user_settings,
        updateUserSettings,
        user_settings_loading,
        user_mechanic,
        updateUserMechanic,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsProvider;
