import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route } from 'react-router';
import 'antd/dist/antd.css';

import App from './App';
import AuthProvider, { Callback, Login } from './hoc/auth/Provider';
import UserSettingsProvider from './components/UserSettings/UserSettingsProvider';
import SyncProvider from './api/resources';
import WorkerProvider from './api/workers/WorkerProvider';

import store from './store';

const history = createBrowserHistory();

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router history={history}>
      <UserSettingsProvider>
        <AuthProvider loginUri="/login" redirectUri="/">
          <WorkerProvider>
            <SyncProvider>
              <Switch>
                <Route path="/callback" component={Callback} />
                <Route exact path="/login" component={Login} />
                <Route path="/" component={App} />
              </Switch>
            </SyncProvider>
          </WorkerProvider>
        </AuthProvider>
      </UserSettingsProvider>
    </Router>
  </ReduxProvider>,
  document.getElementById('root'),
);
