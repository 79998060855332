import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router';
import { Layout, Menu } from 'antd';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';

const { SubMenu } = Menu;

const SideBar = ({ menuItems = [], history }) => {
  const { user_settings, updateUserSettings } = useContext(UserSettingsContext);
  const [selected_keys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [default_collapsed, setDefaultCollapsed] = useState(false);
  const [activeRoute, setActiveRoute] = useState('');

  const location = useLocation();

  const handleOpenKeys = (indexToOpen = null) => {
    if (indexToOpen && !openKeys.includes(indexToOpen.key)) {
      setOpenKeys([indexToOpen.key]);
      return;
    }

    setOpenKeys([]);
  };

  const renderMenuItem = (item, index) => {
    const onRowClick = () => {
      // Antd keeps the external links selected which keeps the parent menu title highlighted
      const new_keys = item.external ? [] : [index];
      setSelectedKeys(new_keys);

      if (item.action) return item.action();

      if (item.external) return window.open(item.path, '_blank');

      history.push(item.path);
    };

    if (Object.keys(item).includes('children')) {
      return (
        <SubMenu icon={<item.icon />} key={index} onTitleClick={handleOpenKeys} title={item.title}>
          {item.children.map((child, i) => renderMenuItem(child, `${index}_sub_${i}`))}
        </SubMenu>
      );
    }

    return (
      <Menu.Item
        key={index}
        onClick={onRowClick}
        className={activeRoute === item.path || activeRoute.includes(item.title.toLowerCase().replace(' ', '')) ? 'active' : null}
        {...(item.icon ? { icon: <item.icon /> } : {})}
      >
        {item.title}
      </Menu.Item>
    );
  };

  const onSidebarCollapse = (sidebar_collapsed) => {
    const update = {
      ...user_settings,
      displayTimeAdjustments: false,
      sidebar_collapsed,
    };
    updateUserSettings(update);
  };

  useEffect(() => {
    const collapsed = user_settings?.sidebar_collapsed ?? false;
    setDefaultCollapsed(collapsed);
  }, [user_settings]);

  useEffect(() => {
    for (const item of menuItems) {
      if (Object.keys(item).includes('children')) {
        const index = menuItems.indexOf(item);
        item.children.forEach((child) => {
          if (child.path === location.pathname) {
            setOpenKeys([index.toString()]);
          }
        });
      }
    }

    setActiveRoute(location.pathname);
  }, [location]);

  return (
    <Layout.Sider
      onCollapse={onSidebarCollapse}
      collapsed={default_collapsed}
      collapsible
      style={{
        overflow: 'auto',
        minheight: 'calc(100vh - 64px)',
        overflowY: 'auto',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        marginTop: '64px',
      }}
    >
      <Menu mode="inline" theme="dark" openKeys={openKeys} selectedKeys={selected_keys}>
        {menuItems.map(renderMenuItem)}
      </Menu>
    </Layout.Sider>
  );
};

export default withRouter(SideBar);
