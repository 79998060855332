import React, { useState, useEffect } from 'react';
import { Divider, Form } from 'antd';
import { potentialHazards } from '../../config/constants';
import { useCheckBoxWithOther } from '../hooks';
import { CheckboxGroupWithOther } from '../shared/CheckboxGroupWithOther';
import { getJsaById } from '../../db/api';

const PotentialHazards = (props) => {
  const {
    jsa,
    updateJSAValue,
    form,
    read_only,
  } = props;

  // don't want to include hazard_checklists.plan when counting for errors
  const curr_hazard_checklist = jsa?.hazards_checklists ?? {};
  const filtered_checklist = Object.keys(curr_hazard_checklist).reduce((acc, item) => {
    const temp = acc;
    if (item !== 'plan') temp[item] = curr_hazard_checklist[item];
    return temp;
  }, {});

  const [selected_options, setSelectedOptions] = useState(filtered_checklist);
  const [section_error, setSectionError] = useState(false);

  const checkbox_options = potentialHazards.map((ph) => (
    { label: ph.value.toUpperCase(), value: ph.value }
  ));

  const update = async (res) => {
    const { data } = res;

    setSelectedOptions(data);

    // hazard_checklists.plan is handled in '../HazardPlanning'
    // we need to requery the current JSA's state to prevent overwriting the value here with stale data
    const curr_jsa = await getJsaById(jsa.localId);
    const plan = curr_jsa?.hazards_checklists?.plan ?? '';

    const new_checklist = {
      ...data,
      plan,
    };
    await updateJSAValue(jsa.localId, ['hazards_checklists'], new_checklist);
  };

  const validateChecked = async () => {
    setSectionError(false);
    const selected = Object.keys(selected_options);

    if (selected.includes('other') && selected_options.other_description.length === 0) {
      setSectionError(true);
      throw new Error('You must provide a description for Potential Hazard: "Other"');
    }

    if (selected.length < 1) {
      setSectionError(true);
      throw new Error('You must select at least one Potential Hazard');
    }
  };

  useEffect(() => {
    form.validateFields();
  }, [selected_options]);

  const { handleChange, handleOtherDescriptionChange } = useCheckBoxWithOther({
    initial_values: selected_options,
    update,
  });

  const checkbox_props = {
    group: {
      defaultValue: Object.keys(selected_options),
      options: checkbox_options,
      onChange: handleChange,
    },
    input: {
      defaultValue: selected_options?.other_description ?? '',
      disabled: read_only || !Object.keys(selected_options).includes('other'),
      onChange: handleOtherDescriptionChange,
    },
    read_only,
    showOther: Object.keys(selected_options).includes('other'),
  };

  return (
    <>
      {checkbox_options
        && (
        <section className={`section-validation section-validation--border${section_error ? '__error' : ''}`}>
          <Divider>
            How could I be injured? (check all that apply)
          </Divider>
          <Form.Item
            name={[
              { errors: 'potential_hazards' },
              { name: 'Potential Hazards' },
            ]}
            rules={[{ validator: validateChecked }]}
          >
            <CheckboxGroupWithOther {...checkbox_props} />
          </Form.Item>
        </section>
        )}
    </>
  );
};

export default PotentialHazards;
