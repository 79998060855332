/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-webpack-loader-syntax */
import MasterWorker from 'worker-loader!./master.worker.js';
import React from 'react';
import { addSiteNotification } from '../../db/api';

const worker = new MasterWorker();
worker.onmessage = (worker_message) => addSiteNotification(worker_message.data);

const { Provider, Consumer } = React.createContext();

const WorkerProvider = (props) => (
  <Provider
    value={{
      worker,
    }}
  >
    {props.children}
  </Provider>
);

export default WorkerProvider;
export function workerConsumer(WrappedComp) {
  return (props) => (
    <Consumer>
      { (ctx) => <WrappedComp {...ctx} {...props} /> }
    </Consumer>
  );
}
