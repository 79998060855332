import React from 'react';
import { Modal } from 'antd';

const ModalWrapper = (props) => {
  const {
    title,
    okText = 'Next',
    cancelText = 'Cancel',
    disabled,
    onClose = () => undefined,
    onOk = () => undefined,
    onCancel,
    okButtonProps,
    cancelButtonProps,
    open,
    body,
    hideModal,
    size,
    maskClosable,
    footer,
    closable,
  } = props;
  return (
    <Modal
      closable={closable}
      footer={footer}
      title={title}
      visible={open}
      disabled={disabled}
      okText={okText}
      cancelText={cancelText}
      onCancel={onCancel || hideModal}
      onClose={onClose}
      onOk={onOk}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      maskClosable={maskClosable}
      width={size === 'large' ? '950px' : '520px'}
      bodyStyle={{ padding: '15px 24px' }}
    >
      {body}
    </Modal>
  );
};

export default ModalWrapper;
