/* eslint-disable camelcase */
import {
  append, remove, propEq, update, findIndex, assocPath, pathOr, prop, head, curry, compose, assoc,
} from 'ramda';
import uuid from 'uuid/v4';
import moment from 'moment';
import db from '.';

/**
 * Initial data for a new work order
 * @typedef {object} InitialData
 */

/**
 * Updates to a work order, requires at least an id
 * @typedef {object} Updates
 * @property {string} id - the id of the object you are updating. Required by Dexie.
 */

/**
 * Creates a new work order and stores it in the work orders table
 * @param {Mechanic} mechanic - the current users mechanic data
 * @param {InitialData} initial - the initial data for the work order
 * @returns {Promise} - resolves with the id of the newly created work order
 */

export const createWorkOrder = curry(async (user_settings, wo, jsa, mechanic = {}) => {
  const wo_id = uuid();
  const jsa_id = uuid();
  const version = moment().unix();

  // need to put the relationship at the top level for when viewing a local wo/ jsa
  const workorder_flogistix_id = `${mechanic.mechanic_number || 'TES1234'}_workorder_${version}`;
  const jsa_flogistix_id = `${mechanic.mechanic_number || 'TES1234'}_jsa_${version}`;

  const new_jsa = {
    localId: jsa_id,
    workorder_flogistix_id,
    flogistix_id: jsa_flogistix_id,
    basic_information: {
      ...jsa.basic_information,
      flogistix_id: jsa_flogistix_id,
      unsafe_surroundings: false,
    },
    hazards_checklists: {},
    controls_checklists: {},
    signatures: [],
  };

  const new_work_order = {
    localId: wo_id,
    jsa_flogistix_id,
    flogistix_id: workorder_flogistix_id,
    complete: false,
    synced: false,
    ...wo,
    basic_information: {
      ...wo.basic_information,
      flogistix_id: workorder_flogistix_id,
      truck_id: user_settings.truck_id,
      mechanic_id: mechanic.user_id,
      mechanic_name: `${mechanic.first_name} ${mechanic.last_name}`,
    },
    timestamp: version, // for editing a work order
    jsa_id,
  };

  await db.jsas.put(new_jsa);
  return db.workorders
    .put(new_work_order)
    .then(async () => {
      await db.current_workorder.update(1, { wo_id }).then(async (updated) => {
        if (updated) return;

        await db.current_workorder.put({ id: 1, wo_id });
      });
      // localStorage.setItem('current_workorder', workOrderId);
      localStorage.setItem('workorder_timestamp', version);
      return { wo: new_work_order, jsa: new_jsa };
    })
    .catch((error) => {
      console.error(error);
    });
});


export const getWorkOrders = async () => db.workorders.toArray();

/**
 * Gets a work order from the work orders table
 * @param {string} id - id of the work order to retrieve
 * @returns {Promise} - Resolves with the returned work order
 */
export const getWorkOrder = async (id) => {
  let wo = await db.workorders.get(id);
  if (wo === undefined) {
    const localOrders = await getWorkOrders();
    wo = localOrders.find((wo) => wo.basic_information.flogistix_id === id);
  }
  return wo;
};

export const getWorkOrderByJSAId = async (id) => db.workorders.where('jsa_id').equals(id).first();

export const getJsas = async () => db.jsas.toArray();
export const getJsaById = async (id) => db.jsas.get(id);
export const getJsaByWorkOrderId = async (id) => {
  const wo = await getWorkOrder(id);
  const jsa = await db.jsas.get(wo.jsa_id);
  return jsa;
};

export const getUnitInformation = async (id) => (id ? db.units.get(id) : null);
/**
 * Updates an already existing work order in the work orders table
 * @param {Updates} updates - an object of updates to be made to the work order
 * @throws {TypeError} - if the updates object does not contain an id
 * @returns {Promise} - Resolves with the id of the updated work order
 */
export const updateWorkOrder = async (updates) => {
  if (!updates.localId) {
    throw new TypeError('Invalid object supplied to updateWorkOrder. Type Updates require an id');
  }
  return db.workorders.put(updates);
};

export const updateWorkOrderValue = async (wo_id, path, value) => {
  if (!wo_id) {
    throw new Error('Work Order id required for update.');
  }
  const work_order = await getWorkOrder(wo_id);
  const updated_wo = assocPath(path, value, work_order);
  return db.workorders.put(updated_wo);
};

export const updateWorkOrderList = async (wo_id, list_path, list_id_prop, value) => {
  if (!wo_id) {
    throw new Error('Work Order id required for update.');
  }
  const work_order = await getWorkOrder(wo_id);
  const list = pathOr(null, list_path, work_order);
  if (!list) {
    const updated_wo = assocPath(list_path, [value], work_order);
    // update dexie with updated wo
    return db.workorders.put(updated_wo);
  }
  // get index
  const index = findIndex(propEq(list_id_prop, prop(list_id_prop, value)), list);

  if (index === -1) {
    // add new item to list
    const new_list = append(value, list);
    // update wo with new list
    const updated_wo = assocPath(list_path, new_list, work_order);
    // update dexie with updated wo
    return db.workorders.put(updated_wo);
  }
  // update list with new value for value at index
  const new_list = update(index, value, list);
  // update wo with new list
  const updated_wo = assocPath(list_path, new_list, work_order);
  // update dexie with updated wo
  return db.workorders.put(updated_wo);
};

export const removeItemFromWorkOrderList = async (wo_id, list_path, list_id_prop, value) => {
  if (!wo_id) {
    throw new Error('Work Order id required for update.');
  }
  const work_order = await getWorkOrder(wo_id);
  const list = pathOr(null, list_path, work_order);

  if (!list) return;

  const index = findIndex(propEq(list_id_prop, prop(list_id_prop, value)), list);
  const new_list = remove(index, 1, list);
  const updated_wo = assocPath(list_path, new_list, work_order);
  return db.workorders.put(updated_wo);
};

export const updateJSA = async (updates) => {
  if (!updates.localId) {
    throw new TypeError('Invalid object supplied to updateJSA. Type Updates require an id');
  }
  return db.jsas.put(updates);
};

/**
 * Deletes the work_order with the given id
 * @param {string} id - id of the work_order in the work orders table
 * @returns {Promise} - Resolves with undefined
 */
export const deleteWorkOrder = async (wid) => {
  // get work_order to be deleted
  const wo = await getWorkOrder(wid);
  // delete work_order and current work_order and jsa
  return db.workorders
    .delete(wid)
    .then(async () => {
      // remove local storage information
      localStorage.removeItem('workorder_timestamp');
      localStorage.removeItem('current_workorder');
      // delete work orders jsa
      await db.jsas.delete(wo.jsa_id);
      // delete current work_order row in dexie
      await db.current_workorder.delete(1);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deleteCurrentWorkOrder = async () => {
  // remove local storage information
  localStorage.removeItem('workorder_timestamp');
  localStorage.removeItem('current_workorder');

  // delete current work_order row in dexie
  await db.current_workorder.delete(1);
};

/**
 * Gets all postgres work orders completed between two points in time
 * @param {object} start - the starting date
 * @param {object} end - the ending date
 */
export const getRecentWorkOrdersByDate = (start, end) => db
  .recent_workorders
  .where('end_time')
  .between(start, end, true, true)
  .toArray();

/**
 * Gets all local work orders completed between two points in time
 * @param {object} start - the starting date
 * @param {object} end - the ending date
 */
export const getLocalWorkOrdersByDate = (start, end) => db
  .workorders
  .where('end_time')
  .between(start, end, true, true)
  .toArray();

const getResource = curry((table, id) => db[table].where('workorder_id').equals(id));

const buildParts = async (woid) => {
  const parts = await getResource('recent_workorder_parts', woid).toArray();
  const builtParts = await Promise.all(parts.map(async (part) => {
    const { part_id, labor_code_id } = part;

    const actualPart = await db.parts.get(part_id);
    const laborCode = await db.laborcodes.get(labor_code_id);

    return compose(
      assoc('part_number', actualPart.number),
      assoc('part_description', actualPart.description),
      assoc('labor_code_name', laborCode.name),
      assoc('labor_code_short_code', laborCode.code),
    )(part);
  }));

  return builtParts;
};

export const buildLaborCodes = async (woid) => {
  const laborCodes = await getResource('recent_workorder_labor_codes', woid).toArray();
  const builtLaborCodes = await Promise.all(laborCodes.map(async (lc) => {
    const { labor_code_id } = lc;

    const actualLaborCode = await db.laborcodes.get(labor_code_id);

    return compose(
      assoc('code', actualLaborCode.code),
      assoc('labor_code_name', actualLaborCode.name),
    )(lc);
  }));

  return builtLaborCodes;
};

export const buildRecentWorkOrder = async (woid) => {
  const workOrderId = parseInt(woid, 10);
  const work_order = await db.recent_workorders.get(workOrderId);

  if (!work_order) {
    throw new Error(`Work order ${woid} does not exist in the database`);
  }

  const {
    customer_id,
    asset_id,
    truck_id,
    mechanic_id,
  } = work_order;

  const next = compose(...[
    assoc('parts', await buildParts(workOrderId)),
    assoc('notes', await getResource('recent_workorder_notes', workOrderId).toArray()),
    assoc('labor_codes', await buildLaborCodes(workOrderId)),
    assoc('unit_emissions', await getResource('recent_workorder_emissions', workOrderId).first() || null),
    assoc('pm_checklist', await getResource('recent_workorder_pms', workOrderId).first() || null),
    assoc('unit_readings', await getResource('recent_workorder_readings', workOrderId).first() || null),
    assoc('customer', await db.customers.get(customer_id)),
    assoc('unit', await db.units.get(asset_id)),
    assoc('truck', await db.trucks.get(truck_id)),
    assoc('mechanic', await db.mechanics.get({ user_id: mechanic_id })),
  ])(work_order);

  return next;
};

export const getRecentWorkOrders = async () => {
  const work_orders = await db.recent_workorders.orderBy('end_time').reverse().toArray();

  return Promise.all(work_orders.map(async (work_order) => {
    const { mechanic_id, customer_id, asset_id } = work_order;

    const mechanicInfo = mechanic_id !== null
      ? await db.mechanics.get({ user_id: mechanic_id })
      : null;

    const customerInfo = customer_id !== null
      ? await db.customers.get({ id: customer_id })
      : null;

    const unitInfo = mechanic_id !== null
      ? await db.units.get({ id: asset_id })
      : null;

    return compose(
      assoc('mechanic', mechanicInfo && `${mechanicInfo.first_name} ${mechanicInfo.last_name}`),
      assoc('customer_name', customerInfo && customerInfo.name),
      assoc('asset_number', unitInfo && unitInfo.number),
    )(work_order);
  }));
};

export const getCurrentWorkOrder = async () => {
  const current_work_order = head(await db.current_workorder.toArray());
  const cwid = prop('wo_id', current_work_order);
  if (!cwid) { return { wo: null, jsa: null }; }
  const wo = await getWorkOrder(cwid);
  const jsa = await db.jsas.get(wo.jsa_id);
  return { wo, jsa };
};

export const getWorkOrderByJSA = async (id) => {
  const wo = await getWorkOrderByJSAId(id);
  const jsa = await db.jsas.get(id);
  return { wo, jsa };
};
