import React, { useState } from 'react';
import {
  Button, Table, InputNumber, Form, Typography, message,
} from 'antd';
import { DeleteOutlined, PlusSquareFilled } from '@ant-design/icons';
import AddLaborCodeModal from '../../Modals/AddLaborCodeModal';
import { addKeys, debounce } from '../../../lib/utils';

const { confirm } = window;

const LaborCodes = (props) => {
  const {
    work_order, updateWorkOrderValue, form, read_only, work_order_call_types,
    setSelectedLaborCodes, selected_labor_codes,
    labor_codes, call_type_labor_codes, selected_parts,
  } = props;

  const [labor_code_modal_visible, setLaborCodeModalVisible] = useState(false);

  const addLaborCode = async (labor_code) => {
    const updated_labor_codes = [
      ...selected_labor_codes,
      labor_code,
    ];
    await updateWorkOrderValue(work_order.localId, ['labor_codes'], updated_labor_codes);
    form.setFieldsValue({ labor_codes: updated_labor_codes });
    setSelectedLaborCodes(updated_labor_codes);

    setLaborCodeModalVisible(false);
  };

  const updateLaborCodeValue = async (row, field, value) => {
    let val = value;
    if (typeof val !== 'number') val = 0;

    if (field === 'minutes' && val >= 60) {
      const calcdHours = Math.floor(val / 60);
      updateLaborCodeValue(row, 'hours', row.hours + calcdHours);

      val -= (60 * calcdHours);
    }

    const updated_labor_codes = selected_labor_codes.map((lc) => {
      if (lc.id === row.id) lc[field] = val;
      return lc;
    });

    await updateWorkOrderValue(work_order.localId, ['labor_codes'], updated_labor_codes);
    setSelectedLaborCodes(updated_labor_codes);

    form.setFieldsValue({ [`labor_code_${row.id}_${field}`]: val });
  };

  const deleteLaborCode = async (labor_code) => {
    let msg = '';
    const labor_code_used = selected_parts.some((p) => p.labor_code_id === labor_code.id);
    if (labor_code_used) {
      msg = 'This labor code is used on one or more parts. Please reassign this labor code before removing it.';
      return message.error(msg);
    }

    msg = `Are you sure you wish to remove this labor code (${labor_code.name})?`;
    if (!confirm(msg)) return;

    const updated_labor_codes = selected_labor_codes.filter((lc) => lc.id !== labor_code.id);
    await updateWorkOrderValue(work_order.localId, ['labor_codes'], updated_labor_codes);
    setSelectedLaborCodes(updated_labor_codes);
  };

  const TimeValidator = async (row) => {
    if (read_only) return;

    const form_values = form.getFieldsValue();
    const h = `labor_code_${row.id}_hours-${row.key}`;
    const m = `labor_code_${row.id}_minutes-${row.key}`;
    if (form_values[h] + form_values[m] > 0) return;

    // include name so that the notifications are useful (vs using {required:true})
    throw new Error(`At least 1 minute is required for ${row.name}`);
  };

  const cols = [
    {
      title: 'Labor Code',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (_, row) => (
        <Form.Item
          name={`labor_code_${row.id}_name`}
          rules={[
            { validator: () => TimeValidator(row) },
          ]}
        >
          <Typography>{row.name}</Typography>
        </Form.Item>
      ),
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
      render: (_, row) => (
        <Form.Item
          name={`labor_code_${row.id}_hours-${row.key}`}
          initialValue={row.hours || 0}
        >
          <InputNumber
            min={0}
            max={71}
            onChange={debounce((val) => updateLaborCodeValue(row, 'hours', val), 500)}
            disabled={read_only}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Minutes',
      dataIndex: 'minutes',
      key: 'minutes',
      render: (_, row) => (
        <Form.Item
          name={`labor_code_${row.id}_minutes-${row.key}`}
          initialValue={row.minutes || 0}
        >
          <InputNumber
            min={0}
            max={55}
            step={5}
            onChange={debounce((val) => updateLaborCodeValue(row, 'minutes', val), 500)}
            disabled={read_only}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      dataIndex: 'delete',
      width: '5%',
      render: (_, row) => (
        <Button
          type="primary"
          shape="round"
          title="Delete"
          icon={<DeleteOutlined />}
          danger
          onClick={() => deleteLaborCode(row)}
        />
      ),
    },
  ];

  const columns = read_only ? cols.filter((c) => c.key !== 'delete') : cols;

  return (
    <>
      { !read_only && (
        <Button
          style={{ float: 'right', marginBottom: '10px' }}
          type="primary"
          onClick={() => setLaborCodeModalVisible(true)}
        >
          <PlusSquareFilled />
          Add Labor Code
        </Button>
      )}
      <Table
        columns={columns}
        dataSource={addKeys(selected_labor_codes, false)}
        pagination={false}
      />
      <AddLaborCodeModal
        labor_codes={labor_codes}
        call_type_labor_codes={call_type_labor_codes}
        selected_labor_codes={selected_labor_codes}
        work_order={work_order}
        work_order_call_types={work_order_call_types}
        visible={labor_code_modal_visible}
        onCancel={() => setLaborCodeModalVisible(false)}
        addLaborCode={addLaborCode}
      />
    </>
  );
};

export default LaborCodes;
