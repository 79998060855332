import { useLocation } from 'react-router-dom';

const useSeachParams = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const searchParams = {};

  if (search) {
    const urlEntires = new URLSearchParams(search).entries();

    for (const [key, value] of urlEntires) {
      searchParams[key] = value.split(',');
    }
  }

  return { pathname, searchParams };
};

export default useSeachParams;
