import React, { useState, useEffect } from 'react';
import {
  prop, map, sortBy, pathOr,
} from 'ramda';
import {
  Spin, notification, Row, Col,
} from 'antd';
import { makeOptions } from '../../lib/utils';
import { updateWorkOrderValue } from '../../db/api';
import { AntdFormField } from '../shared';
import { getUnitInfo, h2sLocation } from '../utils/unit';

const BasicInformation = (props) => {
  const {
    form,
    work_order,
    user_mechanic,
    units,
    customers,
    customer_locations,
    jsa,
    updateJSAValue,
    read_only,
  } = props;

  const bi = jsa.basic_information;

  const [selected_unit, setSelectedUnit] = useState(null);
  const [selected_customer, setSelectedCustomer] = useState({});
  const [customer_location_options, setCustomerLocationOptions] = useState([]);

  const customer_options = sortBy(prop('label'), map(makeOptions(['name'], 'id'), customers));
  const unit_options = units.map((unit) => (
    {
      value: unit.id,
      label: unit.number + (h2sLocation(unit) ? ' (H2S)' : ''),
    }
  ));

  const handleChange = async (field, value, update_work_order = false) => {
    await updateJSAValue(jsa.localId, ['basic_information', field], value);
    if (update_work_order) await updateWorkOrderValue(work_order.localId, ['basic_information', field], value);
  };

  const setCustomer = (customer_id) => {
    const customer = customers.find((c) => c.id === customer_id);
    setSelectedCustomer(customer);
  };

  const onUnitChange = async (field, id) => {
    const unit_info = getUnitInfo(units, id);

    if (pathOr(false, ['h2s_certified'], user_mechanic) !== true && unit_info.h2s_location === true) {
      notification.error({
        message: 'Unit Selection Error',
        key: 'h2s_error',
        description: 'You selected a unit on an H2S site, but are not H2S certified. '
          + 'Please choose another unit or contact your Area Manager for further assistance.',
        duration: 8,
      });
      form.setFieldsValue({ unit_id: selected_unit.unit_id });
      return;
    }

    await handleChange('unit_id', unit_info.unit_id, true);

    // need to keep track of selected unit so we can "revert" their selection if they aren't h2s certified (above)
    setSelectedUnit(unit_info);

    // update customer and filter location list
    const { customer_id, customer_location_id } = unit_info;

    setCustomer(customer_id);
    form.validateFields(['customer_id', 'customer_location_id']);
    form.setFieldsValue(unit_info);

    await handleChange('customer_id', customer_id, true);
    await handleChange('customer_location_id', customer_location_id, true);
  };

  const onCustomerChange = async (field, customer_id) => {
    form.setFieldsValue({ customer_location_id: null });
    form.validateFields(['customer_location_id']);
    setCustomer(customer_id);

    await handleChange('customer_id', customer_id, true);
    await handleChange('customer_location_id', null, true);
  };

  const onCustomerLocationChange = async (field, customer_id) => {
    await handleChange('customer_location_id', customer_id, true);
  };

  const filterCustomerLocationOptions = () => {
    const filtered_locations = selected_customer
      ? customer_locations.filter((l) => parseInt(l.customer_id) === parseInt(selected_customer.id))
      : [];
    const location_options = filtered_locations.map((l) => (
      { value: l.id, label: l.address_label }
    ));
    setCustomerLocationOptions(location_options);
  };

  useEffect(() => {
    const { customer_id, customer_location_id, unit_id } = bi;

    // set unit info display fields & defaults (on new work_order)
    const unit = getUnitInfo(units, unit_id);
    setSelectedUnit(unit);
    form.setFieldsValue(unit);

    // overwrite WO's customer & location with unit defaults
    form.setFieldsValue({ customer_id, customer_location_id });

    // needed to filter customer_location_options on load
    setCustomer(customer_id);
  }, []);

  // changing customer removes the location, have to re-validate
  useEffect(() => {
    filterCustomerLocationOptions();
  }, [selected_customer]);

  // validate on page load
  useEffect(() => {
    form.validateFields();
  }, [selected_unit]);

  if (!jsa || !selected_unit) return <Spin />;

  return (
    <>
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <AntdFormField
            type="select"
            name="unit_id"
            label="Unit Number"
            defaults={bi}
            options={unit_options}
            onChange={onUnitChange}
            read_only={read_only}
          />
        </Col>
        <Col span={8}>
          <AntdFormField
            type="select"
            name="customer_id"
            label="Customer"
            defaults={bi}
            options={customer_options}
            onChange={onCustomerChange}
            read_only={read_only}
          />
        </Col>
        <Col span={8}>
          <AntdFormField
            type="select"
            name="customer_location_id"
            label="Customer Location"
            defaults={bi}
            placeholder={selected_customer
              ? 'Start Typing to Search...'
              : 'Select a customer for location options'}
            options={customer_location_options}
            onChange={onCustomerLocationChange}
            read_only={!selected_customer || read_only}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col
          xs={{
            span: 24,
          }}
          md={{
            span: 24,
          }}
          lg={{
            span: 12,
          }}
        >
          <AntdFormField
            type="textarea"
            name="description_of_work"
            label="Description of Work"
            defaults={bi}
            onChange={handleChange}
            read_only={read_only}
          />
        </Col>
        <Col
          xs={{
            span: 24,
          }}
          md={{
            span: 24,
          }}
          lg={{
            span: 12,
          }}
        >
          <AntdFormField
            type="textarea"
            name="emergency_evacuation_instructions"
            label="Emergency Evacuation Instructions"
            defaults={bi}
            onChange={handleChange}
            read_only={read_only}
          />
        </Col>
      </Row>
    </>
  );
};

export default BasicInformation;
