import React, { useState, useEffect } from 'react';
import { Alert, Space } from 'antd';
import { AntdFormField } from '../shared';
import { yes_no_options } from '../../config/constants';

const WorkConditions = (props) => {
  const {
    jsa,
    updateJSAValue,
    form,
    read_only,
  } = props;

  const bi = read_only ? jsa : jsa.basic_information;

  // this field name makes no sense. You would think "yes" means there ARE unsafe surroundings, so it's bad,
  // but it's backwards. unsafe_surroundings = true means it IS safe
  // const [safe, setSafe] = useState(Object.prototype.hasOwnProperty.call(bi, 'basic_information') ? bi.unsafe_surroundings : false);
  const [safe, setSafe] = useState(false);

  const handleChange = async (field, val) => {
    setSafe(val);
    await updateJSAValue(jsa.localId, ['basic_information', field], val);
  };

  const unsafeValidator = async () => {
    const { unsafe_surroundings } = form.getFieldsValue(['unsafe_surroundings']);
    if (unsafe_surroundings === false) throw new Error('You cannot continue with this work order if you feel unsafe!');
  };

  useEffect(() => {
    if (bi?.basic_information) {
      setSafe(bi?.basic_information?.unsafe_surroundings);
    }
  }, [bi]);

  return (
    <>
      <h3>
        Do you feel safe and comfortable working on this
        unit/equipment based on the condition of the surroundings?
      </h3>
      <p>
        Consider lighting, electrical, housekeeping, gas or oil present, etc.
      </p>
      <Space direction="vertical">
        <AntdFormField
          type="radiogroup"
          name="unsafe_surroundings"
          options={yes_no_options}
          onChange={handleChange}
          defaults={bi}
          validation_rules={[
            { required: true },
            { validator: unsafeValidator },
          ]}
          read_only={read_only}
        />
        { !safe
          && (
          <Alert
            type="error"
            message="STOP WORK!"
            description="Contact your area manager & EHSS representative immediately for further instructions."
            showIcon
          />
          )}
      </Space>
    </>
  );
};

export default WorkConditions;
