const STATUS_TO_OWNERSHIP = {
  X: 'Customer Owned',
  R: 'Rental',
  A: 'Rental',
  C: 'Rental',
  CO: 'Customer Owned',
  P: 'Rental',
  PR: 'Rental',
};

export default STATUS_TO_OWNERSHIP;
