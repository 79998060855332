import { Card, Col, Row } from 'antd';
import React from 'react';
import { AntdFormField } from '../shared';
import { addKeys, chunkArray } from '../../lib/utils';

const VehicleInspectionSection = (props) => {
  const {
    fields, defaults, handleChange, title, read_only,
  } = props;

  const options = [
    { label: 'Pass', value: 1 },
    { label: 'Fail', value: 0 },
    { label: 'N/A', value: -1 },
  ];

  const form_fields = fields.map((field) => (
    <AntdFormField
      {...field}
      type="select"
      name={field.key}
      options={options}
      onChange={handleChange}
      defaults={defaults}
      read_only={read_only}
      placeholder=""
    />
  ));

  const chunked_fields = chunkArray(form_fields, 6);
  const rows = chunked_fields.map((chunk) => (
    <Row gutter={[24, 24]}>
      {addKeys(chunk.map((field) => (
        <Col span={4}>{field}</Col>)), false)}
    </Row>
  ));

  return (
    <Card
      style={{ marginBottom: '15px' }}
      title={title}
    >
      {addKeys(rows, false)}
    </Card>
  );
};

export default VehicleInspectionSection;
