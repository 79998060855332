import { createSlice } from '@reduxjs/toolkit';
import { GET_API_HEALTH, GET_API_HEALTH_RESOLVED } from '../actionTypes';

const initialState = {
  isPending: false,
  isOnline: true,
};

export const apiHealthSlice = createSlice({
  name: 'apiHealth',
  initialState,
  extraReducers(builder) {
    builder.addCase(GET_API_HEALTH, (state) => {
      state.isPending = true;
    });
    builder.addCase(GET_API_HEALTH_RESOLVED, (state, action) => {
      state.isPending = false;
      state.isOnline = action.isOnline;
    });
  },
});

export default apiHealthSlice.reducer;
