import React from 'react';
import { Row, Col, Spin } from 'antd';
import 'moment-timezone';
import { AntdFormField } from '../shared';

const BasicInformation = (props) => {
  const {
    vehicle_inspection,
    updateInspection,
    mechanics,
    read_only,
  } = props;

  const bi = vehicle_inspection.basic_information;

  const handleChange = async (field, value) => {
    await updateInspection(['basic_information', field], value);
  };

  if (!vehicle_inspection || !mechanics) return <Spin />;

  const mechanic_options = mechanics.map((m) => (
    { label: `${m.first_name} ${m.last_name}`, value: m.user_id }
  ));

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <AntdFormField
            type="select"
            name="inspected_by"
            label="Inspected By"
            options={mechanic_options}
            defaults={bi}
            onChange={handleChange}
            read_only={read_only}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <AntdFormField
            type="textarea"
            name="comments"
            label="Comments"
            defaults={bi}
            onChange={handleChange}
            required={false}
            read_only={read_only}
          />
        </Col>
      </Row>
    </>
  );
};

export default BasicInformation;
