import React, { useState } from 'react';
import { Card } from 'antd';
import Parts from './Parts';
import LaborCodes from './LaborCodes';
import LaborCodeTimer from './LaborCodeTimer';

const PartsAndLaborCodes = (props) => {
  const {
    work_order, work_order_call_types, selected_labor_codes, setSelectedLaborCodes,
  } = props;

  const [selected_parts, setSelectedParts] = useState(work_order?.parts ?? []);

  const allProps = {
    ...props,
    selected_parts,
    setSelectedParts,
    selected_labor_codes,
    setSelectedLaborCodes,
  };

  return (
    <>
      <LaborCodeTimer {...allProps} />
      <Card>
        <LaborCodes
          {...allProps}
        />
      </Card>
      <br />
      { !work_order_call_types.includes(3)
        && (
        <Card>
          <Parts
            {...allProps}
          />
        </Card>
        )}
    </>
  );
};

export default PartsAndLaborCodes;
