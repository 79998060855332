import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { pathOr } from 'ramda';
import { Row, Col, Card } from 'antd';
import { AntdFormField } from '../shared';
import { addKeys, chunkArray } from '../../lib/utils';
import { additional_unit_reading_fields, unit_reading_stage_fields }
  from '../../config/constants/unit_readings';

const UnitReadings = (props) => {
  const {
    form,
    work_order,
    updateWorkOrderValue,
    read_only,
  } = props;

  const readings = work_order?.unit_readings ?? {};
  const keyedReadings = Object.keys(readings).reduce((acc, key) => {
    acc[`unit_readings_${key}`] = readings[key];
    return acc;
  }, {});

  const [flow_rate, setFlowRate] = useState(pathOr(null, ['flow_rate'], keyedReadings));

  const handleChange = async (field, val) => {
    const key = field.split('unit_readings_')[1];
    await updateWorkOrderValue(work_order.localId, ['unit_readings', key], val);
  };

  const onFlowRateChange = async (field, val) => {
    setFlowRate(val > 0); // keeping track of the actual value is slow

    if (val) {
      await form.setFieldsValue({ no_flow_rate_reason: null });
      await handleChange('no_flow_rate_reason', null);
    }

    form.validateFields(['no_flow_rate_reason']);
    await handleChange(field, val);
  };

  const createPlaceholder = (label) => {
    if (!['Press', 'Temp'].some((part) => label.includes(part))) return '';
    return label.includes('Press') ? 'PSIG' : '°F';
  };

  const stages = [1, 2, 3].map((n) => {
    const mapping = [
      { number: 1, title: '1st', index: 'first' },
      { number: 2, title: '2nd', index: 'second' },
      { number: 3, title: '3rd', index: 'third' },
    ];

    const { title, index } = mapping.find((m) => m.number === n);

    const fields = unit_reading_stage_fields.map((field) => (
      <AntdFormField
        name={`${field.category}_${field.name.replace('%index%', index)}`}
        label={field.label}
        type="number"
        onChange={handleChange}
        defaults={keyedReadings}
        placeholder={createPlaceholder(field.label)}
        read_only={read_only}
      />
    ));

    return { title, fields };
  });

  const stage_cols = stages.map((stage) => (
    <Col span={8}>
      <Card title={`${stage.title} Stage`}>
        {addKeys(stage.fields, false)}
      </Card>
    </Col>
  ));

  // only thing required is hour meter reading
  const additional_fields = additional_unit_reading_fields.map((field) => (
    <AntdFormField
      {...field}
      name={`${field.category}_${field.name}`}
      type="number"
      onChange={handleChange}
      defaults={keyedReadings}
      placeholder={createPlaceholder(field.label)}
      read_only={read_only}
    />
  ));

  // chunk fields into rows of 4 elements
  const chunked_fields = chunkArray(additional_fields, 4);
  const form_rows = chunked_fields.map((chunk) => (
    <Row gutter={[24, 24]}>
      {addKeys(chunk.map((field) => (<Col span={6}>{field}</Col>)), false)}
    </Row>
  ));

  return (
    <>
      <Row gutter={[8, 24]}>
        {addKeys(stage_cols, false)}
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <AntdFormField
            type="number"
            name="unit_readings_flow_rate"
            label="Flow Rates for Previous Days"
            defaults={keyedReadings}
            required={false}
            onChange={onFlowRateChange}
            read_only={read_only}
          />
        </Col>
        <Col span={6} style={{ display: flow_rate ? 'none' : 'block' }}>
          <AntdFormField
            name="unit_readings_no_flow_rate_reason"
            label="Reason for not providing Flow Rate"
            defaults={keyedReadings}
            onChange={handleChange}
            required={!flow_rate}
            read_only={read_only}
          />
        </Col>
      </Row>
      {addKeys(form_rows, false)}
    </>
  );
};

export default withRouter(UnitReadings);
