import { useEffect, useState } from 'react';
import { reduce, curry, concat } from 'ramda';
import { makeOptions } from '../../lib/utils';
import db, {
  resourceList,
} from '../../db';

const makeResPromise = (res) => new Promise((resolve, reject) => {
  db[res.name].toArray().then((res_list) => {
    const res_options = res.labelCols ? res_list.map(makeOptions(res.labelCols, 'id')) : undefined;
    return resolve({ [res.name]: res_list, [`select_${res.name}`]: res_options });
  }).catch((error) => reject(error));
});

const reduceResources = curry((resource_list, acc, res) => {
  if (resource_list.includes(res.name)) {
    const promise = makeResPromise(res);
    return concat(acc, [promise]);
  }
  return acc;
});

const useResources = (resource_list, dataSource = resourceList) => {
  const [resources, setResources] = useState({});
  const [resource_loading, setResourceLoading] = useState(true);

  useEffect(() => {
    const res_promises = reduce(reduceResources(resource_list), [], dataSource);
    Promise.all(res_promises).then((res) => {
      const new_resources = reduce((acc, val) => ({ ...acc, ...val }), {}, res);
      setResources(new_resources);
      setResourceLoading(false);
    });
  }, []);

  return { resources, resource_loading };
};

export default useResources;
