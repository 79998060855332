import React, { useEffect, useState, useContext } from 'react';
import { map, propOr } from 'ramda';
import {
  Row, Col, Switch, Form,
} from 'antd';
import { Select, Button } from '.';
import { timezones } from '../../lib/constants';
import { makeOptions } from '../../lib/utils';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import {
  getMechanics,
  getWarehouseById,
} from '../../db/api';

const Settings = (props) => {
  const {
    cancel,
    save,
  } = props;
  const { user_settings } = useContext(UserSettingsContext);
  const [settings, setSettings] = useState(null);
  const [truck_options, setTruckOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getMechanics(),
    ]).then((res) => {
      setTruckOptions(map(makeOptions(['first_name', 'last_name', 'truck_number'], 'truck_id'), res[0]));
      // this ensures that we have set settings from dexie before we load the components
      setSettings(user_settings);
      setLoading(false);
    });
  }, [user_settings]);

  return loading ? (<></>) : (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Select
            options={truck_options}
            label="Truck"
            defaultValue={propOr(null, 'truck_id', settings)}
            onChange={async (val, option) => {
              const warehouse = await getWarehouseById(val);
              setSettings({
                ...settings,
                truck_id: val,
                truck_number: warehouse.description,
                truck_label: option.label,
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={24}>
          <Select
            options={timezones}
            label="Time Zone"
            defaultValue={propOr(null, 'timezone_label', settings)}
            onChange={(val, option) => {
              setSettings({
                ...settings,
                timezone: val,
                timezone_label: option.label,
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={24}>
          <Form.Item
            label="Time Adjustment Display"
          >

            <Switch
              title="Turn on or off the Time Adjustment Display"
              checkedChildren="On"
              unCheckedChildren="Off"
              defaultChecked={user_settings?.displayTimeAdjustments ?? false}
              onChange={(checked) => {
                setSettings({
                  ...settings,
                  displayTimeAdjustments: checked,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <Button
            block
            type="danger"
            onClick={cancel}
          >
            Cancel
          </Button>
        </Col>
        <Col span={6} offset={12}>
          <Button
            block
            type="success"
            onClick={() => { save(settings); }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
