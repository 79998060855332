import React from 'react';
import {
  Checkbox, DatePicker, Form, Input, InputNumber, Radio,
} from 'antd';
import { pathOr } from 'ramda';
import moment from 'moment';
import { Select } from '.';
import { yes_no_options } from '../../config/constants';
import ColumnCheckboxGroup from './ColumnCheckboxGroup';
import { isDefined } from '../../lib/utils';

const AntdFormField = (props) => {
  const {
    name, label, type, options, required, placeholder, min, max, validation_rules, style, tooltip,
    defaults = [], onChange = () => null, allowClear = false, read_only = false, rows = 2,
    min_date = moment().subtract(100, 'years'), max_date = moment().add(100, 'years'), onPressEnter = () => null,
  } = props;

  const childField = (field_type) => {
    switch (field_type) {
      case 'number':
        return (
          <InputNumber
            style={{ width: '100%' }}
            placeholder={placeholder}
            {...(isDefined(min) ? { min } : {})}
            {...(isDefined(max) ? { max } : {})}
            onChange={(val) => onChange(name, val)}
            disabled={read_only}
          />
        );
      // checkboxes are ugly when all other fields are nice rectangles
      case 'yes_no':
        return (
          <Select
            options={yes_no_options}
            onChange={(val) => onChange(name, val)}
            disabled={read_only}
          />
        );
      case 'select':
        return (
          <Select
            allowClear={allowClear}
            options={options}
            onChange={(val) => onChange(name, val)}
            placeholder={placeholder}
            disabled={read_only}
          />
        );
      case 'multiselect':
        return (
          <Select
            mode="multiple"
            allowClear
            options={options}
            onChange={(val) => onChange(name, val)}
            disabled={read_only}
          />
        );
      case 'dropdown':
        return (
          <Select
            options={options.map((o) => ({ value: o, label: o }))}
            onChange={(val) => onChange(name, val)}
            disabled={read_only}
          />
        );
      case 'boolean':
        return (
          <Checkbox
            style={{ transform: 'scale(1.25)' }}
            defaultChecked={pathOr(false, [name], defaults)}
            onChange={(e) => onChange(name, e.target.checked)}
            disabled={read_only}
          />
        );
      case 'columncheckboxgroup':
        return (
          <ColumnCheckboxGroup
            options={options}
            onChange={onChange}
            disabled={read_only}
          />
        );
      case 'textarea':
        return (
          <Input.TextArea
            style={{ width: '100%' }}
            placeholder={placeholder}
            rows={rows}
            onChange={(e) => onChange(name, e.target.value)}
            disabled={read_only}
          />
        );
      case 'datepicker':
        return (
          <DatePicker
            style={{ width: '100%' }}
            disabledDate={(d) => !d || !d.isBetween(min_date, max_date)}
            onChange={(val) => onChange(name, val)}
            disabled={read_only}
          />
        );
      case 'datetimepicker':
        return (
          <DatePicker
            showTime
            style={{ width: '100%' }}
            disabledDate={(d) => !d || !d.isBetween(min_date, max_date)}
            onChange={(val) => onChange(name, val)}
            disabled={read_only}
          />
        );
      case 'radiogroup':
        return (
          <Radio.Group
            options={options}
            onChange={(e) => onChange(name, e.target.value)}
            disabled={read_only}
          />
        );
      case 'readonly':
        return (
          <Input
            style={{ width: '100%' }}
            placeholder={placeholder}
            readOnly
            disabled={read_only}
          />
        );
      case 'string':
      default:
        return (
          <Input
            style={{ width: '100%' }}
            placeholder={placeholder}
            onChange={(e) => onChange(name, e.target.value)}
            onPressEnter={(e) => onPressEnter(e)}
            disabled={read_only}
          />
        );
    }
  };

  // use validation_rules prop by default, otherwise
  // default to required if the required prop isn't passed
  // (have to pass required={false} to make field not-required)
  const required_rules = validation_rules || (
    required !== false
      ? [{ required: true, message: `${label} is required` }]
      : [{ required: false }]
  );

  // antd checkboxes would not show the required message, even if the initialValue was false
  // have to set these rules or else the checkbox won't validate properly
  // use whatever the required rules are & append the special checkbox rules if it's a checkbox
  const rules = read_only ? []
    : [{
      ...required_rules.at(0),
      ...required_rules.at(1),
      ...(type === 'boolean' ? { transform: (value) => (value || undefined), type: 'boolean' } : {}),
    }];

  // date fields expect a moment object as their default
  // multiselect requires an array, it'll break on null
  const default_value = () => {
    if (type === 'multiselect') return defaults?.[name] ?? [];
    if (['datepicker', 'datetimepicker'].includes(type)) return moment(defaults?.[name]);
    if (defaults[name] === undefined) return null;

    return defaults[name];
  };

  const form_style = {
    width: '100%',
    ...(isDefined(style) ? style : {}),
  };

  return (
    <Form.Item
      style={form_style}
      name={name}
      label={label}
      rules={rules}
      initialValue={default_value()}
      tooltip={tooltip}
      {...(type === 'boolean' ? { valuePropName: 'checked' } : {})} // make antd happy
    >
      {childField(type)}
    </Form.Item>
  );
};

export default AntdFormField;
