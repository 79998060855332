import React from 'react';
import { withRouter } from 'react-router';
import { Col, Row } from 'antd';
import { addKeys, chunkArray } from '../../lib/utils';
import { AntdFormField } from '../shared';

const Emissions = (props) => {
  const {
    work_order,
    updateWorkOrderValue,
    reasons,
    read_only,
  } = props;

  const emissions = work_order.unit_emissions;
  const reason_options = reasons
    ? reasons.map((reason) => ({ label: reason.reason, value: reason.id }))
    : [];

  const handleChange = async (field, value) => {
    await updateWorkOrderValue(work_order.localId, ['unit_emissions', field], value);
  };

  const fields = [
    {
      name: 'reason_id', label: 'Reason for Test or Check', type: 'select', options: reason_options,
    },
    { name: 'o2_changed', label: 'O2 Sensor Change', type: 'yes_no' },
    { name: 'o2', label: 'O2 Percent (%)', type: 'number' },
    { name: 'nox_ppm', label: 'NOX (PPM)', type: 'number' },
    { name: 'co_ppm', label: 'CO (PPM)', type: 'number' },
    { name: 'afr_mv', label: 'AFR mV', type: 'number' },
    { name: 'pre_cat_temp', label: 'Catalyst Temp Pre-Cat', type: 'number' },
    { name: 'post_cat_temp', label: 'Catalyst Temp Post-Cat', type: 'number' },
    { name: 'engine_serial', label: 'Engine Serial', type: 'number' },
    { name: 'permit_number', label: 'Permit Number', type: 'number' },
  ];

  const form_fields = fields.map((field) => (
    <AntdFormField
      {...field}
      onChange={handleChange}
      defaults={emissions}
      read_only={read_only}
    />
  ));

  // chunk fields into rows of 4 elements
  const chunked_fields = chunkArray(form_fields, 4);
  const form_rows = chunked_fields.map((chunk) => (
    <Row gutter={[24, 24]}>
      {addKeys(chunk.map((field) => (<Col span={6}>{field}</Col>)), false)}
    </Row>
  ));

  return (
    <>
      {addKeys(form_rows, false)}
    </>
  );
};
export default withRouter(Emissions);
