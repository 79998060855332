import React, { useState, useEffect } from 'react';
import {
  Row, Col, Form, Modal, Button,
} from 'antd';
import { pathOr } from 'ramda';
import { Select } from '../shared';
import { sortObjectsBy } from '../../lib/utils';

const AddLaborCodeModal = (props) => {
  const {
    work_order, labor_codes, call_type_labor_codes, selected_labor_codes,
    onCancel, addLaborCode, work_order_call_types,
  } = props;

  const [labor_code_options, setLaborCodeOptions] = useState([]);
  const [selected_labor_code, setSelectedLaborCode] = useState([]);

  const selectLaborCode = (labor_code_id) => {
    const lc = labor_codes.find((p) => p.id === labor_code_id);
    const labor_code = {
      ...lc,
      hours: 0,
      minutes: 0,
    };
    setSelectedLaborCode(labor_code);
  };

  const setSelectableLaborCodes = () => {
    const labor_codes_with_matching_ct = call_type_labor_codes.filter((ctlc) => (
      work_order_call_types.includes(ctlc.call_type_id)
    )).map((ctlc) => ctlc.labor_code_id);

    const used_lc_ids = selected_labor_codes.map((lc) => lc.id);
    const available_labor_codes = labor_codes.filter((lc) => (
      !used_lc_ids.includes(lc.id) && labor_codes_with_matching_ct.includes(lc.id)
    ));

    const is_indirect = work_order.call_types.includes(3);
    const indirect_filter = ({ code }) => code > 9000 && code < 9999;
    const filtered_labor_codes = is_indirect
      ? available_labor_codes.filter(indirect_filter) : available_labor_codes;

    const sorted_labor_codes = sortObjectsBy(filtered_labor_codes, 'name');

    const lc_opts = sorted_labor_codes.map((lc) => (
      {
        value: lc.id,
        label: lc.name,
      }
    ));
    setLaborCodeOptions(lc_opts);
  };

  useEffect(() => {
    setSelectableLaborCodes();
  }, [selected_labor_codes, work_order_call_types]);

  return (
    <Modal
      {...props}
      title="Add Labor Code"
      destroyOnClose
      footer={[
        <Button
          key="back"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!Object.keys(selected_labor_code).includes('id')}
          onClick={() => addLaborCode(selected_labor_code)}
        >
          Add Labor Code
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Labor Code"
            initialValue={pathOr(null, ['id'], selected_labor_code)}
          >
            <Select
              options={labor_code_options}
              onChange={(labor_code_id) => selectLaborCode(labor_code_id)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddLaborCodeModal;
