import React from 'react';
import JSA from './index';

// Need to put this in a separate component
//   because react-router was not playing well with rendering the same component
//   between the /jsa/:jid path and the /jsa/view/:jid path
// render: () => <JSA/> would not re-render if jumping between view and edit page
// component: () => <JSA /> threw console errors about updating state on unmounted components
const JsaView = (props) => (
  <JSA {...props} read_only />
);

export default JsaView;
