import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCallTypes = () => {
  const [options, setOptions] = useState([]);
  const [types, setTypes] = useState([]);

  const callTypes = useSelector((state) => state.callTypes);

  const ownershipAwareCallTypes = [
    'Preventative Maintenance',
    'Trouble Call',
    'Corrective Maintenance',
    'Pump Work Order',
    'Shop Rebuild',
  ];

  useEffect(() => {
    setTypes(callTypes?.callTypeList);
    setOptions(callTypes?.options);
  }, [callTypes]);

  const getCallTypeIdByName = (callTypeName) => types.find((element) => element.name === callTypeName).id;
  const getCallTypeNameById = (callTypeId) => types.find((element) => element.id === callTypeId).name;

  const getCallTypeUnitOwnershipConcern = (callTypeId) => ownershipAwareCallTypes.includes(getCallTypeNameById(callTypeId));

  const adjustCallTypes = (currentCallTypes, newCallType) => {
    const maybe_pm = currentCallTypes.includes(1) ? [1] : [];
    const maybe_tc = currentCallTypes.includes(2) ? [2] : [];
    const maybe_cm = currentCallTypes.includes(4) ? [4] : [];

    switch (newCallType) {
      case getCallTypeIdByName('Preventative Maintenance'):
        return [...maybe_tc, ...maybe_cm, newCallType];

      case getCallTypeIdByName('Trouble Call'):
        return [...maybe_pm, newCallType];

      case getCallTypeIdByName('Corrective Maintenance'):
        return [...maybe_pm, newCallType];

      default:
        return [newCallType];
    }
  };

  return {
    options, types, adjustCallTypes, getCallTypeUnitOwnershipConcern, getCallTypeIdByName,
  };
};

export default useCallTypes;
