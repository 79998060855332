import React, { useEffect, useState } from 'react';
import { sortObjectsBy } from '../../lib/utils';
import PMChecklistSection from './PMChecklistSection';
import pm_section_titles from '../../config/constants/pm_checklist';

const PMChecklist = (props) => {
  const {
    form,
    work_order,
    pm_templates,
    updateWorkOrderValue,
    selected_unit,
    read_only,
  } = props;

  const { pm_checklist = {} } = work_order;

  const [template_fields, setTemplateFields] = useState({});
  const [pm_sections, setPMSections] = useState([]);

  // cylinder fields can be loaded out of order. Sort them and put them at the top of each section
  const sortCylinders = (fields) => {
    const compression_fields = fields.engine_compression.reduce((acc, field) => {
      if (field.label.includes('Cylinder')) acc.cylinders = [...acc.cylinders, field];
      if (!field.label.includes('Cylinder')) acc.not_cylinders = [...acc.not_cylinders, field];

      return acc;
    }, { cylinders: [], not_cylinders: [] });

    const sorted_cylinders = sortObjectsBy(compression_fields.cylinders, 'label');

    return {
      ...fields,
      engine_compression: [
        ...sorted_cylinders,
        ...compression_fields.not_cylinders,
      ],
    };
  };

  // group all checkbox fields and put them at the end of each section to make it visually consistent
  const sortSectionByFieldType = (fields) => (
    Object.keys(fields).reduce((acc, key) => {
      const section = fields[key];
      section.sort((a, b) => (a.type === 'boolean') - (b.type === 'boolean'));
      acc[key] = section;
      return acc;
    }, {})
  );

  const handleChange = async (field, val) => {
    await updateWorkOrderValue(work_order.localId, ['pm_checklist', field], val);
  };

  const filterFields = () => {
    // find pm fields for this unit's pm_type
    const { pm_type_id = 2 } = selected_unit;
    const template = pm_templates.find((pmt) => pmt.id === pm_type_id);
    const fields_for_pm = template.template_fields.reduce((acc, tf) => {
      const { category } = tf;

      if (!Object.keys(acc).includes(category)) acc[category] = [];

      acc[category] = [...acc[category], tf];
      return acc;
    }, {});

    // put checkboxes at the end of each section
    const sorted_checkbox_fields = sortSectionByFieldType(fields_for_pm);

    // put cylinders in order at the top of record engine compression
    const cylinder_sorted_fields = Object.keys(sorted_checkbox_fields).includes('engine_compression')
      ? sortCylinders(sorted_checkbox_fields)
      : sorted_checkbox_fields;

    setTemplateFields(cylinder_sorted_fields);
  };

  // changing the dom (pm_sections) re-renders everything
  // have to re-run the validation so that "field_name is required" shows up
  useEffect(() => {
    form.validateFields();
  }, [pm_sections]);

  useEffect(() => {
    filterFields();
  }, [selected_unit]);

  useEffect(() => {
    filterFields();
  }, []);

  useEffect(() => {
    const sections = Object.keys(template_fields).map((key) => (
      <PMChecklistSection
        section={template_fields[key]}
        defaults={pm_checklist}
        handleChange={handleChange}
        title={pm_section_titles[key]}
        key={key}
        read_only={read_only}
      />
    ));

    setPMSections(sections);
  }, [template_fields]);

  if (Object.keys(pm_sections).length === 0) return <></>;

  return (
    <>
      {pm_sections}
    </>
  );
};

export default PMChecklist;
