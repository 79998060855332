/* eslint-disable no-shadow */
// eslint-disable-next-line import/no-extraneous-dependencies
import { capitalize, snakeCase } from 'lodash';
import {
  concat,
  addIndex,
  reduce,
  compose,
  map,
  split,
  join,
  assocPath,
  curry,
  equals,
  pathOr,
} from 'ramda';
import uuid from 'uuid/v4';
import moment from 'moment';
import { formatInTimeZone } from 'date-fns-tz';
import 'moment-timezone';
import {
  controlsPractices,
  potentialHazards,
  WORK_ORDER_BILLING_OPTIONS,
  TIMEZONES,
  DATE_FORMAT,
} from '../config/constants';
import { pump_service_type_options } from '../config/constants/pump_pm_checklist';

const iReduce = addIndex(reduce);

/**
 * Contains the details returned from an errored Joi.validate
 * @typedef {object} JoiDetails
 * @property {string} message - the message returned from Joi
 * @property {[string, number]} path - the path to the property that did not pass validation
 * @property {string} type - the Joi type that was being checked for
 */

/**
 * Takes any string and converts it to Title Case
 * @param {string} - the string to convert to title case
 * @returns {string} - a string in title case
 * @example
 *
 * const str = 'helloWorld'
 *
 * titleCase(str)
 * // 'Hello World'
 *
 */
export const titleCase = compose(
  join(' '),
  map(capitalize),
  split('_'),
  snakeCase,
);

/**
 * Check if there is an error returned from Joi for the current path
 * @param{[JoiDetails]} errors - errors returned from Joi
 * @param {[string]} path - the path to search for
 * @returns {boolean} - whether the given path has errored
 */
export const checkPathError = curry(
  (errors, path) => {
    const errorPaths = errors.reduce((acc, err) => [...acc, err.path], []);

    return errorPaths.some((err) => equals(err, path));
  },
);

/**
 * Check if the current field is contained in the errors returned from Joi
 * @param {[JoiDetails]} errors - errors returned from Joi
 * @param {string|[string]} field - the field(s) to search for
 * @returns {boolean} - whether there is an error for the particular field
 */
export const checkFieldError = curry(
  (errors, field) => {
    if (!Array.isArray(errors)) {
      return false;
    }
    const errorPaths = errors.reduce((acc, err) => [...acc, ...err.path], []);

    if (Array.isArray(field)) {
      return errorPaths.some((error) => field.includes(error));
    }

    return errorPaths.includes(field);
  },
);

/**
 * Return the error for a given field, if it exists
 * @param {[JoiDetails]} errors - errors returned from Joi
 * @param {string|[string]} field - the field(s) to search for
 * @return {JoiDetails} - the error generated by Joi for the given field
 */
export const getFieldError = curry(
  (errors, field, exclude = '') => {
    if (Array.isArray(field)) {
      return errors.find((error) => field.every((f) => error.path.includes(f)));
    }

    return errors
      .filter((error) => !error.path.includes(exclude))
      .find((error) => error.path.includes(field));
  },
);

/**
 * Collates a list of items,
 * where each item in each chunk obeys: index % n === 0
 * @param {number} n - the number of "pages" per chunk
 * @param {[any]} list - the list of items to collate
 * @returns {[any]} - the collated list
 */
export const splitIntoNColumns = (n, list) => list.reduce(
  (acc, val, index) => {
    // get the index of the column it should go in
    const i = index % n;

    // create a new column
    const col = [...acc[i], val];

    // update the column with the new value
    return assocPath([i], col, acc);
  },
  [...Array(n)].fill([]),
);

const propRegex = /^\".+\"$/g; // eslint-disable-line

/**
 * Formats a message generated by Joi
 * @param {JoiDetails.message} message - message generated by Joi
 * @returns {string} - the formated message
 */
export const formatErrorMessage = compose(
  join(' '),
  map(s => propRegex.test(s) ? `"${titleCase(s)}"` : s), // eslint-disable-line
  split(' '),
);

export const makeOptions = curry((label_props, val_prop, val) => {
  const make_label = curry((val, acc, label_prop, index) => {
    const label = val[label_prop] || '';
    if (index === 0) { return concat(acc, label); }
    if (label_prop === 'last_name') { return concat(acc, ` ${label}`); }
    return concat(acc, ` - ${label}`);
  });
  const label = iReduce(make_label(val), '', label_props);
  return {
    label,
    value: val[val_prop],
  };
});

export const removeUnderscores = (x) => {
  const acronyms = ['Pm'];
  const strSplit = x.split('_');
  // This looks like ramda map, refactor native
  // eslint-disable-next-line no-shadow
  const upper_case_split = map((x) => (x.charAt(0)).toUpperCase() + x.slice(1), strSplit);
  const words = upper_case_split.map((ucs) => (acronyms.includes(ucs) ? ucs.toUpperCase() : ucs));
  return words.join(' ');
};

export const mergePrimitiveArrayToString = (x) => reduce(concat, '', map(toString, x));

// defaults to record.id, pass bool false to generate a uuid or str key to override
export const addKeys = (arr, key = 'id') => (
  arr.map((item) => ({
    ...item,
    key: (key !== false ? item[key] : uuid()),
  }))
);

// removes objects from @arr containing duplicate values at @path
export const removeDuplicateRecords = (arr, path) => {
  const path_values = [];
  return arr.reduce((new_set, record) => {
    const path_value = pathOr(null, path, record);

    if (path_values.includes(path_value) && path_value) return new_set;

    path_values.push(path_value);
    return [...new_set, record];
  }, []);
};

// converts @str str snake_case to Title Case
export const formatSnakeCase = (str) => (
  str.split('_')
    .map((w) => (w[0].toUpperCase() + w.substr(1).toLowerCase()))
    .join(' ')
);

// standard antd column definition
export const createAntdColumns = (arr) => (
  arr.map((col) => (
    {
      title: formatSnakeCase(col),
      key: col,
      dataIndex: col,
    }
  ))
);

export const chunkArray = (arr, size) => (
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => (
    arr.slice(i * size, i * size + size)))
);

export const uniqueValues = (arr) => [...new Set(arr)];

export const isDefined = (val, exclude_empty = true) => {
  const defined = val !== undefined;
  const not_null = val !== null;
  const not_empty = exclude_empty && Array.isArray(val) ? val.length > 0 : true;
  return defined && not_null && not_empty;
};

export const removeNullsFromObject = (obj) => (
  Object.keys(obj).reduce((acc, key) => {
    const val = obj[key];

    if (!isDefined(val)) return acc;

    return { ...acc, [key]: val };
  }, {})
);

export const convertDateToCentral = (date) => {
  let date_obj = new Date(date);
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (currentTimeZone !== TIMEZONES.central) date_obj = formatInTimeZone(date_obj, TIMEZONES.central.value, DATE_FORMAT);

  return date_obj;
};

export const convertCentralToDate = (date, to_tz) => {
  const date_obj = moment(date || moment());
  const date_string = date_obj.format('YYYY-MM-DD HH:mm:ss');
  const from_time = moment.tz(date_string, 'America/Chicago');
  return moment.tz(from_time, to_tz).format('YYYY-MM-DD HH:mm:ss');
};

// expects moment obj
export const isValidDate = (date, format) => (
  moment(date, (format || moment.ISO_8601), true).isValid()
);

export const guessSorter = (first, second, key) => {
  const a = first[key] || '';
  const b = second[key] || '';

  if (isValidDate(a) && isValidDate(b)) return moment(a).isAfter(moment(b));

  if (parseInt(a, 10) && parseInt(b, 10)) return a - b;

  return a.localeCompare(b);
};

// sort @arr of objects by values at @key
export const sortObjectsBy = (arr, key) => (
  arr.sort((a, b) => guessSorter(a, b, key))
);

// formats the response from GET /workorder to something the Work Order component can use
// reverts all the workarounds needed to make antd work marginally smoothly
// what a good idea it seemed at the time...
export const formatApiWorkOrder = (wo) => {
  const call_types = wo.call_types.map((ct) => ct.call_type_id);
  const billing = WORK_ORDER_BILLING_OPTIONS
    .map((o) => (wo[o.value] ? o.value : null))
    .filter((o) => o);
  const meta = wo.pump_work_order_meta || {};
  const service_types = pump_service_type_options
    .map((o) => (meta[o.value] ? o.value : null))
    .filter((o) => o);
  const [start, end] = [wo.start_time, wo.end_time].map((t) => moment(t));
  const total_time = end.diff(start, 'seconds', true);
  const { wo: workorder_note_defaults, pump: note_defaults } = pathOr([], ['notes'], wo).reduce((acc, n) => {
    const pump_notes = n.note_type.includes('pump_pm_') ? { ...acc.pump, [n.note_type]: n.text } : acc.pump;
    return {
      ...acc,
      wo: {
        ...acc.wo, [n.note_type]: n.text,
      },
      pump: pump_notes,
    };
  }, { wo: {}, pump: {} });
  const checklist = pathOr([], ['newset_checklist'], wo);
  const formatted_newset = Object.keys(checklist).reduce((acc, key) => (
    { ...acc, [key]: (checklist[key] === null ? 'n_a' : checklist[key]) }
  ), {});

  // just put everything back in here since the page expects a lot to be in this obj
  const basic_information = {
    ...wo,
  };

  // visible leaks was historically a text field, but it's now a boolean field saving to a text col
  // perhaps a db migration is in order, but I don't know how important that field is
  // so just guess-cast it for, assuming that all new WOs through new client will use booleans
  const pm_checklist = {
    ...wo.pm_checklist,
    visible_leaks: pathOr(null, ['pm_checklist', 'visible_leaks'], wo) !== 'false',
  };

  const unit_readings = {
    ...wo.unit_readings,
    ur_engine_oil_pressure: pathOr(null, ['unit_readings', 'engine_oil_pressure'], wo),
    suction_temp: pathOr(null, ['unit_readings', 'suction_temperature'], wo),
  };

  const pump_pm_checklist = {
    ...wo.pump_pm_checklist,
    note_defaults,
  };

  const pump_work_order_meta = {
    ...wo.pump_work_order_meta,
    service_types,
  };

  const newset_checklist = {
    ...formatted_newset,
    unit_running: true,
  };

  return {
    ...wo,
    unit_id: wo.asset_id,
    billing,
    call_types,
    total_time,
    workorder_note_defaults,
    basic_information,
    pm_checklist,
    unit_readings,
    pump_pm_checklist,
    pump_work_order_meta,
    newset_checklist,
  };
};

/**
 * This function adds what is need to format a local but synged work order for
 * the view displa. Currently, this only formats time.
 * @param {*} wo
 * @returns workOrder
 */
export const formatLocalWorkOrder = (wo) => {
  const [start, end] = [wo.start_time, wo.end_time].map((t) => moment(t));
  const total_time = end.diff(start, 'seconds', true);

  wo.total_time = total_time;
  return wo;
};

// formats the response from GET /jsa to something the JSA component can use
// reverts all the workarounds needed to make antd work marginally smoothly
export const formatApiJsa = (jsa) => {
  // just put everything back in here since the page expects a lot to be in this obj
  const basic_information = {
    ...jsa,
  };

  const controls_checklists = controlsPractices
    .reduce((acc, c) => {
      if (jsa.controls_checklists[c.value] === null) return acc;
      return {
        ...acc,
        [c.value]: true,
      };
    }, {});

  const hazards = potentialHazards
    .reduce((acc, h) => {
      if (jsa.hazards_checklists[h.value] === null) return acc;
      return {
        ...acc,
        [h.value]: true,
      };
    }, {});

  const hazards_checklists = {
    ...hazards,
    plan: jsa.hazards_checklists.plan,
  };

  const signatures = jsa.signatures.map((s) => s.signature);

  return {
    ...jsa,
    basic_information,
    customer_id: jsa.ns_customer_id,
    controls_checklists,
    hazards_checklists,
    signatures,
  };
};

export const formatApiVehicleInspection = (inspection) => {
  // just put everything back in here since the page expects a lot to be in this obj
  // inspected_by should be a mechanic_id, but the response is an obj of the record
  const basic_information = {
    ...inspection,
    inspected_by: inspection.inspected_by.user_id,
  };

  // convert intersection records to what the form expects
  const reMapCheckList = (checklist) => (
    checklist.reduce((acc, cl) => (
      { ...acc, [cl.field_key]: Number((cl.value === null ? '-1' : cl.value)) }
    ), {})
  );
  const interior = inspection.inspection_checklist.filter((f) => f.field_key.includes('interior_'));
  const exterior = inspection.inspection_checklist.filter((f) => f.field_key.includes('exterior_'));

  return {
    ...inspection,
    basic_information,
    interior_checklist: reMapCheckList(interior),
    exterior_checklist: reMapCheckList(exterior),
  };
};

export const convertObjVals = (arr = [], search, replace) => (
  Object.keys(arr).reduce((acc, key) => (
    {
      ...acc,
      [key]: (arr[key] === search ? replace : arr[key]),
    }
  ), {})
);

export const ucFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// borrowed from underscores and modified to remove immediate option
export const debounce = (func, wait) => {
  let timeout = true;
  return (...args) => {
    const later = () => {
      timeout = false;
      return func.apply(this, args);
    };
    const callNow = !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, args);
  };
};
