import { createSlice } from '@reduxjs/toolkit';
import { GET_CALL_TYPES, GET_CALL_TYPES_SUCCESS, GET_CALL_TYPES_FAILED } from '../actionTypes/index';

const initialState = {
  isPending: false,
  callTypeList: [],
  options: [],
};

export const callTypesSlice = createSlice({
  name: 'callTypes',
  initialState,
  extraReducers(builder) {
    builder.addCase(GET_CALL_TYPES, (state) => ({
      ...state,
      isPending: true,
    }));
    builder.addCase(GET_CALL_TYPES_SUCCESS, (state, action) => ({
      ...state,
      isPending: false,
      callTypeList: action.response,
      options: action.response.map((ct) => ({ label: ct.name, value: ct.id })),
    }));
    builder.addCase(GET_CALL_TYPES_FAILED, (state) => ({
      ...state,
      isPending: false,
    }));
  },
});

export default callTypesSlice.reducer;
