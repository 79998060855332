export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_FAILED = 'GET_USER_SETTINGS_FAILED';

export const GET_MECHANIC = 'GET_MECHANIC';
export const GET_MECHANIC_SUCCESS = 'GET_MECHANIC_SUCCESS';
export const GET_MECHANIC_FAILED = 'GET_MECHANIC_FAILED';

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAILED = 'UPDATE_USER_SETTINGS_FAILED';

export const UPDATE_MECHANIC = 'UPDATE_MECHANIC';
export const UPDATE_MECHANIC_SUCCESS = 'UPDATE_MECHANIC_SUCCESS';
export const UPDATE_MECHANIC_FAILED = 'UPDATE_MECHANIC_FAILED';
