import React from 'react';

import {
  HomeFilled,
  MailOutlined,
  PhoneOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

const icons = {
  HomeFilled,
  MailOutlined,
  PhoneOutlined,
  WarningOutlined,
  InfoCircleOutlined,
};

const AntDesignIcon = ({ icon, style = { color: '#f05921' } }) => {
  const Icon = icons[icon];

  return (
    <Icon style={style} />
  );
};

export default AntDesignIcon;
