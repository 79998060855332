import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const StyledButton = styled(Button)`
    &.ant-btn[disabled] {
      color: #fff;
      background-color: rgba(24, 143, 255, .5);
      border-color: #4cae4c;
    }

    //SUCCESS STYLES
    &.ant-btn-success {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
    }
    &.ant-btn-success:hover {
      color: #fff;
      background-color: #98cc98;
      border-color: #398439;
    }
    &.ant-btn-success:focus {
      color: #fff;
      background-color: #449d44;
      border-color: #255625;
    }
    &.ant-btn-success:active {
      color: #fff;
      background-color: #449d44;
      border-color: #398439;
    }
    .ant-btn-success-disabled, .ant-btn-success.disabled, .ant-btn-success[disabled], .ant-btn-success-disabled:hover, .ant-btn-success.disabled:hover, .ant-btn-success[disabled]:hover, .ant-btn-success-disabled:focus, .ant-btn-success.disabled:focus, .ant-btn-success[disabled]:focus, .ant-btn-success-disabled:active, .ant-btn-success.disabled:active, .ant-btn-success[disabled]:active, .ant-btn-success-disabled.active, .ant-btn-success.disabled.active, .ant-btn-success[disabled].active {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }

    //INFO STYLES
    &.ant-btn-info {
      color: #fff;
      background-color: #5ca8b8;
      border-color: #4f9fb0;
    }
    &.ant-btn-info:hover {
      color: #fff;
      background-color: #93bfc9;
      border-color: #387482;
    }
    &.ant-btn-info:focus {
      color: #fff;
      background-color: #428d9e;
      border-color: #264e57;
    }
    &.ant-btn-info:active {
      color: #fff;
      background-color: #428d9e;
      border-color: #387482;
    }
    .ant-btn-info-disabled, .ant-btn-info.disabled, .ant-btn-info[disabled], .ant-btn-info-disabled:hover, .ant-btn-info.disabled:hover, .ant-btn-info[disabled]:hover, .ant-btn-info-disabled:focus, .ant-btn-info.disabled:focus, .ant-btn-info[disabled]:focus, .ant-btn-info-disabled:active, .ant-btn-info.disabled:active, .ant-btn-info[disabled]:active, .ant-btn-info-disabled.active, .ant-btn-info.disabled.active, .ant-btn-info[disabled].active {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }

    //WARNING STYLES
    &.ant-btn-warning {
      color: #fff;
      background-color: #e3bd32;
      border-color: #ffcf1f;
    }
    &.ant-btn-warning:hover {
      color: #fff;
      background-color: #f5d55f;
      border-color: #ffd53d;
    }
    &.ant-btn-warning:focus {
      color: #fff;
      background-color: #ebc84b;
      border-color: #c99f08;
    }
    &.ant-btn-warning:active {
      color: #fff;
      background-color: #e6c13e;
      border-color: #c99f08;
    }
    .ant-btn-warning-disabled, .ant-btn-warning.disabled, .ant-btn-warning[disabled], .ant-btn-warning-disabled:hover, .ant-btn-warning.disabled:hover, .ant-btn-warning[disabled]:hover, .ant-btn-warning-disabled:focus, .ant-btn-warning.disabled:focus, .ant-btn-warning[disabled]:focus, .ant-btn-warning-disabled:active, .ant-btn-warning.disabled:active, .ant-btn-warning[disabled]:active, .ant-btn-warning-disabled.active, .ant-btn-warning.disabled.active, .ant-btn-warning[disabled].active {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }
`;

const WrappedButton = ({
  type, children, disabled, ...rest
}) => {
  const added_types = {
    success: 'ant-btn-success',
    info: 'ant-btn-info',
    warning: 'ant-btn-warning',
  };
  const added_disabled_types = {
    success: 'ant-btn-success-disabled',
    info: 'ant-btn-info-disabled',
    warning: 'ant-btn-warning-disabled',
  };
  if (Object.keys(added_types).includes(type)) {
    const class_name = rest.disabled ? added_disabled_types[type] : added_types[type];
    return (
      <StyledButton className={class_name} disabled={disabled} {...rest}>
        {children}
      </StyledButton>
    );
  }
  return (
    <StyledButton type={type} disabled={disabled} {...rest}>
      {children}
    </StyledButton>
  );
};

export default WrappedButton;
