import styled from 'styled-components';

const Panel = styled.div`
  &&{
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid rgba(51, 51, 51, .5);
    border-radius: 4px;
    box-shadow: rgb(205 212 218) 1px 1px 2px;
  }
`;

export default Panel;
