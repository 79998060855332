const authConfig = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  responseType: 'token id_token',
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
  scope: 'openid profile email is:mechanic',
};

export default authConfig;
