import React from 'react';
import styled from 'styled-components';
import { Panel } from '.';

const Container = styled.div`
  text-align: center;
`;

const ValueDiv = styled.div`
  font-size: 40px;
`;

const LabelDiv = styled.div`
  font-size: 20px;
`;

const Scoreboard = (props) => {
  const { label, value } = props;
  return (
    <Panel>
      <Container style={{ textAlign: 'center' }}>
        <ValueDiv>{value}</ValueDiv>
        <LabelDiv>{label}</LabelDiv>
      </Container>
    </Panel>
  );
};

export default Scoreboard;
