import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, Form, InputNumber, Table,
} from 'antd';
import { DeleteOutlined, PlusSquareFilled } from '@ant-design/icons';
import AddPartModal from '../../Modals/AddPartModal';
import { addKeys } from '../../../lib/utils';
import { Select } from '../../shared';

const { confirm } = window;

const Parts = (props) => {
  const {
    work_order, updateWorkOrderValue, form, read_only,
    setSelectedParts, selected_parts,
    labor_codes, selected_labor_codes,
  } = props;

  const [part_modal_visible, setPartModalVisible] = useState(false);
  const [labor_code_options, setLaborCodeOptions] = useState([]);

  const addPart = async (part) => {
    const updated_parts = [
      ...selected_parts,
      part,
    ];
    await updateWorkOrderValue(work_order.localId, ['parts'], updated_parts);
    setSelectedParts(updated_parts);
  };

  const updatePartValue = async (row, field, value) => {
    const updated_parts = selected_parts.map((p) => {
      if (p.id === row.id) p[field] = value;
      return p;
    });

    await updateWorkOrderValue(work_order.localId, ['parts'], updated_parts);
    setSelectedParts(updated_parts);
  };

  const deletePart = async (part) => {
    const msg = `Are you sure you wish to remove this part (${part.description})?`;
    if (!confirm(msg)) return;

    const updated_parts = selected_parts.filter((p) => p.id !== part.id);
    await updateWorkOrderValue(work_order.localId, ['parts'], updated_parts);
    setSelectedParts(updated_parts);
  };

  const laborCodeValidator = async (row) => {
    const form_values = form.getFieldsValue();
    if (form_values[`part_${row.id}_labor_code_id`]) return;

    // include name so that the notifications are useful (vs using {required:true})
    const trimmed_name = row.description.slice(0, 25) + (row.description.length > 25 ? ' ...' : '');
    throw new Error(`Select a labor code for ${trimmed_name}`);
  };

  useEffect(() => {
    const used_labor_codes = selected_labor_codes.map((lc) => lc.id);
    const selectable_labor_codes = labor_codes.filter((lc) => used_labor_codes.includes(lc.id));
    const lc_opts = selectable_labor_codes.map((lc) => (
      {
        value: lc.id,
        label: lc.name,
      }
    ));
    setLaborCodeOptions(lc_opts);
  }, [selected_labor_codes]);

  const cols = [
    {
      title: 'Part',
      dataIndex: 'number',
      key: 'number',
      width: '50%',
      render: (_, row) => (
        <div>
          <h5>{row.number}</h5>
          <p>{row.description}</p>
        </div>
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_, row) => (
        <Form.Item
          name={`part_${row.id}_quantity`}
          initialValue={row.quantity || 1}
        >
          <InputNumber
            min={1}
            max={100}
            onChange={(val) => updatePartValue(row, 'quantity', val)}
            disabled={read_only}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Warranty',
      dataIndex: 'warranty',
      key: 'warranty',
      render: (_, row) => (
        <Form.Item
          name={`part_${row.id}_warranty`}
          valuePropName="checked"
          initialValue={row.warranty}
        >
          <Checkbox
            onChange={(e) => updatePartValue(row, 'warranty', e.target.checked)}
            disabled={read_only}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Billable',
      dataIndex: 'billable',
      key: 'billable',
      render: (_, row) => (
        <Form.Item
          name={`part_${row.id}_billable`}
          valuePropName="checked"
          initialValue={row.billable}
        >
          <Checkbox
            onChange={(e) => updatePartValue(row, 'billable', e.target.checked)}
            disabled={read_only}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Labor Code',
      dataIndex: 'labor_code_id',
      key: 'labor_code_id',
      width: '40%',
      render: (_, row) => (
        <Form.Item
          name={`part_${row.id}_labor_code_id`}
          initialValue={row.labor_code_id}
          rules={[
            { validator: () => laborCodeValidator(row) },
          ]}
        >
          <Select
            options={labor_code_options}
            onChange={(val) => updatePartValue(row, 'labor_code_id', val)}
            disabled={read_only}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      dataIndex: 'delete',
      width: '5%',
      render: (_, row) => (
        <Button
          type="primary"
          shape="round"
          title="Delete"
          style={{ textAlign: 'center' }}
          icon={<DeleteOutlined />}
          danger
          onClick={() => deletePart(row)}
        />
      ),
    },
  ];

  const columns = read_only ? cols.filter((c) => c.key !== 'delete') : cols;

  return (
    <>
      { !read_only && (
        <Button
          style={{ float: 'right', marginBottom: '10px' }}
          type="primary"
          onClick={() => setPartModalVisible(true)}
        >
          <PlusSquareFilled />
          Add Part
        </Button>
      )}
      <Table
        columns={columns}
        dataSource={addKeys(selected_parts, false)}
        pagination={false}
      />
      <AddPartModal
        {...props}
        selected_parts={selected_parts}
        visible={part_modal_visible}
        closeModal={() => setPartModalVisible(false)}
        addPart={addPart}
      />
    </>
  );
};

export default Parts;
