/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useContext } from 'react';

// config
import { Form } from 'antd';
import { TIME_ADJUSTMENT_LABOR_CODES } from '../../../config/constants';
import { UserSettingsContext } from '../../UserSettings/UserSettingsProvider';

// components
import TimeDisplayBox from './Time-Display-Box';

// custom hooks
import { useTimer } from '../../hooks';

const LaborCodeTimer = (props) => {
  const {
    form, work_order, read_only, selected_labor_codes, editable,
  } = props;

  const [allocated, setAllocatedTime] = useState(0);
  const [validUnallocatedTime, setValidUnallocatedTime] = useState(false);
  const [timeAdjustmentAmount, setTimeAdjustmentAmount] = useState(0);

  const {
    allocated_time,
    adjustment_time,
    unallocated_time,
    total_time,
    formatTime,
  } = useTimer(allocated, work_order.timestamp, timeAdjustmentAmount);
  // overwrite the times with static values if in readonly mode
  const total = read_only || editable ? work_order.total_time : total_time;
  const unallocated = read_only || editable ? work_order.total_time - allocated * 60 : unallocated_time;

  const defaultTimesToDisplay = () => [
    {
      key: 0,
      name: 'Total Work Order Time',
      time: formatTime(total),
      valueStyle: { },
    },
    {
      key: 1,
      name: 'Time Adjustments',
      time: formatTime(adjustment_time, true),
    },
    {
      key: 2,
      name: 'Allocated Time',
      time: formatTime(allocated_time),
      valueStyle: { color: 'darkgrey' },
    },
    {
      key: 3,
      name: 'Unallocated Time',
      time: formatTime(unallocated, true),
      valueStyle: { color: validUnallocatedTime ? 'green' : 'red' },
    },
  ];

  const { user_settings } = useContext(UserSettingsContext);

  const [hasPositiveTimeAdjustment, setHasPositiveTimeAdjustment] = useState(false);
  const [displayTimeAdjustments, setDisplayTimeAdjustments] = useState(false);
  const [timesToDisplay, setTimesToDisplay] = useState(defaultTimesToDisplay);

  const timerValidator = async () => {
    if (read_only || editable || hasPositiveTimeAdjustment) return;

    const timeDifference = total_time - allocated_time;

    if (timeDifference < 0 && timeDifference <= -300) throw new Error('Allocated time exceeds total work order time by more than 5 minutes!');

    if (timeDifference > 300) throw new Error('More than 5 minutes unallocated!');
  };

  // the timer re-renders a lot, need to run this to keep error messages visible
  const validateFields = () => {
    const form_fields = Object.keys(form.getFieldsValue());
    const fields_to_validate = form_fields.filter((field) => (
      (field.includes('labor_code_') && field.includes('_name'))
      || (field.includes('part_') && field.includes('_labor_code_id'))
      || (field === 'labor_code_timer')
    ));

    form.validateFields(fields_to_validate);
  };

  const calculateAllocatedMinutes = () => selected_labor_codes.reduce((accTotal, lc) => {
    if (lc.minutes > 55) lc.minutes = 55;
    let laborCodeTime = lc.hours * 60 + lc.minutes;

    if (displayTimeAdjustments && Object.values(TIME_ADJUSTMENT_LABOR_CODES).includes(lc.name)) {
      // eslint-disable-next-line no-param-reassign
      if (lc.name === TIME_ADJUSTMENT_LABOR_CODES.negative) laborCodeTime *= -1;

      accTotal.timeAdjustments += laborCodeTime;

      return { ...accTotal };
    }

    accTotal.allocatedMinutes += laborCodeTime;

    return { ...accTotal };
  }, { allocatedMinutes: 0, timeAdjustments: 0 });

  useEffect(() => {
    setHasPositiveTimeAdjustment(selected_labor_codes.some((lc) => lc.name === TIME_ADJUSTMENT_LABOR_CODES.positive));

    const { allocatedMinutes, timeAdjustments } = calculateAllocatedMinutes();

    form.setFieldsValue({ labor_code_timer: allocatedMinutes });

    setAllocatedTime(allocatedMinutes);
    setTimeAdjustmentAmount(timeAdjustments);
  }, [selected_labor_codes, displayTimeAdjustments]);

  useEffect(() => {
    const unallocatedWithinFiveMin = unallocated > -300 && unallocated < 300;

    setValidUnallocatedTime((unallocatedWithinFiveMin || hasPositiveTimeAdjustment));
    validateFields();
  }, [unallocated]);

  useEffect(() => {
    if (displayTimeAdjustments !== user_settings?.displayTimeAdjustments) {
      setDisplayTimeAdjustments(user_settings?.displayTimeAdjustments);
    }

    const newTimesToDisplay = defaultTimesToDisplay();

    if (!user_settings?.displayTimeAdjustments) {
      setTimesToDisplay(newTimesToDisplay.filter((data) => data.name !== 'Time Adjustments'));
      return;
    }

    setTimesToDisplay(newTimesToDisplay);
  }, [user_settings, allocated_time, adjustment_time, unallocated_time]);

  useEffect(() => {
    validateFields();
  }, []);

  return (
    <Form.Item
      className={`section-validation--border${validUnallocatedTime ? '__lightgrey' : '__red'}`}
      style={{
        padding: '2%',
        margin: '2%',
        textAlign: 'center',
        backgroundColor: '#fafafa',
      }}
      name="labor_code_timer"
      rules={[{ validator: timerValidator }]}
    >
      <div
        className="grid-row"
        style={{ marginBottom: '2%' }}
        justify="center"
      >
        {timesToDisplay.map((boxprops) => <TimeDisplayBox {...boxprops} />)}
      </div>
    </Form.Item>
  );
};

export default LaborCodeTimer;
