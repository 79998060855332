import uuid from 'uuid/v4';
import moment from 'moment';
import 'moment-timezone';
import { assocPath } from 'ramda';
import db from '.';
import { convertDateToCentral } from '../lib/utils';

const table = db.vehicle_inspections;

export const createVehicleInspection = async (user_settings, mechanic) => {
  const localId = uuid();
  const version = moment().unix();
  const flogistix_id = `${mechanic.mechanic_number || 'TES1234'}_vehicleinspection_${version}`;
  const { truck_id } = user_settings;

  const initalized_inspection = {
    id: localId,
    localId,
    version,
    flogistix_id,
    mechanic_id: mechanic.id,
    truck_id,
    complete: false,
    synced: false,
    basic_information: {
      inspection_date: moment().format(),
      inspected_by: mechanic.id,
    },
  };

  await table.put(initalized_inspection);
  return initalized_inspection;
};

export const getCurrentVehicleInspection = async () => {
  const records = await table.toArray();
  return records.find((vi) => !vi.complete);
};

export const getVehicleInspection = async (id) => table.get(id);

export const getVehicleInspections = async () => table.toArray();

export const updateVehicleInspection = async (id, update) => table.update(update, id);

export const updateVehicleInspectionValue = async (id, path, value) => {
  if (!id) throw new Error('Vehicle Inspection id is required for update');

  const current_inspection = await getVehicleInspection(id);
  const update = assocPath(path, value, current_inspection);
  return table.put(update);
};

export const deleteVehicleInspection = (id) => table.delete(id);

export const hasCompletedInspectionToday = async (timezone) => {
  const start = moment.tz(timezone).startOf('day');
  const end = start.clone().add(1, 'days');
  const start_time = convertDateToCentral(start, timezone);
  const end_time = convertDateToCentral(end, timezone);
  const daily_inspection_count = await db.vehicle_inspections
    .where('date_submitted')
    .between(start_time, end_time, true, true)
    .count();
  return daily_inspection_count !== 0;
};
