import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import { Row, Col } from 'antd';
import { AntdFormField } from '../shared';
import {
  new_set_checklist_fields, new_set_checklist_options,
  new_set_previous_location_options, new_set_transported_by_options,
}
  from '../../config/constants/new_set';
import { addKeys, chunkArray } from '../../lib/utils';

const NewsetChecklist = (props) => {
  const {
    form,
    mechanics,
    warehouses,
    work_order,
    updateWorkOrderValue,
    read_only,
  } = props;

  const { new_sets, newset_checklist: checklist } = work_order;

  const [unit_running, setUnitRunning] = useState(pathOr(false, ['unit_running'], checklist));
  const [previous_location, setPreviousLocation] = useState(pathOr(null, ['previous_location'], checklist));

  const handleNewSetChange = async (field, val) => {
    await updateWorkOrderValue(work_order.localId, ['new_sets', field], val);
  };

  const handleNewSetChecklistChange = async (field, val) => {
    await updateWorkOrderValue(work_order.localId, ['newset_checklist', field], val);
  };

  const onUnitRunningChange = async (field, val) => {
    setUnitRunning(val);
    await handleNewSetChecklistChange(field, val);
  };

  const onPreviousLocationChange = async (field, val) => {
    setPreviousLocation(val);

    switch (val) {
      case 'Yard':
        await handleNewSetChecklistChange('previous_location_other', null);
        break;
      case 'Customer Location':
        await handleNewSetChecklistChange('previous_location_other', null);
        await handleNewSetChecklistChange('previous_location_yard', null);
        break;
      default: // formerly case 'Other'
        await handleNewSetChecklistChange('previous_location_yard', null);
        break;
    }

    await handleNewSetChecklistChange('previous_location', val);
  };

  // revalidate when re-rendering section
  useEffect(() => {
    form.validateFields();
  }, [unit_running, previous_location, props]);

  // revalidate when re-rendering section
  useEffect(() => {
    form.validateFields();
  }, []);

  const mechanic_options = mechanics
    ? mechanics.map((m) => ({ label: `${m.first_name} ${m.last_name}`, value: m.user_id }))
    : [];

  const warehouse_options = warehouses
    ? warehouses.filter((w) => !w.truck_id && w.description)
      .map((w) => ({ label: w.description, value: w.id }))
    : [];

  const checklist_fields = new_set_checklist_fields.map((field) => (
    <AntdFormField
      {...field}
      type="select"
      options={new_set_checklist_options}
      onChange={handleNewSetChecklistChange}
      defaults={checklist}
      read_only={read_only}
    />
  ));

  // chunk fields into rows of 4 elements
  const chunked_fields = chunkArray(checklist_fields, 4);
  const form_rows = chunked_fields.map((chunk) => (
    <Row gutter={[24, 24]}>
      {addKeys(chunk.map((field) => (<Col span={6}>{field}</Col>)), false)}
    </Row>
  ));

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <AntdFormField
            name="unit_running"
            label="Was the unit running before leaving location?"
            type="yes_no"
            defaults={checklist}
            onChange={onUnitRunningChange}
            read_only={read_only}
          />
        </Col>
      </Row>
      <div style={{ display: unit_running ? 'block' : 'none' }}>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <AntdFormField
              name="assigned_mechanic_id"
              label="Assigned Mechanic"
              type="select"
              options={mechanic_options}
              defaults={new_sets}
              onChange={handleNewSetChange}
              read_only={read_only}
            />
          </Col>
          <Col span={6}>
            <AntdFormField
              name="transported_by"
              label="How was this unit transported?"
              type="select"
              options={new_set_transported_by_options}
              defaults={new_sets}
              onChange={handleNewSetChange}
              read_only={read_only}
            />
          </Col>
          <Col span={6}>
            <AntdFormField
              name="previous_location"
              label="Where was this unit located previously?"
              type="select"
              options={new_set_previous_location_options}
              defaults={checklist}
              onChange={onPreviousLocationChange}
              read_only={read_only}
            />
          </Col>
          { previous_location === 'Yard' && (
            <Col span={6}>
              <AntdFormField
                name="previous_location_yard"
                label="Flogistix Yard Location"
                type="select"
                options={warehouse_options}
                defaults={checklist}
                onChange={handleNewSetChecklistChange}
                read_only={read_only}
              />
            </Col>
          )}
          { previous_location === 'Other' && (
            <Col span={6}>
              <AntdFormField
                name="previous_location_other"
                label="Other Location"
                type="input"
                defaults={checklist}
                onChange={handleNewSetChecklistChange}
                read_only={read_only}
              />
            </Col>
          )}
        </Row>
        {addKeys(form_rows, false)}
        <Row>
          <AntdFormField
            name="comments"
            label="Unit Condition Comments"
            type="textarea"
            defaults={checklist}
            placeholder="Please list reasons for all Failures"
            onChange={handleNewSetChecklistChange}
            read_only={read_only}
          />
        </Row>
      </div>
    </>
  );
};

export default NewsetChecklist;
