import React from 'react';
import { Spin } from 'antd';
import 'moment-timezone';
import VehicleInspectionSection from './VehicleInspectionSection';

const InteriorChecklist = (props) => {
  const {
    vehicle_inspection,
    updateInspection,
    vehicle_inspection_fields,
    read_only,
  } = props;

  const ext = vehicle_inspection.interior_checklist || {};

  const section_order = ['General', 'Emergency Equipment', 'Miscellaneous'];

  const interior_sections = section_order.reduce((acc, title) => (
    [
      ...acc,
      {
        title,
        fields: vehicle_inspection_fields.filter((vif) => vif.subsection === title && vif.section === 'Interior'),
      },
    ]
  ), []);

  const handleChange = async (field, val) => {
    await updateInspection(['interior_checklist', field], val);
  };

  if (!vehicle_inspection || !vehicle_inspection_fields) return <Spin />;

  const sections = interior_sections.map((section) => (
    <VehicleInspectionSection
      {...section}
      defaults={ext}
      handleChange={handleChange}
      key={`exterior_vi_section_${section.title}`}
      read_only={read_only}
    />
  ));

  return (
    <>
      {sections}
    </>
  );
};

export default InteriorChecklist;
