import db from '.';

/**
 * Gets user mechanic from the mechanic table
 * @returns {Promise} - Resolves with the returned mechanic
 */
export const getUserMechanic = async () => db.user_mechanic.toArray();

export const getUserMechanicRecord = async () => {
  const user_mechanic_array = await db.user_mechanic.toArray();
  return user_mechanic_array[0];
};

export const updateUserMechanic = async (mech) => db.user_mechanic.put(mech);

export const getMechanicUnits = async (mechanic_id) => db.units.where('assigned_mechanic_id').equals(mechanic_id).toArray();
