import moment from 'moment';
import db from '.';

const today = moment().startOf('day');

async function getMechanicUnits(mechanicId, filter) {
  const units = await db.units
    .where({ assigned_mechanic_id: 487 })
    .filter(filter)
    .toArray();
  return Promise.all(units.map(async (unit) => {
    if (unit.customer_id) {
      const customer = await db.customers.get(unit.customer_id);

      return {
        ...unit,
        customer,
      };
    }

    return unit;
  }));
}

const upcomingPm = (unit) => {
  const nextWeek = moment().add(7, 'days').endOf('day');

  return moment(unit.next_pm_date)
    .isBetween(
      today,
      nextWeek,
      null,
      '[]',
    );
};

const overduePm = (unit) => moment(unit.next_pm_date)
  .isBefore(today);

const completePm = (unit) => moment(unit.next_pm_date)
  .isAfter(today)
  && moment(unit.last_pm_date)
    .isSameOrBefore(today);

export async function getUpcomingPMs(mechanicId) {
  const units = await getMechanicUnits(
    mechanicId,
    (unit) => {
      if (!unit.next_pm_date) {
        return false;
      }

      return upcomingPm(unit);
    },
  );

  const typedUnits = units.map((value) => {
    const newValue = { ...value, row_type: 'upcoming' };
    return newValue;
  });
  return typedUnits;
}

export async function getOverduePMs(mechanicId) {
  const units = await getMechanicUnits(
    mechanicId,
    (unit) => {
      if (!unit.last_pm_date && !unit.next_pm_date) {
        return false;
      }

      return overduePm(unit);
    },
  );

  const typedUnits = units.map((value) => {
    const newValue = { ...value, row_type: 'overdue' };
    return newValue;
  });
  return typedUnits;
}

export async function getTotalPMs(mechanicId) {
  const units = await getMechanicUnits(
    mechanicId,
    (unit) => {
      if (!unit.next_pm_date) {
        return false;
      }
      return true;
    },
  );

  return units;
}

export async function getTotalUnits(mechanicId) {
  const units = await getMechanicUnits(
    mechanicId,
    () => true,
  );

  const typedUnits = units.map((unit) => {
    if (unit.shutin) return { ...unit, row_type: 'shutin' };
    if (upcomingPm(unit)) return { ...unit, row_type: 'upcoming' };
    if (completePm(unit)) return { ...unit, row_type: 'complete' };
    if (overduePm(unit)) return { ...unit, row_type: 'overdue' };
    return { ...unit, row_type: 'overdue' };
  });

  return typedUnits;
}
