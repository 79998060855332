import { Card } from 'antd';
import React from 'react';
import { pathOr } from 'ramda';
import { AntdFormField } from '../shared';

const PMChecklistSection = (props) => {
  const {
    name, label, note_type_id, defaults, options, pump_note_types, read_only,
    onFieldChange, onNotesChange,
  } = props;

  const note_type = pump_note_types.find((pnt) => pnt.note_type_id === note_type_id);
  const note_name = pathOr(note_type_id, ['note_type'], note_type);

  return (
    <Card>
      <AntdFormField
        type="select"
        name={name}
        label={label}
        options={options}
        defaults={defaults}
        onChange={onFieldChange}
        read_only={read_only}
      />
      <AntdFormField
        type="textarea"
        name={note_name}
        label="Notes"
        defaults={pathOr({}, ['note_defaults'], defaults)}
        onChange={onNotesChange}
        required={false}
        read_only={read_only}
      />
    </Card>
  );
};

export default PMChecklistSection;
