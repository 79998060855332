/* eslint-disable object-curly-newline */
export const additional_unit_reading_fields = [
  { category: 'unit_readings', name: 'hours_meter_reading', label: 'Hour Meter Reading' },
  { category: 'unit_readings', name: 'suction_pressure', label: 'Suction Pressure', required: false },
  { category: 'unit_readings', name: 'discharge_pressure', label: 'Discharge Pressure', required: false },
  { category: 'unit_readings', name: 'alternator_voltage', label: 'Alternator Voltage', required: false },
  { category: 'unit_readings', name: 'rpm', label: 'RPM', required: false },
  { category: 'unit_readings', name: 'engine_oil_pressure', label: 'Engine Oil Pressure', required: false },
  { category: 'unit_readings', name: 'compressor_oil_pressure', label: 'Comp Oil Pressure', required: false },
  { category: 'unit_readings', name: 'compressor_oil_diff_pressure', label: 'Comp Oil Filter Diff Pressure', required: false },
  { category: 'unit_readings', name: 'suction_temp', label: 'Water Temp', required: false },
  { category: 'unit_readings', name: 'turbo_water_in', label: 'Turbo Water In', required: false },
  { category: 'unit_readings', name: 'turbo_water_out', label: 'Turbo Water Out', required: false },
  { category: 'unit_readings', name: 'discharge_filter_diff_pressure', label: 'Discharge Filter Diff Pressure', required: false },
];

export const unit_reading_stage_fields = [
  { category: 'unit_readings', name: 'disc_pressure_%index%_stage', label: 'Disc Pressure' },
  { category: 'unit_readings', name: 'disc_temp_%index%_stage', label: 'Disc Temp' },
  { category: 'unit_readings', name: 'suction_temp_%index%_stage', label: 'Suction Temp' },
];
