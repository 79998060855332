import React, { useState, useEffect } from 'react';
import {
  Row, Col, Spin,
} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { AntdFormField } from '../shared';
import { uniqueValues } from '../../lib/utils';

const BasicInformation = (props) => {
  const {
    vehicle_inspection,
    updateInspection,
    fleet_vehicles,
    read_only,
  } = props;

  const bi = vehicle_inspection.basic_information;

  const [selected_make, setSelectedMake] = useState(null);
  const [vehicle_make_options, setVehicleMakeOptions] = useState([]);
  const [vehicle_model_options, setVehicleModelOptions] = useState([]);

  const handleChange = async (field, value) => {
    await updateInspection(['basic_information', field], value);
  };

  const handleMakeChange = async (field, val) => {
    setSelectedMake(val);
    await handleChange(field, val);
  };

  const handleDateChange = async (field, val) => {
    await handleChange(field, val.format('MM-DD-YYYY'));
  };

  // filter model list and create label/value options when vehicle_make changes
  useEffect(() => {
    const models = fleet_vehicles.filter((v) => v.make === selected_make);
    const model_opts = models.map((v) => (
      { label: v.model, value: v.id }
    ));

    setVehicleModelOptions(model_opts);
  }, [selected_make]);

  useEffect(() => {
    if (fleet_vehicles.length === 0) return;

    const car_makes = fleet_vehicles.map((v) => v.make);
    const make_opts = uniqueValues(car_makes).map((v) => ({ value: v, label: v }));
    setVehicleMakeOptions(make_opts);
  }, [fleet_vehicles]);

  useEffect(() => {
    const { vehicle_make } = bi;
    setSelectedMake(vehicle_make);
  }, []);

  if (!vehicle_inspection) return <Spin />;

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <AntdFormField
            type="number"
            name="vehicle_year"
            label="Vehicle Year"
            min={1950}
            max={new Date().getFullYear() + 2}
            defaults={bi}
            onChange={handleChange}
            read_only={read_only}
          />
        </Col>
        <Col span={8}>
          <AntdFormField
            type="select"
            name="vehicle_make"
            label="Vehicle Make"
            defaults={bi}
            options={vehicle_make_options}
            onChange={handleMakeChange}
            read_only={read_only}
          />
        </Col>
        <Col span={8}>
          <AntdFormField
            type="select"
            name="fleet_vehicle_id"
            label="Vehicle Model"
            defaults={bi}
            placeholder={selected_make
              ? 'Start Typing to Search...'
              : 'Select a Vehicle Make for model options'}
            options={vehicle_model_options}
            onChange={handleChange}
            read_only={!selected_make || read_only}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <AntdFormField
            type="datepicker"
            name="inspection_date"
            label="Inspection Date"
            defaults={bi}
            min_date={moment().subtract(5, 'days')}
            max_date={moment()}
            onChange={handleDateChange}
            read_only={read_only}
          />
        </Col>
        <Col span={8}>
          <AntdFormField
            type="datepicker"
            name="vehicle_registration_date"
            label="Vehicle Registration Date"
            defaults={bi}
            onChange={handleDateChange}
            read_only={read_only}
          />
        </Col>
        <Col span={8}>
          <AntdFormField
            type="datepicker"
            name="vehicle_dot_inspection_date"
            label="Vehicle DOT Inspection Date"
            defaults={bi}
            onChange={handleDateChange}
            read_only={read_only}
          />
        </Col>
      </Row>
    </>
  );
};

export default BasicInformation;
