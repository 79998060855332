import moment from 'moment';

const setSearchParams = (form, param) => {
  const initialFormValue = {};
  const [name, value] = Object.entries(param)[0];

  const dateValue = name.includes('date');
  if (dateValue) {
    initialFormValue[name] = moment(value[0]);
    form.setFieldsValue(initialFormValue);

    return;
  }

  initialFormValue[name] = value.map((v) => Number(v));
  return form.setFieldsValue(initialFormValue);
};

export default setSearchParams;
