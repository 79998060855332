import React from 'react';
import { withRouter } from 'react-router';
import { PageHeader } from 'antd';

const NotFound = ({ history }) => (
  <div>
    <PageHeader
      title="Page Not Found"
      onBack={() => history.goBack()}
    />
  </div>
);

export default withRouter(NotFound);
