import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';
import { WebAuth } from 'auth0-js';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid/v4';
import { propOr } from 'ramda';
import authConfig from './config';
import getApiHealth from '../../../store/actions/api-health-actions';
import { updateUserMechanic, getUserSettings } from '../../../db/api';
import { UserSettingsContext } from '../../../components/UserSettings/UserSettingsProvider';
import { GET_API_HEALTH_RESOLVED } from '../../../store/actionTypes';

export const AuthContext = createContext();

const { localStorage } = window;

const AuthProvider = ({ history, children, redirectUri }) => {
  const webAuth = new WebAuth(authConfig);
  const { updateUserSettings } = useContext(UserSettingsContext);
  const [user, setUser] = useState({});
  const [accessTokenState, setAccessToken] = useState(null);
  const [expiresAt, setExpiresAt] = useState(null);
  const { isOnline } = useSelector((store) => store.apiHealth);
  const dispatch = useDispatch();

  window.addEventListener('online', () => dispatch({ type: GET_API_HEALTH_RESOLVED, isOnline: true }));
  window.addEventListener('offline', () => dispatch({ type: GET_API_HEALTH_RESOLVED, isOnline: false }));

  const checkActiveSession = async () => {
    const token = localStorage.getItem('access_token');
    const userProfile = JSON.parse(localStorage.getItem('user_profile'));
    const expireTime = localStorage.getItem('token_expiration');
    if (token && userProfile) {
      setAccessToken(token);
      setUser(userProfile);
      setExpiresAt(expireTime);
    }
  };

  const clearSession = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_profile');
    localStorage.removeItem('token_expiration');

    setExpiresAt(null);
    setAccessToken(null);
    setUser({});

    history.replace('/login');
  };

  useEffect(() => {
    dispatch(getApiHealth());
    checkActiveSession();
  }, []);

  useEffect(() => {
    if (!expiresAt) return;
    const interval = setInterval(() => {
      if (expiresAt < new Date().getTime() && isOnline) {
        clearSession();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [expiresAt, isOnline]);

  const setUserProfile = async (accessToken) => new Promise((resolve, reject) => {
    webAuth.client.userInfo(accessToken, async (err, userProfile) => {
      if (err) {
        console.error(err);
        reject(err);
      } else if (userProfile) {
        const mechanic = userProfile[process.env.REACT_APP_MECHANIC_INFO_API];
        updateUserMechanic(mechanic);

        const existing_settings = await getUserSettings() ?? {};
        const settings = {
          localId: uuid(),
          truck_id: propOr(null, 'truck_id', mechanic),
          displayTimeAdjustments: false,
          ...existing_settings,
        };
        updateUserSettings(settings);

        localStorage.setItem('user_profile', JSON.stringify(userProfile));
        setUser(userProfile);
      }
      resolve();
    });
  });

  const setSession = async ({ accessToken, expiresIn }) => {
    const expireTime = expiresIn * 1000 + new Date().getTime();

    await setUserProfile(accessToken);
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('token_expiration', expireTime);

    setAccessToken(accessToken);
    setExpiresAt(expireTime);
  };

  const handleAuthCallback = () => {
    webAuth.parseHash(async (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        await setSession(authResult);
        history.replace(redirectUri);
      } else if (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        history.replace('/unauthorized');
      }
    });
  };

  const login = () => webAuth.authorize();

  const logout = () => clearSession();

  const tokenExpiration = parseInt(localStorage.getItem('token_expiration'), 10);
  const isLoggedIn = tokenExpiration && (new Date().getTime()) <= tokenExpiration;

  const contextValue = {
    handleAuthCallback,
    accessToken: accessTokenState,
    isLoggedIn,
    user,
    login,
    logout,
    is_online: isOnline,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const Callback = () => {
  const { handleAuthCallback } = useContext(AuthContext);
  useEffect(() => {
    handleAuthCallback();
  }, []);
  return null;
};

export const Login = ({ history }) => {
  const { login } = useContext(AuthContext);
  const user = localStorage.getItem('user_profile');
  if (user) history.replace('/dashboard');
  else login();

  return null;
};

export default withRouter(AuthProvider);
