import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Text } = Typography;

const PageHeader = ({ title, subtext, extra }) => (
  <div style={{ marginTop: '15px' }}>
    <Row align='middle'>
      <Col span={12}>
        <h2 className="mb-0">
          {' '}
          {title}
          {' '}
        </h2>
      </Col>
      {
        extra && (
          <Col span={12}>
            {extra}
          </Col>
        )
      }
    </Row>
    <Row>
      <Col span={12}>
        <p>{subtext}</p>
      </Col>
    </Row>
    <hr />
  </div>
);

export default PageHeader;
