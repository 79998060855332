import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Col } from 'antd';
import * as R from 'ramda';
import {
  FolderFilled,
  ClockCircleFilled,
  CarFilled,
  ToolFilled,
  SwapOutlined,
  BorderlessTableOutlined,
} from '@ant-design/icons';
import { notificationTypes } from '../../config/constants';
import { AuthContext } from '../../hoc/auth/Provider';
import { syncConsumer } from '../../api/resources';
import {
  WeekScoreboard,
  Panel,
  Button,
  Scoreboard,
  StyledModal,
  DashboardTable,
  Select,
} from '../shared';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import {
  getUpcomingPMs,
  getOverduePMs,
  getTotalUnits,
  getMechanics,
  getCurrentWorkOrder,
  getWarehouseById,
  addSiteNotification,
} from '../../db/api';
import { getWeeklyStats } from '../../db/stats';

import { useCallTypes, useWorkOrderHelpers } from '../hooks';

// Utils
import { makeOptions } from '../../lib/utils';

const Container = styled.div`
  margin-top: 15px;
`;

const getWelcomeMessage = (name) => {
  let partOfDay = 'Morning';
  const hour = (new Date()).getHours();
  if (hour >= 12) partOfDay = 'Afternoon';
  if (hour >= 18) partOfDay = 'Evening';
  return `Good ${partOfDay}${name ? `, ${name}` : ''}`;
};

const Dashboard = ({ history, syncing, setNewWOStep }) => {
  const { user } = useContext(AuthContext);
  const {
    user_settings, user_mechanic, updateUserSettings, user_settings_loading,
  } = useContext(UserSettingsContext);

  const { openWorkOrder } = useWorkOrderHelpers({ history, setNewWOStep });
  const { getCallTypeIdByName } = useCallTypes();

  const [loading, setLoading] = useState(true);
  const [weeks_work_orders, setWeeksWorkOrders] = useState(0);
  const [weeks_minutes, setWeeksMinutes] = useState(0);
  const [weeks_miles, setWeeksMiles] = useState(0);
  const [weeks_parts, setWeeksParts] = useState(0);
  const [weeks_transfers, setWeeksTransfers] = useState(0);
  const [overdue_pm_count, setOverduePMCount] = useState(0);
  const [, setOverduePMs] = useState([]);
  const [all_pms_list, setAllPMLists] = useState([]);
  const [upcoming_pm_count, setUpcomingPMCount] = useState(0);
  const [, setUpcomingPMs] = useState([]);
  const [show_truck_modal, setShowTruckModal] = useState(false);
  const [truck_options, setTruckOptions] = useState([]);
  const [truck, setTruck] = useState();
  const [current_work_order, setCurrentWorkOrder] = useState(null);

  const start_of_week = moment().startOf('week');
  const end_of_week = moment().endOf('week');

  const cols = [
    {
      title: 'Unit Number',
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'display_name'],
      key: ['customer', 'display_name'],
      sorter: (a, b) => a.customer.display_name.localeCompare(b.customer.display_name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => a.state.localeCompare(b.state),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'County',
      dataIndex: 'county',
      key: 'county',
      sorter: (a, b) => a.county.localeCompare(b.county),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Last PM Date',
      dataIndex: 'last_pm_date',
      key: 'last_pm_date',
      sorter: (a, b) => moment(a.last_pm_date).isAfter(moment(b.last_pm_date)),
      sortDirections: ['descend', 'ascend'],
      render: (val) => <span>{moment(val).format('MM/DD/YYYY')}</span>,
    },
    {
      title: 'Next PM Date',
      dataIndex: 'next_pm_date',
      key: 'next_pm_date',
      sorter: (a, b) => moment(a.next_pm_date).isAfter(moment(b.next_pm_date)),
      sortDirections: ['descend', 'ascend'],
      render: (val) => <span>{moment(val).format('MM/DD/YYYY')}</span>,
    },
  ];

  const updateSettings = () => {
    const settings = {
      ...user_settings,
      truck_id: truck.truck_id,
      truck_label: truck.truck_label,
      truck_number: truck.truck_number,
      displayTimeAdjustments: false,
    };
    updateUserSettings(settings);
    setShowTruckModal(false);
  };

  const setTruckData = async (value, option) => {
    const warehouse = await getWarehouseById(value);
    setTruck({
      truck_id: value,
      truck_label: option.label,
      truck_number: R.propOr(null, 'description', warehouse),
    });
  };

  const setWeekData = (week_data) => {
    setWeeksWorkOrders(week_data.total_work_orders);
    setWeeksMinutes(week_data.total_labor_minutes);
    setWeeksMiles(week_data.total_miles);
    setWeeksParts(week_data.total_parts);
    setWeeksTransfers(week_data.total_transfers);
  };

  useEffect(() => {
    if (user_settings_loading || !user_settings || truck_options.length === 0) return;

    setShowTruckModal(!R.prop('truck_id', user_settings));
  }, [user_settings, syncing, truck_options]);

  useEffect(() => {
    if (Object.keys(user).length > 0 && !syncing) {
      const mechanic_id = user_mechanic.id;
      setAllPMLists([]);
      // get and set the PM and Mechanic data and Week Data
      Promise.all([
        getOverduePMs(mechanic_id),
        getUpcomingPMs(mechanic_id),
        getTotalUnits(mechanic_id),
        getMechanics(),
        getWeeklyStats(start_of_week.format(), end_of_week.format()),
        getCurrentWorkOrder(),
      ]).then(([resOverduePm, resUpcomingPM, resTotalUnits, resMechanicsList, resWeeklyStats, resCurrentWO]) => {
        const current_wo_res = resCurrentWO;

        setOverduePMCount(resOverduePm.length);
        setOverduePMs(resUpcomingPM);
        setUpcomingPMCount(resUpcomingPM.length);
        setUpcomingPMs(resUpcomingPM);
        setAllPMLists(resTotalUnits);
        setTruckOptions(R.map(makeOptions(['first_name', 'last_name', 'truck_number'], 'truck_id'), resMechanicsList));
        setWeekData(resWeeklyStats);
        setCurrentWorkOrder(current_wo_res.wo);
        setLoading(false);

        if (user_mechanic && (!user_mechanic.truck_number || !user_mechanic.mechanic_number)) {
          const notification = {
            message: 'Your mechanic record is incomplete. Get with helpdesk or your manager to resolve the issue.',
            type: notificationTypes.ERROR,
            key: 'incomplete_mechanic_record',
          };
          addSiteNotification(notification);
        }
      });
    }
  }, [user, syncing]);

  return loading ? (<></>) : (
    <Container>
      <StyledModal
        open={show_truck_modal && !syncing}
        title="Please Select Truck Number"
        okText="Set Truck Number"
        cancelButton={false}
        okButtonProps={{ disabled: !R.prop('truck_id', truck) }}
        onOk={updateSettings}
        hideModal={() => setShowTruckModal(false)}
        body={(
          <Select
            options={truck_options}
            defaultValue={R.prop('truck_id', user_settings)}
            onChange={setTruckData}
          />
        )}
      />
      <Row gutter={16}>
        <Col span={12}>
          <h2>
            {getWelcomeMessage(user ? user.name : null)}
          </h2>
        </Col>
        <Col span={12}>
          { current_work_order ? (
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={async () => {
                openWorkOrder();
              }}
            >
              Continue Work Order
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={() => { openWorkOrder(); }}
            >
              New Work Order
            </Button>
          )}
        </Col>
      </Row>
      <hr />
      <Row gutter={16}>
        <Col span={6}>
          <Panel>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: '20px', marginBottom: '10px' }}><strong>This Week</strong></div>
              <div style={{ marginBottom: '10px' }}>
                {`${start_of_week.format('MMM. Do, YYYY')}
                - ${end_of_week.format('MMM. Do, YYYY')}`}
              </div>
            </div>
            <WeekScoreboard
              title="Current Truck"
              value={R.prop('truck_number', user_settings)}
              icon={BorderlessTableOutlined}
            />
            <WeekScoreboard
              title="Work Orders"
              value={weeks_work_orders}
              icon={FolderFilled}
            />
            <WeekScoreboard
              title="Hours Worked"
              type="hours"
              value={weeks_minutes}
              icon={ClockCircleFilled}
            />
            <WeekScoreboard
              title="Total Miles"
              value={weeks_miles}
              icon={CarFilled}
            />
            <WeekScoreboard
              title="Parts Used"
              value={weeks_parts}
              icon={ToolFilled}
            />
            <WeekScoreboard
              title="Parts Transferred"
              value={weeks_transfers}
              icon={SwapOutlined}
            />
          </Panel>
          <Scoreboard label="Upcoming PMs" value={upcoming_pm_count} />
          <Scoreboard label="Overdue PMs" value={overdue_pm_count} />
        </Col>
        <Col span={18}>
          <Row gutter={16}>
            <Col span={24}>
              <DashboardTable
                zebra
                label="Preventative Maintenance Report"
                scroll={{ scrollToFirstRowOnChange: true, x: true }}
                row_key="id"
                data_source={all_pms_list}
                cols={cols}
                row_click={(record) => ({
                  onClick: () => { openWorkOrder(record, [getCallTypeIdByName('Preventative Maintenance')]); },
                })}
                page_size={10}
                table_key={[
                  'complete',
                  'shut in',
                  'overdue',
                  'upcoming',
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default syncConsumer(Dashboard);
