import { GET_CALL_TYPES, GET_CALL_TYPES_SUCCESS, GET_CALL_TYPES_FAILED } from '../actionTypes/index';
import {
  getEnabledCallTypes,
} from '../../db/api';

const getCallTypes = () => async (dispatch) => {
  dispatch({ type: GET_CALL_TYPES });
  try {
    const response = await getEnabledCallTypes();

    dispatch({ type: GET_CALL_TYPES_SUCCESS, response });
  } catch (error) {
    dispatch({ type: GET_CALL_TYPES_FAILED });
  }
};

export default getCallTypes;
