import React, { useEffect, useState } from 'react';
import { Divider, Form } from 'antd';
import { pathOr } from 'ramda';
import { controlsPractices } from '../../config/constants';
import { useCheckBoxWithOther } from '../hooks';
import { CheckboxGroupWithOther } from '../shared/CheckboxGroupWithOther';

const Controls = (props) => {
  const {
    jsa,
    updateJSAValue,
    form,
    read_only,
  } = props;

  const [selected_options, setSelectedOptions] = useState(pathOr({}, ['controls_checklists'], jsa));
  const [section_error, setSectionError] = useState(false);

  const checkbox_options = controlsPractices.map((cp) => (
    { label: cp.value.toUpperCase(), value: cp.value }
  ));

  const update = async (res) => {
    const { data } = res;

    setSelectedOptions(data);
    await updateJSAValue(jsa.localId, ['controls_checklists'], data);
  };

  const validateChecked = async () => {
    setSectionError(false);
    const selected = Object.keys(selected_options);

    if (selected.includes('other') && selected_options.other_description.length === 0) {
      setSectionError(true);
      throw new Error('You must provide a description for Control: "Other"');
    }

    if (selected.length < 1) {
      setSectionError(true);
      throw new Error('You must select at least one Control or Practice');
    }
  };

  useEffect(() => {
    form.validateFields();
  }, [selected_options]);

  const { handleChange, handleOtherDescriptionChange } = useCheckBoxWithOther({
    initial_values: selected_options,
    update,
  });

  const checkbox_props = {
    group: {
      defaultValue: Object.keys(selected_options),
      options: checkbox_options,
      onChange: handleChange,
    },
    input: {
      defaultValue: pathOr('', ['other_description'], selected_options),
      disabled: read_only || !Object.keys(selected_options).includes('other'),
      onChange: handleOtherDescriptionChange,
    },
    read_only,
    showOther: Object.keys(selected_options).includes('other'),
  };

  return (
    <>
      {checkbox_options
        && (
          <section className={`section-validation section-validation--border${section_error ? '__error' : ''}`}>
            <Divider>
              Controls and Safe Work Practices to ensure we work safe. (check all that apply)
            </Divider>
            <Form.Item
              name={[
                { errors: 'controls' },
                { name: 'Controls and Practices' },
              ]}
              rules={[{ validator: validateChecked }]}
            >
              <CheckboxGroupWithOther {...checkbox_props} />
            </Form.Item>
          </section>
        )}
    </>
  );
};

export default Controls;
