/* eslint-disable no-use-before-define */
/* *******
 * eslint-disable was put in 6/24/2022 -> At this time we are using a third party
 * for vehicle inspection capabilites. This form requires some updates to be eslint compliant
 * however since this isn't an active file I don't want to address them until it is.
 * -Mark
 * ****** */
import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import {
  PageHeader, notification, Form, Button, message,
  Spin, Tabs, Anchor, Typography, Collapse, Alert, Affix,
} from 'antd';
import { pathOr } from 'ramda';
import moment from 'moment';
import 'moment-timezone';
import {
  CaretRightOutlined,
  CheckCircleFilled,
  DeleteFilled,
} from '@ant-design/icons';
import {
  getCurrentVehicleInspection,
  createVehicleInspection,
  getVehicleInspection,
  getVehicleInspections,
  updateVehicleInspection,
  updateVehicleInspectionValue,
  deleteVehicleInspection,
  getUserMechanicRecord,
  addNotification,
} from '../../db/api';
import { getVehicleInspection as getApiVehicleInspection } from '../../api/vehicle_inspection';
import { useVehicleInspectionTabs, useResources, useWorkOrderHelpers } from '../hooks';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { AuthContext } from '../../hoc/auth/Provider';
import { workerConsumer } from '../../api/workers/WorkerProvider';
import {
  convertDateToCentral,
  convertObjVals,
  formatApiVehicleInspection,
} from '../../lib/utils';
import { hasCompletedInspectionToday } from '../../db/vehicle_inspections';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { confirm, scrollTo } = window;

const VehicleInspection = (props) => {
  const {
    history,
    worker,
    match: { params: { viid } },
    read_only,
  } = props;

  const { openWorkOrder } = useWorkOrderHelpers({ history });

  const [form] = Form.useForm();
  const { user_settings, user_settings_loading } = useContext(UserSettingsContext);
  const { is_online } = useContext(AuthContext);

  // all tabs have to be active by default for them to render and validate on pageload
  const [active_tabs, setActiveTabs] = useState(['0', '1', '2', '3', '4']);
  const [user_mechanic, setUserMechanic] = useState(null);
  const [vehicle_inspection, setVehicleInspection] = useState(null);
  const [error_list, setErrorList] = useState([]);

  const { resources, resource_loading } = useResources([
    'mechanics',
    'trucks',
    'vehicle_inspection_fields',
    'fleet_vehicles',
  ]);
  const { vehicle_inspection_fields, fleet_vehicles } = resources;

  const inspection_id = pathOr(null, ['id'], vehicle_inspection);
  const title = pathOr('Vehicle Inspection', ['flogistix_id'], vehicle_inspection);
  const timezone = pathOr('America/Chicago', ['timezone'], user_settings);
  const token = localStorage.getItem('access_token');

  const updateInspection = async (path, val) => (
    updateVehicleInspectionValue(inspection_id, path, val)
  );

  const setActive = (v) => {
    if (!active_tabs.includes(v)) setActiveTabs([...active_tabs, v]);
  };

  // null is N/A in the PG database,
  //   but we can't store nulls in Dexie or else the field will appear empty
  //   so I used -1 as keys, need to convert them back to null before submitting
  const formatInspection = (inspection) => {
    const checklist = {
      ...convertObjVals(inspection.interior_checklist, -1, null),
      ...convertObjVals(inspection.exterior_checklist, -1, null),
    };

    // convert field key back to ID for postgres intersection records
    const inspection_checklist = vehicle_inspection_fields.map((field) => (
      {
        field_id: field.id,
        value: checklist[field.key],
      }
    ));

    // add a vehicle label to match API for recentVehicleInspections
    const year = form.getFieldValue('vehicle_year');
    const vehicle_id = form.getFieldValue('fleet_vehicle_id');
    const { make, model } = fleet_vehicles.find((v) => v.id === vehicle_id);
    const vehicle = [year, make, model].join(' ');

    return {
      ...inspection,
      inspection_checklist,
      vehicle,
    };
  };

  const onError = () => {
    const error_fields = form.getFieldsError();
    const filtered_errors = error_fields.reduce((acc, field) => {
      if (field.errors.length > 0) {
        acc.push(
          <li
            key={field.name[0]}
            onClick={() => form.scrollToField(field.name[0])}
          >
            {field.errors[0]}
          </li>,
        );
      }
      return acc;
    }, []);

    scrollTo(0, 0);
    setErrorList(filtered_errors);

    // changing the dom (error_list) re-renders everything
    // have to re-run the validation so that "field_name is required" shows up
    form.validateFields();
  };

  const onSubmit = async () => {
    notification.destroy();
    window.scrollTo(0, 0);

    const msg = 'Would you like to submit this vehicle inspection? You can not make changes after it is submitted!';
    if (!confirm(msg)) return;

    const inspection = await getVehicleInspection(inspection_id);
    const { truck_id } = user_settings;
    const { flogistix_id } = inspection;

    const completed_inspection = {
      ...formatInspection(inspection),
      complete: true,
      synced: false,
      date_submitted: convertDateToCentral(moment(), timezone),
      truck_id,
      mechanic_id: user_mechanic.id,
    };

    await updateVehicleInspection(completed_inspection);

    worker.postMessage({
      type: 'vehicle_inspection_upload',
      endpoint: '/next/vehicleinspections',
      token,
      flogistix_id,
      entity_id: inspection_id,
      upload_data: { vehicle_inspection: completed_inspection },
      resource_name: 'vehicle_inspections',
    });

    addNotification(`${flogistix_id} has been submitted and is pending upload`);
    if (!is_online) addNotification('The upload will be attempted once your computer is back online');

    openWorkOrder();
  };

  const onDeleteInspection = async () => {
    const msg = 'Are you sure you want to delete this inspection? \nYou can not undo this action!';
    if (!confirm(msg)) return false;

    deleteVehicleInspection(pathOr(null, ['id'], vehicle_inspection))
      .then(() => history.push('/'))
      .catch((e) => message.error('Vehicle Inspection was not deleted with Error : ', e.message));
  };

  const loadVehicleInspection = async (id) => {
    const existing_inspection = await getVehicleInspection(id);
    if (!existing_inspection) return createAndLoadVehicleInspection();

    setVehicleInspection(existing_inspection);
    history.push(`/vehicleinspection/${existing_inspection.id}`);
  };

  const createAndLoadVehicleInspection = async () => {
    const has_inspection = await hasCompletedInspectionToday(timezone);

    if (has_inspection) {
      const msg = 'You have already completed a vehicle inspection for today.\n\n'
        + 'Are you sure you want to start a new one?';
      if (!confirm(msg)) return history.goBack();
    }
    const new_inspection = await createVehicleInspection(user_settings, user_mechanic);
    await loadVehicleInspection(new_inspection.id);
  };

  useEffect(() => {
    (async function vehicleInspection() {
      if (!read_only) {
        if (!user_mechanic || user_settings_loading || !user_settings) return;

        const cvi = await getCurrentVehicleInspection();
        const vi_id = viid || pathOr(null, ['id'], cvi) || null;

        const fn = vi_id ? loadVehicleInspection : createAndLoadVehicleInspection;
        await fn(vi_id);
      }

      // make sure all of these are true or else the constant re-mounting will spam requests
      if (read_only && is_online && !resource_loading && !user_settings_loading) {
        // try to get local inspection first (may have been uploaded but not sycned down yet)
        const inspections = await getVehicleInspections();
        const local_inspection = inspections.find((i) => i.flogistix_id === viid);
        if (local_inspection) {
          setVehicleInspection(local_inspection);
          return;
        }

        const params = { vehicle_inspection_id: viid };
        const res = await getApiVehicleInspection(params);

        const formatted_inspection = formatApiVehicleInspection(res);
        setVehicleInspection(formatted_inspection);
      }
    }());
  }, [is_online, user_mechanic, resource_loading, user_settings_loading]);

  useEffect(() => {
    (async function mechanicRecord() {
      const mech = await getUserMechanicRecord();
      setUserMechanic(mech);
    }());
  }, []);

  // reset 'complete' on load, have to watch state or else vehicle_inspection will be empty
  useEffect(() => {
    (async function asyncUpdateInspection() {
      if (read_only || !vehicle_inspection) return;

      await updateInspection(['complete'], false);
    }());
  }, [vehicle_inspection]);

  const { tabs } = useVehicleInspectionTabs({
    vehicle_inspection,
    updateInspection,
    form,
    user_mechanic,
    resources,
    resource_loading,
    read_only,
  });

  const tab_panes = tabs.map((tab, i) => (
    <TabPane
      tab={(
        <Anchor.Link
          key={i}
          title={tab.title}
        />
      )}
      key={tab.anchor}
    />
  ));

  const panels = tabs.map((tab, i) => (
    <Panel
      key={i}
      className="site-collapse-custom-panel"
      header={<Typography.Title level={5}><b>{tab.title}</b></Typography.Title>}
    >
      <div id={tab.anchor}>
        <tab.comp {...tab.props} />
      </div>
    </Panel>
  ));

  return vehicle_inspection && tabs ? (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onFinishFailed={onError}
    >
      <Affix offsetTop={64}>
        <div style={{ backgroundColor: 'white', marginBottom: '15px', borderBottom: '1px solid grey' }}>
          <PageHeader
            title={`${title}${read_only ? ' (Read Only)' : ''}`}
            extra={!read_only && (
              <>
                <Button
                  type="primary"
                  icon={<DeleteFilled />}
                  shape="circle"
                  size="large"
                  onClick={onDeleteInspection}
                  danger
                />
                <Button
                  type="primary"
                  icon={<CheckCircleFilled />}
                  shape="circle"
                  size="large"
                  htmlType="submit"
                />
              </>
            )}
          />
          <Anchor className="tabbed-anchor" affix={false} offsetTop={230}>
            <Tabs defaultActiveKey="0" onChange={setActive} centered>{tab_panes}</Tabs>
          </Anchor>
        </div>
      </Affix>
      { error_list.length > 0
        && (
        <Alert
          style={{ marginBottom: '15px' }}
          message="VehicleInspection Error"
          type="error"
          showIcon
          description={<ul>{error_list}</ul>}
        />
        )}
      <Collapse
        style={{
          marginBottom: '24px',
          overflow: 'hidden',
          background: '#f7f7f7',
          border: '0px',
          borderRadius: '2px',
        }}
        bordered
        activeKey={active_tabs}
        defaultActiveKey={active_tabs}
        onChange={setActiveTabs}
        className="site-collapse-custom-collapse"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {panels}
      </Collapse>
      { !read_only && (
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Complete Vehicle Inspection
        </Button>
      )}
    </Form>
  ) : (
    <div style={{
      height: '100%', width: '100%', position: 'fixed', display: 'flex', alignItems: 'center', justifyContent: 'center',
    }}
    >
      <Spin size="large" />
    </div>
  );
};

export default workerConsumer(withRouter(VehicleInspection));
