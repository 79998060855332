import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
  Card, Col, Row,
} from 'antd';
import { pathOr } from 'ramda';
import { AntdFormField } from '../shared';
import {
  pump_service_type_options, pump_pm_checklist_fields, pump_pm_fluid_fields,
  pump_pm_power_fields, pump_pm_schedule_options,
} from '../../config/constants/pump_pm_checklist';
import PumpPMChecklistSection from './PumpPMChecklistSection';
import { yes_no_options } from '../../config/constants';
import { getCurrentWorkOrder } from '../../db/api';

const PumpPMChecklist = (props) => {
  const {
    form,
    work_order,
    updateWorkOrderValue,
    calltype_notetypes,
    pumps,
    read_only,
  } = props;

  const pump_pm_checklist = pathOr({}, ['pump_pm_checklist'], work_order);
  const pump_pm_meta = pathOr({}, ['pump_work_order_meta'], work_order);

  const [pump_note_types, setPumpNoteTypes] = useState([]);
  const [note_mapping, setNoteMapping] = useState({});

  const handleChange = async (field, val) => {
    await updateWorkOrderValue(work_order.localId, ['pump_pm_checklist', field], val);
  };

  const handleMetaChange = async (field, val) => {
    await updateWorkOrderValue(work_order.localId, ['pump_work_order_meta', field], val);
  };

  // these notes are actually supposed to go into work_order.work_order_notes, but
  // I'm keeping track of them separately to avoid overwriting wo notes with stale data
  // or overfetching db as this is run onChange which is every keypress
  // also helps so I only have to pass one defaults to PumpPMChecklistSection
  const handleNoteChange = async () => {
    // retrieve note fields from the form by name
    const note_field_names = pump_note_types.map((nt) => nt.note_type);
    const note_fields = form.getFieldsValue(note_field_names);

    // map the form state to what the db is expecting (ignore nulls so rows aren't created)
    const new_notes = Object.keys(note_fields)
      .filter((key) => note_fields[key])
      .map((key) => (
        {
          note_type_id: note_mapping[key],
          text: note_fields[key],
        }
      ));

    await updateWorkOrderValue(work_order.localId, ['pump_pm_checklist', 'notes'], new_notes);

    // keep track in its own object separately to make textarea population cleaner
    await updateWorkOrderValue(work_order.localId, ['pump_pm_checklist', 'note_defaults'], note_fields);
  };

  const onDateChange = async (field, val) => {
    await handleChange(field, val.format('MM-DD-YYYY'));
  };

  const onServiceTypeChange = async (service_selections) => {
    const service_fields = pump_service_type_options.map((opt) => opt.value);
    const service_info = service_fields.reduce((acc, field) => {
      acc[field] = service_selections.includes(field);
      return acc;
    }, {});

    // fetch up to date wo to avoid updating pump_work_order_meta object with stale data
    const wo = await getCurrentWorkOrder();
    const meta_update = {
      ...wo.wo.pump_work_order_meta,
      ...service_info,
    };

    await updateWorkOrderValue(work_order.localId, ['pump_work_order_meta'], meta_update);

    // keep track in its own object separately to make checkbox population cleaner
    await handleMetaChange('service_types', service_selections);
  };

  const pump_model_options = pumps.map((p) => (
    { value: p.id, label: `${p.model} (${p.manufacturer})` }
  ));

  useEffect(() => {
    // make a list of pump_pm note_types for form field naming
    // using this instead of call_type_id because there are a few notes that are separate in the Notes section
    const pump_fields = [
      ...pump_pm_checklist_fields,
      ...pump_pm_power_fields,
      ...pump_pm_fluid_fields,
    ];
    const pump_note_fields = pump_fields.map((ppnf) => ppnf.note_type_id);
    const note_types = calltype_notetypes.filter((ctnt) => pump_note_fields.includes(ctnt.note_type_id));

    // store an accessible mapping to avoid nested looping to match note_type_id in onChange()
    const note_type_mapping = note_types.reduce((acc, field) => {
      acc[field.note_type] = field.note_type_id;
      return acc;
    }, {});

    setNoteMapping(note_type_mapping);
    setPumpNoteTypes(note_types);
  }, [calltype_notetypes]);

  // changing the dom (re-rendering from notetype change) re-renders everything
  // have to re-run the validation so that "field_name is required" shows up
  useEffect(() => {
    form.validateFields();
  }, [calltype_notetypes]);

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card title="Pump PM Basic Information">
            <Row gutter={[12, 12]}>
              <Col span={4}>
                <AntdFormField
                  type="columncheckboxgroup"
                  name="service_types"
                  label="Service Type"
                  onChange={onServiceTypeChange}
                  options={pump_service_type_options}
                  defaults={pump_pm_meta}
                  read_only={read_only}
                />
              </Col>
              <Col span={5}>
                <AntdFormField
                  type="select"
                  name="pump_model_id"
                  label="Pump Model"
                  onChange={handleMetaChange}
                  options={pump_model_options}
                  defaults={pump_pm_meta}
                  pump_note_types={pump_note_types}
                  read_only={read_only}
                />
              </Col>
              <Col span={5}>
                <AntdFormField
                  name="sold_by"
                  label="Sold By"
                  onChange={handleMetaChange}
                  defaults={pump_pm_meta}
                  pump_note_types={pump_note_types}
                  read_only={read_only}
                />
              </Col>
              <Col span={5}>
                <AntdFormField
                  type="datepicker"
                  name="last_pm_date"
                  label="Last PM Date"
                  onChange={onDateChange}
                  defaults={pump_pm_checklist}
                  pump_note_types={pump_note_types}
                  read_only={read_only}
                />
              </Col>
              <Col span={5}>
                <AntdFormField
                  type="radiogroup"
                  name="schedule"
                  label="PM Schedule"
                  onChange={handleChange}
                  options={pump_pm_schedule_options}
                  defaults={pump_pm_checklist}
                  pump_note_types={pump_note_types}
                  read_only={read_only}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card title="Pump PM Basic Checklist">
            <Row gutter={[12, 12]}>
              { pump_pm_checklist_fields.map((field) => (
                <Col span={6} key={`${field.name}_col`}>
                  <PumpPMChecklistSection
                    {...field}
                    onFieldChange={handleChange}
                    onNotesChange={handleNoteChange}
                    defaults={pump_pm_checklist}
                    options={yes_no_options}
                    pump_note_types={pump_note_types}
                    read_only={read_only}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card title="Fluid End Expendables">
            <Row gutter={[12, 12]}>
              { pump_pm_fluid_fields.map((field) => (
                <Col span={6} key={`${field.name}_col`}>
                  <PumpPMChecklistSection
                    {...field}
                    onFieldChange={handleChange}
                    onNotesChange={handleNoteChange}
                    defaults={pump_pm_checklist}
                    pump_note_types={pump_note_types}
                    read_only={read_only}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card title="Power End">
            <Row gutter={[12, 12]}>
              { pump_pm_power_fields.map((field) => (
                <Col span={6} key={`${field.name}_col`}>
                  <PumpPMChecklistSection
                    {...field}
                    onFieldChange={handleChange}
                    onNotesChange={handleNoteChange}
                    defaults={pump_pm_checklist}
                    pump_note_types={pump_note_types}
                    read_only={read_only}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(PumpPMChecklist);
