import React, { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import {
  PageHeader,
  Form,
  Anchor,
  Button,
  Collapse,
  Spin,
  Tabs,
  Typography,
  Alert,
  Affix,
  message,
} from 'antd';
import {
  CaretRightOutlined,
  DeleteFilled,
  FormOutlined,
  CheckCircleFilled,
  ZoomInOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import 'moment-timezone';
import { workerConsumer } from '../../api/workers/WorkerProvider';
import {
  getWorkOrder,
  updateWorkOrderValue as uwv,
  updateWorkOrder,
  getJsaByWorkOrderId,
  deleteCurrentWorkOrder,
  deleteWorkOrder,
  getWorkOrders,
  addNotification,
} from '../../db/api';
import { getWorkOrder as getApiWorkOrder } from '../../api/work-order';
import { useResources, useWorkOrderTabs, useCallTypes } from '../hooks';
import WorkOrderTimer from '../shared/work-order-timer';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { AuthContext } from '../../hoc/auth/Provider';
import {
  convertDateToCentral, convertObjVals, formatApiWorkOrder, formatLocalWorkOrder,
} from '../../lib/utils';

const token = localStorage.getItem('access_token');
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { confirm, scrollTo } = window;

const WorkOrder = (props) => {
  const {
    history,
    match: { params: { wid } },
    worker,
    read_only,
    editable,
  } = props;

  const { resources, resource_loading } = useResources([
    'units',
    'customers',
    'customer_locations',
    'laborcodes',
    'calltype_laborcodes',
    'warehouses',
    'mechanics',
    'pmtemplates',
    'calltype_notetypes',
    'reasons',
    'parts',
    'pumps',
  ]);

  const {
    units, customers, customer_locations,
  } = resources;

  const [form] = Form.useForm();
  const { user_settings, user_mechanic, user_settings_loading } = useContext(UserSettingsContext);
  const { is_online } = useContext(AuthContext);

  const [work_order, setWorkOrder] = useState();
  const [jsa, setJSA] = useState(null);
  const [active_tabs, setActiveTabs] = useState(['0', '1', '2', '3', '4', '5', '6', '7', '8']);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [work_order_call_types, setWorkOrderCallTypes] = useState([]);
  const [workOrderCallTypesByName, setWorkOrderCallTypesNames] = useState([]);
  const [selected_labor_codes, setSelectedLaborCodes] = useState([]);
  const [selected_unit, setWorkOrderUnit] = useState([]);
  const [error_list, setErrorList] = useState([]);

  const timezone = user_settings?.timezone ?? 'America/Chicago';
  const title = work_order?.basic_information?.flogistix_id ?? 'Work Order';
  const jsa_id = work_order?.jsa_flogistix_id;

  const allCallTypes = useCallTypes();

  const updateWorkOrderValue = async (wo_id, path, value) => {
    await uwv(wo_id, path, value);
  };

  const setActive = (v) => {
    if (!active_tabs.includes(v)) setActiveTabs([...active_tabs, v]);
  };

  // pump pm notes were tracked separately, put them where they belong before submission
  const combineNotes = (wo) => (
    [
      ...wo?.work_order_notes ?? [],
      ...wo?.pump_pm_checklist?.notes ?? [],
    ]
  );

  // TODO: customer_name, lease_name to be implemented for CO units
  const addBasicInfo = (wo) => {
    const { truck_id } = user_settings;
    const { basic_information } = wo;
    let additional_data = {
      truck_id,
    };

    if (!workOrderCallTypesByName.includes('Indirect')) {
      const { unit_id, customer_id, customer_location_id } = basic_information;

      const { id: asset_id, number: asset_number } = units.find((u) => u.id === unit_id);

      const { display_name: legacy_customer } = customer_id
        ? customers.find((c) => c.id === customer_id)
        : { display_name: basic_information?.customer_name };

      const { address_label: lease } = customer_location_id
        ? customer_locations.find((cl) => cl.id === customer_location_id)
        : { address_label: basic_information?.lease_name };

      additional_data = {
        ...additional_data,
        asset_number,
        asset_id,
        legacy_customer,
        lease,
        location_of_work: lease,
      };
    }

    return {
      ...basic_information,
      ...additional_data,
    };
  };

  const formatWorkOrder = (wo) => (
    {
      ...wo,
      basic_information: addBasicInfo(wo),
      work_order_notes: combineNotes(wo),
      newset_checklist: convertObjVals(wo.newset_checklist, 'n_a', null),
    }
  );

  const formatJsa = (jsaRecord) => {
    const uncheckedRecord = jsaRecord;
    if (Object.hasOwn(uncheckedRecord.controls_checklists, 'other_description') && !Object.hasOwn(uncheckedRecord.controls_checklists, 'others')) delete uncheckedRecord.controls_checklists.other_description;
    if (Object.hasOwn(uncheckedRecord.hazards_checklists, 'other_description') && !Object.hasOwn(uncheckedRecord.hazards_checklists, 'others')) delete uncheckedRecord.hazards_checklists.other_description;
    return uncheckedRecord;
  };

  const onError = () => {
    const error_fields = form.getFieldsError();
    const filtered_errors = error_fields.reduce((acc, field) => {
      if (field.errors.length > 0) {
        const errorDesc = field.errors[0];
        const fieldName = field.name[0]?.errors ?? field.name[0];

        const checkboxErrors = (
          <div htmlFor={fieldName} form={fieldName} className="underline">
            {errorDesc}
          </div>
        );
        const generalErrors = (
          <label htmlFor={fieldName} form={fieldName} className="underline">
            {errorDesc}
          </label>
        );

        const errorListElement = (
          <li
            key={fieldName}
            onClick={() => form.scrollToField(fieldName, {
              behavior: 'smooth', scrollMode: 'if-needed', block: 'center', scrollTop: '295px',
            })}
          >
            {((fieldName.includes('check') ? (checkboxErrors) : (generalErrors)))}
          </li>
        );

        acc.push(
          errorListElement,
        );
      }
      return acc;
    }, []);

    scrollTo(0, 0);
    setErrorList(filtered_errors);

    // changing the dom (error_list) re-renders everything
    // have to re-run the validation so that "field_name is required" shows up
    if (!workOrderCallTypesByName.includes('Indirect')) {
      form.validateFields();
    }
  };

  const resetErrorAlert = () => {
    setErrorList([]);
  };

  const onSubmit = async () => {
    setErrorList([]);

    const { total_mileage } = form.getFieldsValue();

    if (total_mileage > 74) {
      const msg = 'Your total mileage is greater than 75 miles.'
        + '\n\nPress \'Ok\' to confirm and continue or \'Cancel\' to go back and edit your mileage';
      if (!confirm(msg)) return false;
    }

    if (confirm(
      'Would you like to submit this work order? You can not make changes after it is submitted!',
    )) {
      Promise.all([getWorkOrder(wid), getJsaByWorkOrderId(wid)]).then((res) => {
        const [_work_order, _jsa] = res;
        const formatted_jsa = formatJsa(_jsa);
        const formatted_wo = formatWorkOrder(_work_order);
        const { basic_information: bi } = formatted_wo;
        const { id, first_name, last_name } = user_mechanic;
        if (bi.mechanic_id === undefined) {
          formatted_wo.basic_information.mechanic_id = id;
          formatted_wo.basic_information.mechanic_name = `${first_name} ${last_name}`;
        }
        const { flogistix_id } = formatted_wo;

        const completed_wo = {
          ...formatted_wo,
          complete: true,
          synced: false,
          end_time: convertDateToCentral(moment(), timezone),
          date_submitted: convertDateToCentral(moment(), timezone),
        };

        // copy up-to-date wo basic info (unit, customer, location)
        const completed_jsa = {
          ...formatted_jsa,
          basic_information: {
            ..._jsa.basic_information,
            ...completed_wo.basic_information,
          },
        };

        updateWorkOrder(completed_wo);

        const jobType = editable ? 'edit' : 'upload';
        worker.postMessage({
          type: `work_order_${jobType}`,
          endpoint: '/next/workorders',
          token,
          flogistix_id,
          entity_id: _work_order.localId,
          upload_data: { wo: completed_wo, jsa: completed_jsa },
          resource_name: 'workorders',
        });

        addNotification(`${flogistix_id} has been submitted and is pending upload`);
        if (!is_online) addNotification('The upload will be attempted once your computer is back online');

        deleteCurrentWorkOrder();
        history.push('/');
      });
    }
    return null;
  };

  const onDeleteWorkOrder = () => {
    const msg = 'Are you sure you want to delete this work order? \nYou can not undo this action!';
    if (!confirm(msg)) return false;

    deleteWorkOrder(work_order?.localId)
      .then(() => history.push('/'))
      .catch((e) => message.error('Work order was not deleted with Error : ', e.message));
  };

  const callTypeNames = (call_types) => call_types.map((callTypeId) => allCallTypes.options.find((act) => act.value === callTypeId).label);

  const setWorkOrderProps = async () => {
    // make sure all of these are true or else the constant re-mounting will spam requests
    if ((read_only || editable) && is_online && !resource_loading && !user_settings_loading) {
      // try to get local work_order first (may have been uploaded but not sycned down yet)
      const work_orders = await getWorkOrders();
      const local_work_order = work_orders.find((wo) => wo.basic_information.flogistix_id === wid);
      if (local_work_order) {
        const wo = formatLocalWorkOrder(local_work_order);
        setWorkOrder(wo);
        setWorkOrderCallTypes(wo.call_types);
        setSelectedLaborCodes(wo?.labor_codes ?? []);
        return;
      }

      const params = { 'w.flogistix_id': wid };
      const res = await getApiWorkOrder(params);
      const wo = formatApiWorkOrder(res);
      setWorkOrder(wo);
      setWorkOrderCallTypes(wo.call_types);
      setSelectedLaborCodes(wo?.labor_codes ?? []);
    }
  };

  useEffect(() => {
    if (!read_only && !editable) {
      Promise.all([getWorkOrder(wid), getJsaByWorkOrderId(wid)])
        .then((res) => {
          const [_work_order, _jsa] = res;
          setWorkOrder(_work_order);
          setWorkOrderCallTypes(_work_order.call_types);
          setSelectedLaborCodes(_work_order.labor_codes || []);
          setJSA(_jsa);
          form.validateFields();
        });
    }
  }, []);

  useEffect(() => {
    setWorkOrderProps();
  }, [is_online, resource_loading, user_settings_loading]);

  useEffect(() => {
    setErrorList([]);

    // changing the dom (error_list & call_type sections) re-renders everything
    // have to re-run the validation so that "field_name is required" shows up
    form.validateFields();
    setWorkOrderCallTypesNames(callTypeNames(work_order_call_types));
    setSelectedLaborCodes(selected_labor_codes);
  }, [work_order_call_types, selected_labor_codes]);

  const tab_props = {
    form,
    jsa,
    work_order,
    user_mechanic,
    work_order_call_types,
    selected_unit,
    resources,
    resource_loading,
    updateWorkOrderValue,
    selected_labor_codes,
    setSelectedLaborCodes,
    setWorkOrderCallTypes,
    setWorkOrderUnit,
    read_only,
    editable,
  };
  const tabs = useWorkOrderTabs(tab_props);

  const setActiveFromAnchor = (currentActiveLink) => {
    const anchorString = currentActiveLink.split('#')[1] ?? '#basic';
    let currentTab = tabs.findIndex((tab) => tab.anchor === anchorString);
    if (currentTab === -1) currentTab = 0;

    setCurrentActiveTab(currentTab);
  };

  const tab_panes = tabs.map((tab, index) => (
    <TabPane
      key={index}
      tab={(
        <Anchor.Link
          href={`#${tab.anchor}`}
          title={tab.title}
        />
      )}
    />
  ));
  const panels = tabs.map((tab, index) => (
    <Panel
      key={index}
      className="site-collapse-custom-panel"
      header={<Typography.Title level={5}><b>{tab.title}</b></Typography.Title>}
    >
      <div id={tab.anchor}>
        <tab.comp {...{ ...tab.props, ...{ callTypeNames: workOrderCallTypesByName } }} style={{ backgroundColor: 'green' }} />
      </div>
    </Panel>
  ));

  // require them to be online if the work_order hasn't already been loaded
  if (!is_online && read_only && !work_order) {
    return (
      <Form form={form}>
        <Alert
          style={{ margin: '15px' }}
          message="Connection Error"
          type="error"
          showIcon
          description="This page requires that you are connected to the internet. Ensure your connection is stable and try again."
        />
      </Form>
    );
  }

  if (work_order && work_order.synced && editable) {
    return <Redirect to={`/workorder/view/${work_order.flogistix_id}`} />
  }

  return work_order && tabs ? (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onFinishFailed={onError}
    >
      <Affix offsetTop={64}>
        <div style={{ backgroundColor: 'white', marginBottom: '15px', borderBottom: '1px solid grey' }}>
          <PageHeader
            className="pl-0"
            title={`${title}${read_only ? ' (Read Only)' : ''}`}
            extra={read_only ? jsa_id && (
              <Button
                type="primary"
                icon={<ZoomInOutlined />}
                onClick={() => history.push(`/jsa/view/${jsa_id}`)}
              >
                VIEW JSA
              </Button>
            ) : (
              <>
                <Button
                  type="primary"
                  icon={<FormOutlined />}
                  onClick={() => history.push(`/jsa/${work_order?.jsa_id ?? ''}`)}
                >
                  EDIT JSA
                </Button>
                { !editable && <WorkOrderTimer timestamp={work_order?.timestamp} /> }
                <Button
                  type="primary"
                  icon={<DeleteFilled />}
                  shape="circle"
                  size="large"
                  onClick={onDeleteWorkOrder}
                  danger
                />
                <Button
                  type="primary"
                  icon={<CheckCircleFilled />}
                  shape="circle"
                  size="large"
                  htmlType="submit"
                />
              </>
            )}
          />
          <Anchor className="tabbed-anchor" affix={false} offsetTop={287} onChange={setActiveFromAnchor}>
            <Tabs activeKey={`${currentActiveTab.toString()}`} onChange={setActive} centered>{tab_panes}</Tabs>
          </Anchor>
        </div>
      </Affix>
      {error_list.length > 0
        && (
        <Alert
          style={{ marginBottom: '15px' }}
          message="Work Order Error"
          type="error"
          showIcon
          closable
          onClose={resetErrorAlert}
          description={<ul>{error_list}</ul>}
        />
        )}
      <Collapse
        style={{
          marginBottom: '24px',
          overflow: 'hidden',
          background: '#f7f7f7',
          border: '0px',
          borderRadius: '2px',
        }}
        bordered
        activeKey={active_tabs}
        defaultActiveKey={active_tabs}
        onChange={setActiveTabs}
        className="site-collapse-custom-collapse"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {panels}
      </Collapse>
      { !read_only && (
        <Button
          className="float-right"
          size="large"
          type="primary"
          htmlType="submit"
        >
          Finalize
        </Button>
      )}
    </Form>
  ) : (
    <>
      <Form form={form}>
        <div style={{
          height: '100%', width: '100%', position: 'fixed', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <Spin size="large" />
        </div>
      </Form>
    </>
  );
};

export default workerConsumer(withRouter(WorkOrder));
