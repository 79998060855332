// imports
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

// third party imports
import {
  Card, Col, Row, Spin, notification,
} from 'antd';
import {
  sortBy, prop, map, pathOr,
} from 'ramda';

// constants
import {
  WORK_ORDER_BILLING_OPTIONS, WORK_ORDER_UNIT_OWNERSHIP_TYPES,
  applicationType,
} from '../../config/constants';
import states from '../../config/constants/states';

// flogistix
import { AntdFormField } from '../shared';
import { useCallTypes } from '../hooks';
import { getCurrentWorkOrder, updateJSAValue } from '../../db/api';

// helpers
import { makeOptions } from '../../lib/utils';
import { getUnitInfo, h2sLocation } from '../utils/unit';

const { confirm } = window;

const BasicInformation = (props) => {
  const {
    form,
    work_order,
    customers,
    customer_locations,
    units,
    jsa,
    user_mechanic,
    history,
    setWorkOrderCallTypes,
    setWorkOrderUnit,
    updateWorkOrderValue,
    read_only,
    callTypeNames,
  } = props;

  const bi = work_order.basic_information;

  const [mileages, setMileages] = useState({});
  const [customer_location_options, setCustomerLocationOptions] = useState([]);
  const [selected_unit, setSelectedUnit] = useState({});
  const [selected_customer, setSelectedCustomer] = useState({});
  const [selected_call_types, setSelectedCallTypes] = useState([]);
  const [billable, setBillable] = useState(false);
  const [readOnlyStatus, setReadOnlyStatus] = useState(false);

  const mileage_required = !selected_call_types.includes(15) && !callTypeNames.includes('Indirect');
  const unit_required = !selected_call_types.includes(3);
  const customer_required = !selected_call_types.includes(3) || billable;

  const { options: call_type_options, adjustCallTypes } = useCallTypes();

  const isReadOnly = callTypeNames.includes('Indirect') || !!read_only;

  const mileageValidator = async () => {
    const { start, end, total } = mileages;

    // don't validate total mileage if it's on a calltype that doesn't require mileage
    // unless they enter mileage
    if (!start && !end && total === 0 && !mileage_required) return;

    if (total <= 0) throw new Error('Mileage End must be greater than Mileage Start');

    if (total > 500) throw new Error('Total Mileage cannot be greater than 500');
  };

  const calculateMileages = (mileage) => {
    const { start = 0, end = 0 } = mileage;
    const total = Math.max(0, end - start);
    setMileages({ start, end, total });
    form.setFieldsValue({ total_mileage: total });
  };

  const filterCustomerLocationOptions = () => {
    const filtered_locations = selected_customer
      ? customer_locations.filter((l) => (
        parseInt(l.customer_id, 10) === parseInt(selected_customer.id, 10))) : [];
    const location_options = filtered_locations.map((l) => (
      { value: l.id, label: l.address_label }
    ));
    setCustomerLocationOptions(location_options);
  };

  const setCustomer = (customer_id) => {
    const customer = customers.find((c) => c.id === customer_id);
    setSelectedCustomer(customer);
  };

  const handleChange = async (field, val, update_jsa = false) => {
    await updateWorkOrderValue(work_order.localId, ['basic_information', field], val);
    if (update_jsa && pathOr(false, ['localId'], jsa)) await updateJSAValue(jsa.localId, ['basic_information', field], val);
  };

  const onMileageChange = async (key, val) => {
    const start = key === 'mileage_start' ? val : mileages.start || 0;
    const end = key === 'mileage_end' ? val : mileages.end || 0;
    calculateMileages({ start, end });

    await handleChange(key, val);
  };

  const onCallTypeChange = async (checked_call_types) => {
    // make sure the checkboxes aren't updated in case the user cancels the change
    // the form will be updated in the selected_call_types useEffect which is called later
    form.setFieldsValue({ call_types: selected_call_types });

    const confirm_msg = `Changing call type may result in loss of entered JSA and Work Order data. 
      Are you sure you would like to change the call type?`;
    if (!confirm(confirm_msg)) return false;

    const call_type_to_add = checked_call_types.find((ct) => !selected_call_types.includes(ct));
    // only run call type special case logic if adding a call type
    const new_call_types = call_type_to_add
      ? adjustCallTypes(selected_call_types, call_type_to_add)
      : checked_call_types;

    if (selected_call_types.includes(3)) {
      // warn about needing to complete a jsa
      if (!jsa?.complete) {
        const jsa_msg = 'Changing away from Indirect will require a JSA to be filled out. Continue?';
        if (!confirm(jsa_msg)) return false;

        await updateWorkOrderValue(work_order.localId, ['call_types'], new_call_types);
        return history.push(`/jsa/${jsa?.localId ?? work_order.jsa_id}`);
      }
    }

    const reset_labor_codes_moving_away_indirect = (selected_call_types.includes(3) && !new_call_types.includes(3));
    const reset_labor_codes_moving_towards_indirect = (new_call_types.includes(3) && !selected_call_types.includes(3));

    if (reset_labor_codes_moving_away_indirect || reset_labor_codes_moving_towards_indirect) {
      await updateWorkOrderValue(work_order.localId, ['labor_codes'], []);
    }

    setSelectedCallTypes(new_call_types);
    setReadOnlyStatus(isReadOnly);
    await updateWorkOrderValue(work_order.localId, ['call_types'], new_call_types);
  };

  const onCustomerChange = async (field, customer_id) => {
    form.setFieldsValue({ customer_location_id: null });
    form.validateFields(['customer_location_id']);
    setCustomer(customer_id);

    await handleChange('customer_id', customer_id, true);
    await handleChange('customer_location_id', null, true);
  };

  const onCustomerLocationChange = async (field, customer_id) => {
    await handleChange('customer_location_id', customer_id, true);
  };

  const onBillingChange = async (billing_selections) => {
    const billing_fields = WORK_ORDER_BILLING_OPTIONS.map((opt) => opt.value);
    const billing_info = billing_fields.reduce((acc, field) => {
      acc[field] = billing_selections.includes(field);
      return acc;
    }, {});

    // fetch up to date wo to avoid updating basic_info object with stale data
    const wo = await getCurrentWorkOrder();
    const basic_info_update = {
      ...wo.wo.basic_information,
      ...billing_info,
    };

    setBillable(billing_selections.includes('billable'));

    await updateWorkOrderValue(work_order.localId, ['basic_information'], basic_info_update);

    // keep track in its own object separately to make checkbox population cleaner
    await updateWorkOrderValue(work_order.localId, ['basic_information', 'billing'], billing_selections);
  };

  const onUnitChange = async (field, id) => {
    const unit_info = getUnitInfo(units, id);

    if (pathOr(false, ['h2s_certified'], user_mechanic) !== true && unit_info.h2s_location === true) {
      notification.error({
        message: 'Unit Selection Error',
        key: 'h2s_error',
        description: `You selected a unit on an H2S site, but are not H2S certified. 
          Please choose another unit or contact your Area Manager for further assistance.`,
        duration: 8,
      });
      form.setFieldsValue({ unit_id: selected_unit.unit_id });
      return;
    }

    // fetch up to date wo to avoid updating basic_info object with stale data
    const wo = await getCurrentWorkOrder();
    const basic_info_update = {
      ...wo.wo.basic_information,
      ...unit_info,
    };
    await updateWorkOrderValue(work_order.localId, ['basic_information'], basic_info_update);

    // need to keep track of selected unit so we can "revert" their selection if not h2s certified
    setSelectedUnit(unit_info);

    // update customer and filter location list
    const { customer_id, customer_location_id, unit_id } = unit_info;

    setCustomer(customer_id);
    form.validateFields(['customer_id', 'customer_location_id']);
    form.setFieldsValue(unit_info);

    if (jsa) {
      await updateJSAValue(jsa.localId, ['basic_information', 'unit_id'], unit_id);
      await updateJSAValue(jsa.localId, ['basic_information', 'customer_id'], customer_id);
      await updateJSAValue(jsa.localId, ['basic_information', 'customer_location_id'], customer_location_id);
    }
  };

  useEffect(() => {
    form.validateFields(['mileage_start', 'mileage_end']);
    form.setFieldsValue({ call_types: selected_call_types });
    setWorkOrderCallTypes(selected_call_types);
    setReadOnlyStatus(isReadOnly);
  }, [selected_call_types, callTypeNames]);

  useEffect(() => {
    filterCustomerLocationOptions();
  }, [selected_customer]);

  useEffect(() => {
    setWorkOrderUnit(selected_unit);
  }, [selected_unit]);

  useEffect(() => {
    form.validateFields(['total_mileage']);
  }, [mileages]);

  useEffect(() => {
    const { customer_id, customer_location_id } = bi;

    // set unit info display fields & defaults (on new work_order)
    const unit = getUnitInfo(units, bi.unit_id);
    setSelectedUnit(unit);
    form.setFieldsValue(unit);

    // overwrite WO's customer & location with unit defaults
    form.setFieldsValue({ customer_id, customer_location_id });

    // needed to filter customer_location_options on load
    setCustomer(customer_id);

    // needed to dynamically validate mileage (in shop does not require mileage) on load
    setSelectedCallTypes(work_order.call_types);
    setReadOnlyStatus(isReadOnly);

    // needed to calculate and validate total_mileage on load
    const { mileage_start: start, mileage_end: end } = bi;
    calculateMileages({ start, end });
  }, []);

  const customer_options = sortBy(prop('label'), map(makeOptions(['name'], 'id'), customers));

  const state_options = states.map((state) => ({
    label: state,
    value: state,
  }));

  const unit_options = units.map((unit) => (
    {
      value: unit.id,
      label: unit.number + (h2sLocation(unit) ? ' (H2S)' : ''),
    }
  ));

  if (!work_order) return <Spin />;

  return (
    <>
      <Row gutter={16}>
        <Col flex={2} span={9}>
          <Card title="Mileage" style={{ marginBottom: '15px' }}>
            <Row gutter={[12, 0]}>
              <Col span={8}>
                <AntdFormField
                  type="number"
                  style={{ marginBottom: '0px' }}
                  name="mileage_start"
                  label="Start"
                  validation_rules={[{
                    required: mileage_required,
                    message: 'Mileage Start is required',
                  }]}
                  min={0}
                  onChange={onMileageChange}
                  defaults={bi}
                  read_only={read_only}
                />
              </Col>
              <Col span={8}>
                <AntdFormField
                  type="number"
                  style={{ marginBottom: '0px' }}
                  name="mileage_end"
                  label="End"
                  validation_rules={[{
                    required: mileage_required,
                    message: 'Mileage End is required',
                  }]}
                  min={0}
                  onChange={onMileageChange}
                  defaults={bi}
                  read_only={read_only}
                />
              </Col>
              <Col span={8}>
                <AntdFormField
                  type="readonly"
                  style={{ marginBottom: '0px' }}
                  name="total_mileage"
                  label="Total"
                  validation_rules={[{ validator: mileageValidator }]}
                  defaults={bi}
                  read_only={read_only}
                />
              </Col>
            </Row>
          </Card>
          <Card
            title="Work Order Type"
            style={{
              marginBottom: '15px',
              border: selected_call_types.length === 0 ? '1px solid red' : '1px solid #f0f0f0',
            }}
          >
            <AntdFormField
              style={{ marginBottom: '0px' }}
              type="columncheckboxgroup"
              name="call_types"
              validation_rules={[{ required: true, message: 'At least one Call Type is required' }]}
              onChange={onCallTypeChange}
              options={call_type_options}
              defaults={work_order}
              read_only={read_only}
            />
          </Card>
          <Card title="Billing Information" style={{ marginBottom: '15px' }}>
            <AntdFormField
              style={{ marginBottom: '0px' }}
              type="columncheckboxgroup"
              name="billing"
              onChange={onBillingChange}
              options={WORK_ORDER_BILLING_OPTIONS}
              defaults={bi}
              read_only={read_only}
              required={false}
            />
          </Card>
        </Col>
        <Col flex={3} span={15}>
          <Card title="Unit Information" style={{ marginBottom: '15px' }}>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <AntdFormField
                  type="select"
                  name="unit_id"
                  label="Unit Number"
                  onChange={onUnitChange}
                  options={unit_options}
                  validation_rules={[{ required: unit_required, message: 'Unit Number is required' }]}
                  defaults={bi}
                  {...(unit_required ? {} : { allowClear: true })}
                  read_only={readOnlyStatus}
                />
              </Col>
              <Col span={12}>
                <AntdFormField
                  type="select"
                  name="unit_status"
                  label="Unit Ownership"
                  options={WORK_ORDER_UNIT_OWNERSHIP_TYPES}
                  defaults={bi}
                  read_only
                />
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <AntdFormField
                  type="readonly"
                  name="unit_type"
                  label="Unit Type"
                  style={{ marginBottom: '0px' }}
                  defaults={bi}
                  read_only={readOnlyStatus}
                  required={unit_required}
                />
              </Col>
              <Col span={12}>
                <AntdFormField
                  type="readonly"
                  name="unit_category"
                  label="Unit Category"
                  style={{ marginBottom: '0px' }}
                  defaults={bi}
                  read_only={readOnlyStatus}
                  required={unit_required}
                />
              </Col>
            </Row>
          </Card>
          <Card title="Customer Information">
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <AntdFormField
                  type="select"
                  name="customer_id"
                  label="Customer"
                  defaults={bi}
                  options={customer_options}
                  onChange={onCustomerChange}
                  validation_rules={[{ required: customer_required, message: 'You must choose a customer' }]}
                  read_only={readOnlyStatus}
                />
              </Col>
              <Col span={12}>
                <AntdFormField
                  type="select"
                  name="customer_location_id"
                  label="Customer Location"
                  defaults={bi}
                  options={customer_location_options}
                  onChange={onCustomerLocationChange}
                  validation_rules={[{ required: customer_required, message: 'You must choose a customer location' }]}
                  placeholder={selected_customer
                    ? 'Start Typing to Search...'
                    : 'Select a customer for location options'}
                  read_only={!selected_customer || readOnlyStatus}
                />
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <AntdFormField
                  label="Contact Name"
                  name="customer_contact"
                  onChange={handleChange}
                  defaults={bi}
                  required={false}
                  read_only={readOnlyStatus}
                />
              </Col>
              <Col span={12}>
                <AntdFormField
                  type="select"
                  label="Application Type"
                  name="application_id"
                  options={applicationType}
                  onChange={handleChange}
                  defaults={bi}
                  validation_rules={[{ required: customer_required, message: 'Application Type is required' }]}
                  read_only={readOnlyStatus}
                />
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <AntdFormField
                  type="select"
                  style={{ marginBottom: '0px' }}
                  name="state"
                  label="State"
                  options={state_options}
                  onChange={handleChange}
                  defaults={bi}
                  required={false}
                  read_only={readOnlyStatus}
                />
              </Col>
              <Col span={12}>
                <AntdFormField
                  style={{ marginBottom: '0px' }}
                  name="county"
                  label="County"
                  onChange={handleChange}
                  defaults={bi}
                  required={false}
                  read_only={readOnlyStatus}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(BasicInformation);
