import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
/**
 * @typedef {Object} TimerReturn
 * @property {number} allocated_time
 * @property {number} adjustment_time
 * @property {number} unallocated_time
 * @property {number} total_time
 * @property {function} formatTime
*/
/**
 * React hook to track time consistently.
 * Returns unallocated_time, allocated_time, and total_time in seconds
 * and returns formatTime Function to be used
 *
 * @param {number} allocated
 * @param {timestamp} wo_timestamp
 * @param {timestamp} negativeAdjustment
 * @returns {TimerReturn} Object containing unallocated_time, allocated_time, and total_time in seconds
 * and formatTime function
 */
const useTimer = (allocated, wo_timestamp = 0, timeAdjustmentAmount = 0) => {
  const timer = useRef(null);
  const [total_time, setTotalTime] = useState(0);
  const [allocated_time, setAllocatedTime] = useState(0);
  const [unallocated_time, setUnallocatedTime] = useState(0);
  const [adjustment_time, settimeAdjustmentAmountInSeconds] = useState(0);

  const isNegative = (greaterNum, lesserNum = 0) => greaterNum < lesserNum;

  /**
   * Format time into a human readable format
   * @param {number} seconds
   * @param {boolean} show_negative
   * @returns string
   */
  const formatTime = (seconds, show_negative = false) => {
    const sign = show_negative && isNegative(seconds) ? '-' : '';

    // eslint-disable-next-line no-param-reassign
    if (isNegative(seconds)) seconds *= -1;

    const format = (n) => (`0${Math.abs(n)}`).slice(-2);

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    return `${sign}${format(h)}:${format(m)}:${format(s)}`;
  };

  const setTimer = (startTimeForTimer) => {
    if (timer.current !== null) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(() => {
      setTotalTime(moment().unix() - startTimeForTimer);
    }, 1000);
  };

  const calculateTimeDifference = (firstTime, secondTime, thirdTime = 0) => {
    if (firstTime > secondTime) {
      setUnallocatedTime(firstTime - secondTime - thirdTime);
      return;
    }

    setUnallocatedTime(secondTime - firstTime + thirdTime);
  };

  useEffect(() => {
    setTimer(wo_timestamp);
    return () => {
      clearInterval(timer.current);
      timer.current = null;
    };
  }, []);

  useEffect(() => {
    calculateTimeDifference(allocated_time, total_time, adjustment_time);
  }, [total_time, allocated_time, adjustment_time]);

  useEffect(() => {
    setAllocatedTime(allocated * 60);
  }, [allocated]);

  useEffect(() => {
    settimeAdjustmentAmountInSeconds(timeAdjustmentAmount * 60);
  }, [timeAdjustmentAmount]);

  return {
    allocated_time,
    adjustment_time,
    unallocated_time,
    total_time,
    formatTime,
  };
};

export default useTimer;
