import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledTable, Panel } from '.';

const Label = styled.span`
  font-size: 1.17em;
  font-weight: bold;
`;

const DashboardTable = (props) => {
  const {
    zebra,
    label,
    scroll,
    data_source,
    cols,
    row_key,
    row_click,
    page_size,
    table_key,
  } = props;

  const [getCurrentRecordCount, setCurrentRecordCount] = useState(page_size);

  const updateRecordCount = (page, pageSize) => {
    const recordCount = page * pageSize;
    const totalRecords = data_source.length;

    if (recordCount > totalRecords) return setCurrentRecordCount(totalRecords);
    setCurrentRecordCount(recordCount);
  };

  const keyElements = table_key.map((key) => (
    <div className="table-key" key={key}>
      <div className={`table-key_color table-row-${key.replace(' ', '')}`} />
      <div className="table-key_label">
        {key}
      </div>
    </div>
  ));

  return (
    <Panel>
      <div>
        <Label>{label}</Label>
        <Label style={{ float: 'right', fontWeight: 'normal' }}>
          {getCurrentRecordCount || data_source.length}
          {' '}
          of
          {' '}
          {data_source.length}
        </Label>
        <div className="table-keys">
          {keyElements}
        </div>
        <hr />
      </div>
      <div style={{ marginTop: '10px' }}>
        <StyledTable
          zebra={zebra}
          scroll={scroll}
          size="small"
          rowKey={row_key}
          onRow={row_click}
          dataSource={data_source}
          columns={cols}
          pagination={{ pageSize: page_size, onChange: updateRecordCount }}
        />
      </div>
    </Panel>
  );
};

export default DashboardTable;
