import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment';

import {
  Button, Modal, message, Table, Tooltip, Typography, Space,
} from 'antd';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';

import {
  addKeys, guessSorter, convertCentralToDate,
  sortObjectsBy,
} from '../../lib/utils';
import { TIMEZONES } from '../../config/constants/date-time';

import { AuthContext } from '../../hoc/auth/Provider';
import { workerConsumer } from '../../api/workers/WorkerProvider';
import { addNotification } from '../../db/api';
import { siteLogsLiveQuery } from '../../db/site_logs';

const PendingUploadsModal = (props) => {
  const { pending_items, worker, onCancel } = props;
  const { Text } = Typography;

  const userSettings = useSelector((state) => state.userSettings);
  const { is_online } = useContext(AuthContext);

  const { value: timezone, label: timezone_label } = userSettings?.timezone ?? TIMEZONES.central;

  const token = localStorage.getItem('access_token');

  const logs = useLiveQuery(() => siteLogsLiveQuery()) || [];
  const sorted_logs = sortObjectsBy(logs, 'timestamp')
    .reverse().map((log) => ({
      flogistix_id: log.body ? log.body.flogistix_id : undefined, body: log.body,
    }));

  const onUploadClick = (row) => {
    if (!is_online) return message.error('Internet is required to upload data!', 5);

    const { localId: entity_id, flogistix_id } = row;
    const entity = flogistix_id.split('_')[1];

    worker.postMessage({
      type: `${entity}_retry`,
      entity_id,
      token,
    });
    addNotification(`${flogistix_id} requeued for upload`);
  };

  const getDateDisplay = (date) => {
    if (!date) return '';

    return moment(convertCentralToDate(date, timezone)).format('L LTS');
  };

  const cols = [
    {
      title: `Date Submitted (${timezone_label})`,
      key: 'date_submitted',
      render: (date) => getDateDisplay(date),
    },
    {
      title: 'ID',
      key: 'flogistix_id',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,

      render: (_, row) => (
        <Space>
          {
            row.error !== null && (
              <Tooltip title="Edit item">
                <Link
                  to={() => `/workorder/edit/${row.flogistix_id}`}
                  state={{ editable: true }}
                  className="ant-btn ant-btn-round"
                  onClick={() => onCancel()}

                >
                  <EditOutlined />
                </Link>
              </Tooltip>
            )
          }
          <Tooltip title="Retry upload">
            <Button
              icon={<UploadOutlined />}
              shape="round"
              onClick={() => onUploadClick(row)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const columns = cols.map((col) => (
    {
      ...col,
      dataIndex: col.key,
      sorter: (a, b) => guessSorter(a, b, col.key),
    }
  ));

  // move WO basic info to the top level for flogistix_id
  const items = pending_items.map((item) => {
    const itemError = [
      ...new Set(
        sorted_logs
          .filter((log) => log.flogistix_id === item.flogistix_id)
          .filter((l) => l.body !== undefined && l.body.reason && l.body.status),
      ),
    ];
    const error = itemError.length ? {
      reason: itemError[0].body.reason,
      status: itemError[0].body.status,
    } : null;
    return {
      ...item,
      error,
      ...item?.basic_information ?? {},
    };
  });

  return (
    <Modal
      style={{
        minWidth: '250px',
        maxWidth: '600px',
      }}
      width="90%"
      footer={null}
      title="Items Pending Upload"
      {...props}
    >
      <Table
        columns={columns}
        expandable={{
          rowExpandable: (row) => row.error !== null,
          expandedRowRender: (row) => (
            <Space direction="vertical">
              <p>
                Error code:
                {' '}
                <Text type="danger">{row.error.status}</Text>
              </p>
              <p>
                Reason:
                {' '}
                <Text type="danger">{row.error.reason}</Text>
              </p>
            </Space>
          ),
        }}
        dataSource={addKeys(items, 'flogistix_id')}
      />
    </Modal>
  );
};

export default workerConsumer(PendingUploadsModal);
