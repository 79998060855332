import React from 'react';
import {
  Row, Col, Form, Input, Checkbox,
} from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const CheckboxGroupWithOther = (props) => {
  const {
    group: {
      defaultValue,
      options,
      onChange,
    },
    input,
    read_only,
    showOther,
  } = props;

  const makeCheckbox = (field) => (
    <Col span={12} key={field.label}>
      <Checkbox value={field.value}>{field.label.replaceAll('_', ' ')}</Checkbox>
    </Col>
  );

  const checkboxes = options.map(makeCheckbox);
  const other_checkbox = makeCheckbox({ label: 'OTHER', value: 'other' });

  return (
    <>
      <Checkbox.Group
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={read_only}
        name="controls"
      >
        <Row>
          {checkboxes}
        </Row>
        <Row style={{ marginTop: '15px' }}>
          {other_checkbox}
        </Row>
      </Checkbox.Group>
      {showOther
        && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="OTHER DESCRIPTION"
              key="other_description"
              hasFeedback
            >
              <Input.TextArea autoSize={{ minRows: 4 }} {...input} />
            </Form.Item>
          </Col>
        </Row>
        )}
    </>
  );
};
