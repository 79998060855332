import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  List,
  Row,
  Tag,
} from 'antd';
import { pathOr } from 'ramda';
import uuid from 'uuid/v4';
import { CheckCircleOutlined } from '@ant-design/icons';
import { AntdFormField } from '../shared';

const Members = (props) => {
  const {
    jsa,
    updateJSAValue,
    form,
    read_only,
  } = props;

  const [signatures, setSignatures] = useState(pathOr([], ['signatures'], jsa));
  const [signature, setSignature] = useState('');

  const onSignatureChange = (field, val) => setSignature(val);

  const addSignature = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSignature('');
    setSignatures([...signatures, signature]);
    await updateJSAValue(jsa.localId, ['signatures'], [...signatures, signature]);
  };

  const removeSignature = async (sig) => {
    const new_sigs = signatures.filter((s) => (s !== sig));
    setSignatures(new_sigs);
    await updateJSAValue(jsa.localId, ['signatures'], new_sigs);
  };

  const validateSignatures = async () => {
    if (signatures.length < 1) throw new Error('You must have at least one Signature');
  };

  useEffect(() => {
    form.validateFields();
  }, [signatures]);

  return (
    <section className={`section-validation section-validation--border${signatures.length === 0 ? '__error' : ''}`}>
      <Divider>Team Members</Divider>
      <Alert
        message="I UNDERSTAND THAT I MAY STOP ANY JOB/TASK/ACTIVITY I FEEL IS UNSAFE AND I MUST REPORT ANY WORK RELATED INJURY IMMEDIATELY."
        description="In addition, I am aware of the job hazards and the controls put in place to ensure a safe and environmentally sound job/task/activity."
        type="warning"
        showIcon
      />
      <Divider />
      <Row justify="center">
        <Col span={24}>
          <Row justify="center">
            <Col
              xs={{
                span: 24,
              }}
              md={{
                span: 12,
              }}
              xxl={{
                span: 8,
              }}
            >
              <Row justify="end">
                <Col span={24}>
                  <AntdFormField
                    name="signature"
                    label="Signature"
                    placeholder="Full Name"
                    onChange={onSignatureChange}
                    onPressEnter={addSignature}
                    required={false}
                    read_only={read_only}
                  />
                </Col>
                <Col>
                  <Button
                    style={{ float: 'right' }}
                    type="primary"
                    disabled={signature.trim().length < 1}
                    onClick={addSignature}
                  >
                    Agree
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              xs={{
                span: 24,
              }}
              md={{
                span: 12,
              }}
              xxl={{
                span: 8,
              }}
            >
              <Form.Item
                name={[
                  { errors: 'signature' },
                  { name: 'Team Member Signatures' },
                ]}
                htmlFor="signature"
                rules={[{ validator: validateSignatures }]}
              >
                <List
                  dataSource={signatures}
                  renderItem={(item) => (
                    <List.Item key={uuid()}>
                      <Tag
                        icon={<CheckCircleOutlined />}
                        color="success"
                        onClose={() => removeSignature(item)}
                        closable={!read_only}
                      >
                        {item}
                      </Tag>
                    </List.Item>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default Members;
