import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentWorkOrder, createWorkOrder } from '../../db/api';
import { convertDateToCentral } from '../../lib/utils';

import { STATUS_TO_OWNERSHIP } from '../../config/constants';

const useWorkOrderHelpers = (props) => {
  const { history, setNewWOStep } = props;
  const [settings, setUserSettings] = useState([]);
  const [userMechanicRecord, setUserMechanicRecord] = useState({});

  const userSettings = useSelector((state) => state.userSettings);

  const getWorkOrderPath = async (work_order, jsa) => {
    const path = jsa.complete || work_order.call_types.includes(3)
      ? `/workorder/${work_order.localId}`
      : `/jsa/${jsa.localId}`;
    return path;
  };

  const initializeWorkOrder = (selectedUnit, selectedCallTypes = []) => {
    const basic_information = {
      unit_id: selectedUnit.id,
      asset_id: selectedUnit.id,
      asset_number: selectedUnit.number,
      customer_id: selectedUnit.customer_id,
      customer: selectedUnit.customer,
      customer_location_id: selectedUnit.location_id,
      lease: selectedUnit.location,
      state: selectedUnit.state,
      county: selectedUnit.county,
      ownership: STATUS_TO_OWNERSHIP[selectedUnit.status] || null,
    };

    const startTime = Date.now();

    const newWorkOrder = {
      call_types: selectedCallTypes,
      basic_information,
      start_time: convertDateToCentral(startTime),
      labor_codes: [],
    };
    const newJsa = { basic_information };

    createWorkOrder(userSettings, newWorkOrder, newJsa, userMechanicRecord).then(async (res) => {
      const path = await getWorkOrderPath(res.wo, res.jsa);
      history.push(path);
    });
  };

  const openWorkOrder = async (record = null, selectedCallTypes = []) => {
    const cwo = await getCurrentWorkOrder();
    if (cwo.wo) {
      const path = await getWorkOrderPath(cwo.wo, cwo.jsa);
      history.push(path);
      return;
    }

    if (setNewWOStep) return setNewWOStep('calltype');

    initializeWorkOrder(record, selectedCallTypes, history);
  };

  useEffect(() => {
    setUserSettings(userSettings.settings);
    setUserMechanicRecord(userSettings.mechanic);
  }, [userSettings]);

  return { settings, openWorkOrder };
};

export default useWorkOrderHelpers;
