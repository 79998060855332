import React from 'react';
import { pathOr } from 'ramda';
import { Row, Col } from 'antd';
import { AntdFormField } from '../shared';

const TroubleCall = (props) => {
  const {
    form,
    reasons,
    updateWorkOrderValue,
    work_order,
    read_only,
  } = props;

  const tc = pathOr({}, ['trouble_calls'], work_order);

  const handleChange = async (field, val) => {
    await updateWorkOrderValue(work_order.localId, ['trouble_calls', field], val);
  };

  const handleDateTimeChange = async (field, val) => {
    const date_time = val ? val.format('YYYY-MM-DD HH:mm:ss') : null;
    await handleChange(field, date_time);
  };

  const handlePhoneNumberChange = async (field, val) => {
    const phone_number = val ? val.replace(/\D/g, '') : null;
    form.setFieldsValue({ phone_number });
    await handleChange(field, phone_number);
  };

  const reason_options = reasons.filter((r) => r.type_id === 1)
    .map((r) => ({ label: r.reason, value: r.id }));

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <AntdFormField
            type="datetimepicker"
            name="time"
            label="Call Out Time"
            onChange={handleDateTimeChange}
            defaults={tc}
            read_only={read_only}
          />
        </Col>
        <Col span={6}>
          <AntdFormField
            type="select"
            name="reason_id"
            label="Call Out Reason"
            options={reason_options}
            onChange={handleChange}
            defaults={tc}
            read_only={read_only}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <AntdFormField
            name="first_name"
            label="First Name"
            onChange={handleChange}
            defaults={tc}
            read_only={read_only}
          />
        </Col>
        <Col span={6}>
          <AntdFormField
            name="last_name"
            label="Last Name"
            onChange={handleChange}
            defaults={tc}
            read_only={read_only}
          />
        </Col>
        <Col span={6}>
          <AntdFormField
            name="phone_number"
            label="Contact Phone Number"
            onChange={handlePhoneNumberChange}
            defaults={tc}
            read_only={read_only}
          />
        </Col>
      </Row>
    </>
  );
};

export default TroubleCall;
