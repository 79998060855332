import React, { useEffect, useState } from 'react';
import {
  Card, Row, Col, Statistic, Form,
} from 'antd';
import { Select } from '../shared';
import { ucFirst } from '../../lib/utils';

const UnitTransferForm = (props) => {
  const {
    title, customers, customer_locations, transfer_type, form,
    setFormValue, validateCustomerLocations,
  } = props;
  const [selected_customer, setSelectedCustomer] = useState({});
  const [customer_location_options, setCustomerLocationOptions] = useState([]);
  const [selected_customer_location, setSelectedCustomerLocation] = useState({});

  const onCustomerSelect = (value) => {
    const customer = customers.find((l) => parseInt(l.id) === parseInt(value));
    const location_options = customer_locations.filter((l) => parseInt(l.customer_id) === parseInt(value));

    setSelectedCustomer(customer);
    setCustomerLocationOptions(location_options);
    setSelectedCustomerLocation({});
    form.setFieldsValue({ [`${ucFirst(transfer_type)} Customer Location`]: null });
  };

  const onCustomerLocationSelect = (value) => {
    const customer_location = customer_location_options.find((l) => parseInt(l.id) === parseInt(value));
    setSelectedCustomerLocation(customer_location);
  };

  useEffect(() => {
    setFormValue(`transfer_${transfer_type}_customer_location_id`, selected_customer_location.id);
    setFormValue(`transfer_${transfer_type}_ns_customer_id`, selected_customer_location.customer_id);
  }, [selected_customer_location]);

  return (
    <Card title={title}>
      <Row>
        <Col span={24}>
          <Form.Item
            name={`${ucFirst(transfer_type)} Customer`}
            label="Customer"
            required
            rules={[{ required: true }]}
          >
            <Select
              value={selected_customer.name}
              onChange={onCustomerSelect}
              options={customers.map((customer) => (
                { value: customer.id, label: customer.name }
              ))}
            />
          </Form.Item>
        </Col>
      </Row>
      {selected_customer.id && (
      <Row>
        <Col span={24}>
          {customer_location_options.length > 0 ? (
            <Form.Item
              name={`${ucFirst(transfer_type)} Customer Location`}
              label="Customer Location"
              required
              validateTrigger="onSubmit"
              rules={[
                { required: true },
                { validator: validateCustomerLocations },
              ]}
            >
              <Select
                value={selected_customer_location.address_label}
                onChange={onCustomerLocationSelect}
                options={customer_location_options.map((location) => (
                  { value: location.id, label: location.address_label }
                ))}
              />
            </Form.Item>
          )
            : <h4>Customer has no locations</h4>}
        </Col>
      </Row>
      )}
      {selected_customer_location.id && (
        <Row>
          <Col span={11} offset={1}>
            <Statistic
              valueStyle={{ fontSize: '16px', display: (selected_customer_location.city ? 'inherit' : 'none') }}
              title="City"
              value={selected_customer_location.city}
            />
          </Col>
          <Col span={11} offset={1}>
            <Statistic
              valueStyle={{ fontSize: '16px' }}
              title="State"
              value={selected_customer_location.state}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default UnitTransferForm;
