import React from 'react';
import { Progress } from 'antd';
import { syncConsumer } from '../../api/resources';

const SyncProgress = (props) => {
  const { completed } = props;
  return (
    <Progress percent={completed} />
  );
};

export default syncConsumer(SyncProgress);
