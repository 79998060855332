export const new_set_checklist_fields = [
  { label: 'Panel Wiring', name: 'panel_wiring' },
  { label: 'Skid Piping', name: 'skid_piping' },
  { label: 'Cooler Fan Motor', name: 'cooler_fan_motor' },
  { label: 'Relays', name: 'relays' },
  { label: 'Pipe Flanges', name: 'pipe_flanges' },
  { label: 'VFD', name: 'vfd' },
  { label: 'Display', name: 'display' },
  { label: 'Oil Leaks', name: 'oil_leaks' },
  { label: 'Compressor', name: 'compressor' },
  { label: 'Battery', name: 'batteries' },
  { label: 'Coolant Leaks', name: 'coolant_leaks' },
  { label: 'Cooler', name: 'cooler' },
  { label: 'Solar Panel', name: 'solar_panel' },
  { label: 'Seal Fluid Leaks', name: 'seal_fluid_leaks' },
  { label: 'Panel Programming', name: 'panel_programming' },
  { label: 'Skid Wiring', name: 'skid_wiring' },
  { label: 'Engine', name: 'engine' },
  { label: 'Automated Control Valves', name: 'automated_control_valves' },
  { label: 'Skid Tubing', name: 'skid_tubing' },
  { label: 'Driver Motor', name: 'driver_motor' },
  { label: 'Dump System', name: 'dump_system' },
];

export const new_set_checklist_options = [
  { label: 'Pass', value: 'pass' },
  { label: 'Fail', value: 'fail' },
  { label: 'N/A', value: 'n_a' },
];

export const new_set_previous_location_options = [
  { label: 'Yard', value: 'Yard' },
  { label: 'Another Customer Location', value: 'Customer Location' },
  { label: 'Other', value: 'Other' },
];

export const new_set_transported_by_options = [
  { label: 'Flogistix', value: 'flogistix' },
  { label: 'Third Party', value: 'third_party' },
  { label: 'Customer', value: 'customer' },
];
