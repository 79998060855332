import { GET_API_HEALTH, GET_API_HEALTH_RESOLVED } from '../actionTypes/index';
import api from '../../api';

const HEALTH_CHECK_INTERVAL = 10000;

const getApiHealth = () => (dispatch) => {
  setInterval(async () => {
    if (!navigator.onLine) dispatch({ type: GET_API_HEALTH_RESOLVED, isOnline: false });
    else {
      dispatch({ type: GET_API_HEALTH });
      try {
        const healthCheckRes = await api.get('/health');
        if (healthCheckRes.status === 200) { 
          dispatch({ type: GET_API_HEALTH_RESOLVED, isOnline: true });
        } else dispatch({ type: GET_API_HEALTH_RESOLVED, isOnline: false });
      } catch (err) {
        dispatch({ type: GET_API_HEALTH_RESOLVED, isOnline: false });
    }
    }
  }, HEALTH_CHECK_INTERVAL);
};

export default getApiHealth;
