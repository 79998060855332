/* eslint-disable */
import { propOr, pathOr } from 'ramda';

import {
  getRecentWorkOrdersByDate,
  getLocalWorkOrdersByDate
} from './work-orders';
import {
  getInventoryTransfersByDate,
  getLocalInventoryTransfersByDate
} from './inventoryTransfers';
import { removeDuplicateRecords } from '../lib/utils';

/**
  * Stats that are displayed for the current mechanic
  * @typedef {object} StatsObject
  * @property {number} totalMiles - the total number of miles that have been driven in the last week
  * @property {number} totalParts - the total number of parts that have been used in the last
  * @property {number} secondsWorked - the total time, in seconds, for work orders in the last week
  * @property {number} totalWorkOrders - the total number of work orders completed in the last week
  * @property {number} totalTransfers - the total number of part transfers in the last week
  */

/**
 * Gets the weekly stats for the current mechanic
 * @returns {StatsObject} - the weekly stats for this mechanic
 */
export const getWeeklyStats = async (start, end) => {

  const uploaded_work_orders = await getRecentWorkOrdersByDate(start, end);
  const local_work_orders = await getLocalWorkOrdersByDate(start, end);
  const merged_work_orders = [
    ...uploaded_work_orders,
    ...local_work_orders
  ];
  const work_orders =
    removeDuplicateRecords(merged_work_orders, ['basic_information', 'flogistix_id']);

  const uploaded_transfers = await getInventoryTransfersByDate(start, end);
  const local_transfers = await getLocalInventoryTransfersByDate(start, end);
  const merged_inventory_transfers = [
    ...uploaded_transfers,
    ...local_transfers
  ];
  const inventory_transfers =
    removeDuplicateRecords(merged_inventory_transfers, ['flogistix_id']);

  const intial_work_order_stats = {
    total_work_orders: work_orders.length,
    total_miles: 0,
    total_parts: 0,
    total_labor_minutes: 0
  };

  const total_transfers = inventory_transfers.reduce( (parts, transfer) => {
    return parts + transfer.parts.reduce( (acc, part) => (
      acc + part.quantity
    ), 0);
  }, 0);

  const work_order_stats = work_orders.reduce( (stats, wo) => {

    const labor_codes = propOr ([], 'labor_codes', wo);
    const parts = propOr ([], 'parts', wo);

    const wo_mileage_end = pathOr (0, ['basic_information', 'mileage_end'], wo);
    const wo_mileage_start = pathOr (0, ['basic_information', 'mileage_start'], wo);
    const wo_miles = parseInt (wo_mileage_end) - parseInt (wo_mileage_start);

    // Labor hours need to be in minutes
    const wo_minutes = labor_codes.reduce( (acc, code) => (
      acc + (code.minutes + (code.hours || 0) * 60)
    ), 0);

    const wo_part_quantities = parts.reduce( (acc, part) => (
      acc + part.quantity
    ), 0);

    return {
      ...stats,
      total_miles: stats.total_miles + wo_miles,
      total_labor_minutes: stats.total_labor_minutes + wo_minutes,
      total_parts: stats.total_parts + wo_part_quantities
    };
  }, intial_work_order_stats);

  return {
    ...work_order_stats,
    total_transfers
  };
};
