import db from '.';
import {
  updateJSAValue as ujsav,
  updateJSAList as ujsal,
  removeItemFromJSAList as rifjsal,
} from './jsa';

import {
  deleteWorkOrder as dwo,
  deleteCurrentWorkOrder as dcwo,
  createWorkOrder as cwo,
  getWorkOrder as gwo,
  getCurrentWorkOrder as gcw,
  getWorkOrderByJSA as gwobjsa,
  getUnitInformation,
  updateJSA as ujsa,
  updateWorkOrder as uwo,
  updateWorkOrderValue as uwov,
  updateWorkOrderList as uwol,
  removeItemFromWorkOrderList as riwol,
  getJsaByWorkOrderId as gjbwoid,
  getJsaById as gjsa,
  getWorkOrders as gwos,
  getJsas as gjsas,
} from './work-orders';

import {
  createVehicleInspection as cvi,
  getCurrentVehicleInspection as gcvi,
  getVehicleInspection as gvi,
  getVehicleInspections as gvis,
  updateVehicleInspection as uvi,
  updateVehicleInspectionValue as uviv,
  deleteVehicleInspection as dvi,
} from './vehicle_inspections';

import {
  getUpcomingPMs as gupm,
  getOverduePMs as gopm,
  getTotalUnits as gtu,
} from './pms';

import {
  getUserMechanic as gum,
  getUserMechanicRecord as gumr,
  updateUserMechanic as uum,
} from './mechanic';

import {
  getCallTypes as gct,
  getEnabledCallTypes as gect,
} from './calltypes';

import {
  getUserSettings as gus,
  updateUserSettings as uus,
} from './user_settings';

import {
  getSiteSettings as gss,
  updateSiteSettings as uss,
  updateSiteSettingsValue as ussv,
} from './site_settings';

import {
  getSiteLogs as gsl,
  addSiteLog as asl, // 29/M/NC
  addSiteNotification as asn,
  deleteSiteLog as dsl,
  deleteSiteLogs as dsls,
  addNotification as an,
  addLog as al,
} from './site_logs';

import {
  getWarehouses as gwh,
  getWarehouseById as gwhbid,
} from './warehouses';

export const getWarehouses = gwh;
export const getWarehouseById = gwhbid;

export const getUserSettings = gus;
export const updateUserSettings = uus;

export const getSiteSettings = gss;
export const updateSiteSettings = uss;
export const updateSiteSettingsValue = ussv;

export const getSiteLogs = gsl;
export const addSiteLog = asl;
export const addSiteNotification = asn;
export const deleteSiteLog = dsl;
export const deleteSiteLogs = dsls;
export const addNotification = an;
export const addLog = al;

export const createVehicleInspection = cvi;
export const getCurrentVehicleInspection = gcvi;
export const getVehicleInspections = gvis;
export const getVehicleInspection = gvi;
export const updateVehicleInspection = uvi;
export const updateVehicleInspectionValue = uviv;
export const deleteVehicleInspection = dvi;

export const getCurrentWorkOrder = gcw;

/**
 * Gets a work order from the work orders table
 * @param {string} id - id of the work order to retrieve
 * @returns {Promise} - Resolves with the returned work order
 */
export const getWorkOrder = gwo;
export const getWorkOrders = gwos;
export const getWorkOrderByJSA = gwobjsa;
export const getJsaByWorkOrderId = gjbwoid;
export const getJsaById = gjsa;
export const getJsas = gjsas;

/**
 * Updates an already existing work order in the work orders table
 * @param {Updates} updates - an object of updates to be made to the work order
 * @throws {TypeError} - if the updates object does not contain an id
 * @returns {Promise} - Resolves with the id of the updated work order
 */
export const updateWorkOrder = uwo;
export const updateWorkOrderValue = uwov;
export const updateWorkOrderList = uwol;
export const removeItemFromWorkOrderList = riwol;
export const updateJSA = ujsa;
export const updateJSAValue = ujsav;
export const updateJSAList = ujsal;
export const removeItemFromJSAList = rifjsal;

export const deleteCurrentWorkOrder = dcwo;

export const deleteWorkOrder = dwo;

export const resetWorkOrder = () => {
  console.log(
    'resetting work order',
  );
};
/**
 * Gets all units from the units table
 * @returns {Promise} - Resolves with the returned units
 */
export const getUnits = async () => db.units.toArray();

export const getUnit = getUnitInformation;

export const getParts = async () => db.parts.toArray();

/**
 * Gets all customers from the customers table
 * @returns {Promise} - Resolves with the returned customers
 */
export const getCustomers = async () => db.customers.toArray();

/**
 * Gets all customer locationsfrom the customer locations table
 * @returns {Promise} - Resolves with the returned locations
 */
export const getLocations = async () => db.customer_locations.toArray();

/**
 * Gets upcoming PMs for mechanic id
 * @param {int} mechanicId - id for mechanic
 * @returns {Promise} - results with units
 */
export const getUpcomingPMs = gupm;

/**
 * Gets overdue PMs for mechanic id
 * @param {int} mechanicId - id for mechanic
 * @returns {Promise} - results with units
 */
export const getOverduePMs = gopm;

/**
 * Gets All Units and Labels PM status for mechanic id
 * @param {int} mechanicId - id for mechanic
 * @returns {Promise} - results with units
 */
export const getTotalUnits = gtu;

/**
 * Gets user mechanic
 * @returns {Promise} - results with mechanics
 */
export const getUserMechanic = gum;

export const getUserMechanicRecord = gumr;

/**
 * Updates user mechanic
 * @returns {Promise} - results with id of the updated mechanic
 */
export const updateUserMechanic = uum;

export const getLaborCodes = async () => db.laborcodes.toArray();

export const getMechanics = async () => db.mechanics.toArray();

export const getPMTemplates = async () => db.pmtemplates.toArray();

export const getCallTypes = gct;

export const getEnabledCallTypes = gect;

export const createWorkOrder = cwo;
