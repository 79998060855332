import { Card, Col, Row } from 'antd';
import React from 'react';
import { AntdFormField } from '../shared';
import { addKeys, chunkArray } from '../../lib/utils';

const PMChecklistSection = (props) => {
  const {
    section, defaults, handleChange, title, read_only,
  } = props;

  const unitOfMeasure = (field) => {
    if (field.label.includes('Temp')) return '°F';
    if (field.label.includes('Engine Manifold')) return 'inHg';
    if (field.label.includes('Press')) return 'PSIG';
    return '';
  };
  const fields = section.map((field) => (
    <AntdFormField
      {...field}
      name={field.field_id}
      onChange={handleChange}
      defaults={defaults}
      read_only={read_only}
      placeholder={unitOfMeasure(field)}
    />
  ));

  // chunk fields into rows of 4 elements
  const chunked_fields = chunkArray(fields, 4);
  const rows = chunked_fields.map((chunk) => (
    <Row gutter={[24, 24]}>
      {addKeys(chunk.map((field) => (
        <Col span={6}>{field}</Col>)), false)}
    </Row>
  ));

  return (
    <Card
      style={{ marginBottom: '15px' }}
      title={title}
    >
      {addKeys(rows, false)}
    </Card>
  );
};

export default PMChecklistSection;
