import React, { useState, useEffect, useContext } from 'react';
import {
  Row, Col, Progress, Statistic,
} from 'antd';
import moment from 'moment';
import { StyledTable } from '../shared/index';

import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import {
  getTotalUnits,
} from '../../db/api';
import { getTotalPMs } from '../../db/pms';

// hooks
import { useCallTypes, useWorkOrderHelpers } from '../hooks';

const AssignedUnits = ({ history, syncing }) => {
  const {
    user_mechanic,
  } = useContext(UserSettingsContext);

  const { openWorkOrder } = useWorkOrderHelpers({ history });
  const { getCallTypeIdByName } = useCallTypes();

  const [all_pms_list, setAllPMLists] = useState([]);
  const [totalPMCount, setTotalPMs] = useState([]);
  const [percentage, setPercentages] = useState({});

  useEffect(() => {
    if (Object.keys(user_mechanic).length > 0 && !syncing) {
      const mechanic_id = user_mechanic.id;
      Promise.all([
        getTotalUnits(mechanic_id),
        getTotalPMs(mechanic_id),
      ]).then(([resTotalUnits, resTotalPm]) => {
        setAllPMLists(resTotalUnits);
        setTotalPMs(resTotalPm);
      });
    }
  }, [user_mechanic, syncing]);

  useEffect(() => {
    if (totalPMCount.length > 0) {
      const calculatePMPercentages = (PMList) => {
        const compareLength = PMList.length;
        const totalLength = totalPMCount.length;
        return (compareLength / totalLength) * 100;
      };
      const overduePMs = all_pms_list.filter((pm) => pm.row_type === 'overdue');
      const upcomingPMs = all_pms_list.filter((pm) => pm.row_type === 'upcoming');
      const shutinPMs = all_pms_list.filter((pm) => pm.row_type === 'shutin');
      const overduePercentage = calculatePMPercentages(overduePMs);
      const upcomingPercentage = calculatePMPercentages(upcomingPMs);
      const shutinPercentage = calculatePMPercentages(shutinPMs);
      const completedPercentage = (100 - (overduePercentage + upcomingPercentage + shutinPercentage));
      setPercentages({
        overduePercentage, upcomingPercentage, completedPercentage, shutinPercentage,
      });
    }
  }, [totalPMCount.length]);

  const cols = [
    {
      title: 'Unit Number',
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Date Due',
      dataIndex: 'next_pm_date',
      key: 'next_pm_date',
      sorter: (a, b) => moment(a.next_pm_date).isAfter(moment(b.next_pm_date)),
      sortDirections: ['descend', 'ascend'],
      render: (val) => <span>{moment(val).format('MM/DD/YYYY')}</span>,
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'display_name'],
      key: ['customer', 'display_name'],
      sorter: (a, b) => a.customer.display_name.localeCompare(b.customer.display_name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  return (
    <div style={{ marginTop: '15px' }}>
      <Row>
        <Col span={12}>
          <h2> Assigned Units </h2>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={6}>
              <Statistic title="Overdue" value={percentage.overduePercentage} suffix="%" precision={2} />
            </Col>
            <Col span={6}>
              <Statistic title="Upcoming" value={percentage.upcomingPercentage} suffix="%" precision={2} />
            </Col>
            <Col span={6}>
              <Statistic title="Shut-In" value={percentage.shutinPercentage} suffix="%" precision={2} />
            </Col>
            <Col span={6}>
              <Statistic title="Completed" value={percentage.completedPercentage} suffix="%" precision={2} />
            </Col>
          </Row>
          <Row>
            <Progress
              strokeColor="red"
              percent={percentage.overduePercentage}
              showInfo={false}
              trailColor="green"
              success={{ percent: percentage.upcomingPercentage, strokeColor: '#F8EA89' }}
            />
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={24}>
          <StyledTable
            zebra
            columns={cols}
            dataSource={all_pms_list}
            rowClassName={(record) => record.status}
            size="small"
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => { openWorkOrder(null, record, [getCallTypeIdByName('Preventative Maintenance')]); },
            })}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AssignedUnits;
