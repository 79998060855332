import styled from 'styled-components';
import { Checkbox } from 'antd';

const CheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
      width: 45%;
      margin: 2%;
  }
`;

export default CheckboxGroup;
