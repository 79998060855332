import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';

// Components
import {
  PageHeader, notification, Form, Button,
  Spin, Tabs, Anchor, Typography, Collapse, Alert, Affix,
} from 'antd';
import {
  CaretRightOutlined,
  CheckCircleFilled,
  DeleteFilled, ZoomInOutlined,
} from '@ant-design/icons';
import WorkOrderTimer from '../shared/work-order-timer';

// API Connectors
import {
  getWorkOrderByJSA,
  updateJSA,
  updateJSAValue as ujv,
  deleteWorkOrder,
  getJsas,
  addNotification,
} from '../../db/api';
import { getJsa as getApiJsa } from '../../api/jsa';

// Helpers
import { useJsaTabs, useResources } from '../hooks';
import { AuthContext } from '../../hoc/auth/Provider';
import { formatApiJsa } from '../../lib/utils';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { confirm, scrollTo } = window;

const JSA = (props) => {
  const {
    history,
    match: { params: { jid } },
    read_only,
  } = props;

  const [form] = Form.useForm();
  const { is_online, user_mechanic } = useContext(AuthContext);

  // all tabs have to be active by default for them to render and validate on pageload
  const [active_tabs, setActiveTabs] = useState(['0', '1', '2', '3', '4', '5']);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [work_order, setWorkOrder] = useState(null);
  const [jsa, setJSA] = useState();
  const [error_list, setErrorList] = useState([]);

  const { resources, resource_loading } = useResources([
    'units',
    'customers',
    'customer_locations',
    'mechanics',
  ]);

  const title = work_order?.basic_information?.flogistix_id ?? 'Work Order';
  const work_order_id = jsa?.workorder_flogistix_id ?? null;

  const updateJSAValue = async (jsa_id, path, value) => {
    await ujv(jsa_id, path, value);
  };

  const setActive = (v) => {
    if (!active_tabs.includes(v)) setActiveTabs([...active_tabs, v]);
  };

  const onError = () => {
    const error_fields = form.getFieldsError();
    const filtered_errors = error_fields.reduce((acc, field) => {
      if (field.errors.length > 0) {
        const errorDesc = field.errors[0];
        const fieldName = field.name[0]?.errors ?? field.name[0];

        acc.push(
          <li
            key={fieldName}
            onClick={() => form.scrollToField(fieldName, {
              behavior: 'smooth', scrollMode: 'if-needed', block: 'center', scrollTop: '295px',
            })}
          >
            <label htmlFor={fieldName} form={fieldName} className="underline">
              {errorDesc}
            </label>
          </li>,
        );
      }
      return acc;
    }, []);

    scrollTo(0, 0);
    setErrorList(filtered_errors);

    // changing the dom (error_list) re-renders everything
    // have to re-run the validation so that "field_name is required" shows up
    form.validateFields();
  };

  const onSubmit = async () => {
    notification.destroy();
    window.scrollTo(0, 0);

    await updateJSAValue(jsa.localId, ['complete'], true);
    history.push(`/workorder/${work_order?.localId ?? ''}#basic`);
  };

  const onDeleteWorkOrder = () => {
    const msg = 'Are you sure you want to delete this work order? \nYou can not undo this action!';
    if (!confirm(msg)) return false;

    deleteWorkOrder(work_order?.localId)
      .then(() => history.push('/'))
      .catch((e) => addNotification(`Work order was not deleted with Error: ${e.message}`, 'error'));
  };

  const jsaValidation = async () => {
    if (read_only && is_online && !resource_loading) {
      const jsas = await getJsas();
      const local_jsa = jsas.find((j) => j.basic_information.flogistix_id === jid);
      if (local_jsa) {
        setJSA(local_jsa);
        return;
      }

      const params = { 'j.flogistix_id': jid };
      const res = await getApiJsa(params);
      setJSA(formatApiJsa(res));
    }
  };

  useEffect(() => {
    // make sure all of these are true or else the constant re-mounting will spam requests
    jsaValidation();
  }, [is_online, resource_loading]);

  useEffect(() => {
    if (!read_only) {
      Promise.all([
        getWorkOrderByJSA(jid),
      ]).then((res) => {
        const { jsa: _jsa, wo: _work_order } = res[0];
        setWorkOrder(_work_order);
        setJSA(_jsa);
        updateJSAValue(_jsa.localId, ['complete'], false);
      });
    }
  }, []);

  const { tabs } = useJsaTabs({
    work_order,
    jsa,
    updateJSAValue,
    updateJSA,
    form,
    user_mechanic,
    resources,
    resource_loading,
    read_only,
  });

  const setActiveFromAnchor = (currentActiveLink) => {
    const anchorString = currentActiveLink.split('#')[1] ?? '#basic';
    let currentTab = tabs.findIndex((tab) => tab.anchor === anchorString);
    if (currentTab === -1) currentTab = 0;

    setCurrentActiveTab(currentTab);
  };

  const resetErrorAlert = () => {
    setErrorList([]);
  };

  const tab_panes = tabs.map((tab, index) => (
    <TabPane
      tab={(
        <Anchor.Link
          href={`#${tab.anchor}`}
          title={tab.title}
        />
      )}
      key={index}
    />
  ));

  const panels = tabs.map((tab, index) => (
    <Panel
      key={index}
      className="site-collapse-custom-panel"
      header={<Typography.Title level={5}><b>{tab.title}</b></Typography.Title>}
    >
      <div id={tab.anchor}>
        <tab.comp {...tab.props} />
      </div>
    </Panel>
  ));

  // require them to be online if the jsa hasn't already been loaded
  if (!is_online && read_only && !jsa) {
    return (
      <Form form={form}>
        <Alert
          style={{ margin: '15px' }}
          message="Connection Error"
          type="error"
          showIcon
          description="This page requires that you are connected to the internet. Ensure your connection is stable and try again."
        />
      </Form>
    );
  }

  return jsa && tabs ? (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onFinishFailed={onError}
    >
      <Affix offsetTop={64}>
        <div style={{ backgroundColor: 'white', marginBottom: '15px', borderBottom: '1px solid grey' }}>
          <PageHeader
            title={`${title}${read_only ? ' (Read Only)' : ''}`}
            extra={read_only ? work_order_id && (
              <Button
                type="primary"
                style={{ textTransform: 'uppercase' }}
                icon={<ZoomInOutlined />}
                onClick={() => history.push(`/workorder/view/${work_order_id}`)}
              >
                View Work Order
              </Button>
            ) : (
              <>
                <WorkOrderTimer timestamp={work_order?.timestamp} />
                <Button
                  type="primary"
                  icon={<DeleteFilled />}
                  shape="circle"
                  size="large"
                  onClick={onDeleteWorkOrder}
                  danger
                />
                <Button
                  type="primary"
                  icon={<CheckCircleFilled />}
                  shape="circle"
                  size="large"
                  htmlType="submit"
                />
              </>
            )}
          />
          <Anchor className="tabbed-anchor" affix={false} offsetTop={295} onChange={setActiveFromAnchor}>
            <Tabs activeKey={`${currentActiveTab.toString()}`} onChange={setActive} centered>{tab_panes}</Tabs>
          </Anchor>
        </div>
      </Affix>
      { error_list.length > 0
        && (
        <Alert
          style={{ marginBottom: '15px' }}
          message="JSA Error"
          type="error"
          showIcon
          closable
          onClose={resetErrorAlert}
          description={<ul>{error_list}</ul>}
        />
        )}
      <Collapse
        style={{
          marginBottom: '24px',
          overflow: 'hidden',
          background: '#f7f7f7',
          border: '0px',
          borderRadius: '2px',
        }}
        bordered
        activeKey={active_tabs}
        defaultActiveKey={active_tabs}
        onChange={setActiveTabs}
        className="site-collapse-custom-collapse"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {panels}
      </Collapse>
      { !read_only && (
        <Button
          size="large"
          className="float-right"
          type="primary"
          htmlType="submit"
        >
          Complete
        </Button>
      )}
    </Form>
  ) : (
    <div style={{
      height: '100%', width: '100%', position: 'fixed', display: 'flex', alignItems: 'center', justifyContent: 'center',
    }}
    >
      <Spin size="large" />
    </div>
  );
};

export default withRouter(JSA);
