import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';

const ColumnCheckboxWithRef = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <Checkbox.Group {...props} />
  </div>
));

const ColumnCheckboxGroup = styled(ColumnCheckboxWithRef)`
  .ant-checkbox-group-item {
      display: block;
      margin-right: 0;
  }
`;

export default ColumnCheckboxGroup;
