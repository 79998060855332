import React from 'react';
import { Table } from 'antd';

const StyledTable = (props) => {
  const {
    zebra,
    rowClassName,
    ...rest
  } = props;

  return (
    <Table
      {...rest}
      rowClassName={(record, index) => {
        if (!zebra) {
          return typeof (rowClassName) === 'function' ? rowClassName(record, index) : rowClassName;
        }

        switch (record?.row_type) {
          case 'overdue':
            return 'table-row-overdue';
          case 'upcoming':
            return 'table-row-upcoming';
          case 'shutin':
            return 'table-row-shutin';
          case 'complete':
            return 'table-row-complete';
          default:
            return index % 2 === 0 ? 'table-row-light' : 'table-row-dark';
        }
      }}
    />
  );
};

export default StyledTable;
