import Axios from 'axios';

const api = Axios.create({ baseURL: process.env.REACT_APP_API_ENDPOINT });

api.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  }),
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => ({
    ...response,
  }),
  (error) => Promise.reject(error),
);

export default api;
