const filterPartsListRemovingHyphen = (searchText, searchableList) => searchText.split(' ').reduce((acc, keyword) => (
  acc.filter((item) => {
    let partNumber = item.number;
    if (!searchText.includes('-') && partNumber.includes('-')) partNumber = partNumber.split('-').join('');

    return partNumber.toLowerCase().includes(keyword.toLowerCase())
        || item.description.toLowerCase().includes(keyword.toLowerCase());
  })), searchableList);

export default filterPartsListRemovingHyphen;
