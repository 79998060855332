import {
  pathOr,
  trim,
} from 'ramda';

export const getLabelNumber = (unit) => {
  if (!unit) return null;

  const { label, number, asset_number } = unit;
  const l = label || number || asset_number;
  return l.includes('-')
    ? trim(l.substring(l.lastIndexOf('-') + 2))
    : l;
};

export const changeUnitNumber = async (unit) => {
  const { value, ...rest } = unit;
  const ownership = (rest.status === 'CO') ? 'Customer Owned' : 'Rental';
  const labelNumber = getLabelNumber(unit);
  return {
    customer_id: rest.customer_id,
    customer_location_id: rest.location_id,
    location: rest.location,
    location_of_work: rest.location,
    lease: rest.location,
    asset_id: value,
    asset_number: labelNumber,
    state: rest.state,
    county: rest.county,
    ownership,
  };
};

export const h2sLocation = (unit) => (
  pathOr('', ['deprecated_location'], unit).toUpperCase().includes('H2S')
  || pathOr('', ['location'], unit).toUpperCase().includes('H2S')
);

// expects the units toe be passed so there isn't a fetch everytime this fn is called
export const getUnitInfo = (units, id) => {
  const unit = units.find((u) => u.id === id) || {};
  const {
    customer_id,
    location_id: customer_location_id,
    category: unit_category,
    type: unit_type,
    status: unit_status,
    id: unit_id,
    pm_type_id,
  } = unit;
  const h2s_location = h2sLocation(unit);

  return {
    customer_id, customer_location_id, unit_category, unit_type, unit_status, unit_id, h2s_location, pm_type_id,
  };
};
