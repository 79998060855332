import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row, Col, Table, Input, Button, Tooltip, Space,
} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { pathOr } from 'ramda';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { PageHeader } from '../shared';
import {
  addKeys,
  guessSorter,
  removeDuplicateRecords,
  isDefined,
  sortObjectsBy,
  convertCentralToDate,
} from '../../lib/utils';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { timezones } from '../../lib/constants';
import { getLocalWorkOrdersByDate, getRecentWorkOrdersByDate } from '../../db/work-orders';

const RecentWorkOrders = (props) => {
  const { history } = props;

  const { user_settings, user_settings_loading } = useContext(UserSettingsContext);
  const { id } = useSelector((state) => state.userSettings.mechanic);

  const [work_orders, setWorkOrders] = useState([]);
  const [filter, setFilter] = useState(null);
  const [filtered_list, setFilteredList] = useState([]);
  const [timezone_label, setTimezoneLabel] = useState('Central Time (US)');

  const timezone = pathOr('America/Chicago', ['timezone'], user_settings);

  const onViewClick = (row) => {
    const { flogistix_id } = row;
    history.push(`/workorder/view/${flogistix_id}`);
  };

  const cols = [
    {
      title: `Date Completed (${timezone_label})`,
      key: 'end_time',
      render: (date) => moment(convertCentralToDate(date, timezone)).format('L LTS'),
    },
    {
      title: 'Work Order ID',
      key: 'flogistix_id',
    },
    {
      title: 'Asset',
      key: 'asset_number',
    },
    {
      title: 'Mechanic',
      key: 'mechanic_name',
    },
    {
      title: 'Customer Name',
      key: 'legacy_customer',
    },
    {
      title: 'Uploaded',
      key: 'synced',
      // recentWorkOrders don't have this column, default to true since they're from pg (uploaded)
      render: (v) => ((isDefined(v) ? v : true) ? 'Yes' : 'No'),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      render: (_, row) => (
        <Space>
          {
            // synced is undefined or true when showing uploaded wo
            row.synced === false && (
              <Tooltip title='Edit Work Order'>
                <Link
                to={() => `/workorder/edit/${row.flogistix_id}`}
                state={{ editable: true }}
                className="ant-btn ant-btn-round"
                >
                  <EditOutlined />
                </Link>
              </Tooltip>
            )
          }
          <Tooltip title='View Work Order'>
          <Button
            icon={<SearchOutlined />}
            shape="round"
            onClick={() => onViewClick(row)}
          />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const columns = cols.map((col) => (
    {
      ...col,
      dataIndex: col.key,
      sorter: (a, b) => guessSorter(a, b, col.key),
    }
  ));

  const gatherWorkOrders = async () => {
    const start = moment.tz(timezone).subtract(1, 'months').format();
    const end = moment.tz(timezone).format();
    const uploaded_work_orders = await getRecentWorkOrdersByDate(start, end);
    const local_work_orders = await getLocalWorkOrdersByDate(start, end);

    // move basic info to the top level
    const formatted_local_work_orders = local_work_orders.map((wo) => (
      { ...wo, ...wo.basic_information }
    ));

    const all_work_orders = [
      ...uploaded_work_orders,
      ...formatted_local_work_orders,
    ];
    const merged_work_orders = removeDuplicateRecords(all_work_orders, ['basic_information', 'flogistix_id']);
    const final_work_orders = merged_work_orders.filter((wo) => wo.mechanic_id === id);
    setWorkOrders(final_work_orders);
    setFilteredList(final_work_orders);
  };

  useEffect(() => {
    const timeout_id = setTimeout(() => {
      if (filter === null) return;

      // this doesn't work on date since it's technically MM-DD-YYYY, but the display is MM/DD/YYYY
      const filtered_data = filter.split(' ').reduce((reduced, keyword) => reduced.filter((record) => {
        const compound_search = cols.map((col) => (record[col.key])).join('');
        return compound_search.toLowerCase().includes(keyword.toLowerCase());
      }), work_orders);

      setFilteredList(filtered_data);
    }, 500);
    // delay filter by 500ms after last keypress
    return () => clearTimeout(timeout_id);
  }, [filter]);

  useEffect(() => {
    if (user_settings_loading || !user_settings) return;

    const { label } = timezones.find((z) => z.value === timezone);
    setTimezoneLabel(label);
  }, [user_settings_loading, user_settings]);

  useEffect(() => {
   gatherWorkOrders();
  }, [id]);

  return (
    <div>
      <PageHeader
        className="pl-0 pr-0"
        title="Recent Work Orders"
        subtext="Showing recent work orders for past 30 days"
      />
      <Row gutter={[0, 16]}>
        <Col span={8}>
          <Input
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Type to Filter on Any Column"
          />
        </Col>
      </Row>
      <Table
        loading={!id && !filtered_list.length}
        columns={columns}
        dataSource={addKeys(sortObjectsBy(filtered_list, 'flogistix_id'), 'flogistix_id').reverse()}
        scroll={{ x: 'auto' }}
      />
    </div>
  );
};

export default RecentWorkOrders;
