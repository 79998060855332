import React from 'react';
import { useTimer } from '../hooks';

const WorkOrderTimer = (props) => {
  const { timestamp } = props;
  const { formatTime, total_time } = useTimer(0, timestamp);

  return (
    <span className="work-order--timer">{formatTime(total_time)}</span>
  );
};

export default WorkOrderTimer;
