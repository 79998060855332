import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';

const InlineDiv = styled.div`
  display: inline;
`;

const DashIcon = styled.span`
  margin-left: 5px;
`;

const DashValue = styled.span`
  float: right;
  margin-right: 5px;
`;

const WeekScoreboard = (props) => {
  const { title, value, type } = props;
  const hours = type === 'hours' ? Math.floor(value / 60) : 0;
  const minutes = type === 'hours' ? value % 60 : 0;
  return (
    <InlineDiv>
      <DashIcon>
        <Space>
          <props.icon />
          {title}
        </Space>
      </DashIcon>
      <DashValue>{type === 'hours' ? (`${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`) : value}</DashValue>
      <hr />
    </InlineDiv>
  );
};

export default WeekScoreboard;
