export const pump_service_type_options = [
  { label: 'Rental', value: 'rental' },
  { label: 'Customer Pay', value: 'customer_pay' },
  { label: 'Warranty', value: 'warranty' },
];

export const pump_pm_schedule_options = [
  { label: '3 Months', value: 90 },
  { label: '6 Months', value: 180 },
];

export const pump_pm_checklist_fields = [
  { label: 'Oil Changed in Pump?', name: 'oil_changed_in_pump', note_type_id: 14 },
  { label: 'Oil Changed in Reducer?', name: 'oil_changed_in_reducer', note_type_id: 18 },
  { label: 'Oil Sample Collected?', name: 'oil_sample_collected', note_type_id: 21 },
  { label: 'Oil Level Switch Operational?', name: 'oil_level_switch_operational', note_type_id: 15 },
  { label: 'Oil Pressure Switch Operational?', name: 'oil_pressure_switch_operational', note_type_id: 19 },
  { label: 'Belts & Sheave?', name: 'belts_sheave', note_type_id: 12 },
  { label: 'Discharge Pressure Switch Operational?', name: 'discharge_pressure_switch_operational', note_type_id: 16 },
  { label: 'Suction Switch Operational?', name: 'suction_switch_operational', note_type_id: 20 },
  { label: 'Check Motor/Engine for Noise & Vibrations?', name: 'check_motor_noise', note_type_id: 22 },
  { label: 'Plunger Lubricator Operational?', name: 'plunger_lubricator_operational', note_type_id: 17 },
  { label: 'Dampners Charged?', name: 'dampners_charged', note_type_id: 13 },
  { label: 'Check Mounting Bolts, etc?', name: 'check_mounting_bolts', note_type_id: 23 },
];

export const pump_pm_fluid_fields = [
  {
    label: 'Plungers/Packing',
    name: 'plungers_packing',
    note_type_id: 24,
    options: [{ label: 'OK', value: 'ok' }, { label: 'Replace', value: 'replace' }],
  },
  {
    label: 'Suction/Discharge Valves',
    name: 'suction_discharge_valves',
    note_type_id: 25,
    options: [{ label: 'OK', value: 'ok' }, { label: 'Replace', value: 'replace' }, { label: 'Rebuilt', value: 'rebuild' }],
  },
];

export const pump_pm_power_fields = [
  {
    label: 'Crankshaft Journals',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Worn', value: 'worn' }],
    name: 'crankshaft_journals',
    note_type_id: 26,
  },
  {
    label: 'Connecting Rod Bolts, etc?',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Worn', value: 'worn' }],
    name: 'connecting_rod_bolts',
    note_type_id: 31,
  },
  {
    label: 'Crank Bearings/Bushings',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Replace', value: 'replace' }],
    name: 'crank_bearing_bushings',
    note_type_id: 27,
  },
  {
    label: 'Extension Rods',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Replace', value: 'replace' }],
    name: 'extension_rods',
    note_type_id: 32,
  },
  {
    label: 'Crosshead Pin',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Loose', value: 'loose' }],
    name: 'crosshead_pin',
    note_type_id: 28,
  },
  {
    label: 'Bearings Main',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Worn', value: 'worn' }, { label: 'Adjusted', value: 'adjusted' }],
    name: 'bearings_main',
    note_type_id: 33,
  },
  {
    label: 'Crosshead Pin Bushing',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Loose', value: 'loose' }],
    name: 'crosshead_pin_bushing',
    note_type_id: 29,
  },
  {
    label: 'Bearings Center',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Worn', value: 'worn' }],
    name: 'bearings_center',
    note_type_id: 34,
  },
  {
    label: 'Crosshead',
    options: [{ label: 'OK', value: 'ok' }, { label: 'See Comments', value: 'see_comments' }],
    name: 'crosshead',
    note_type_id: 30,
  },
  {
    label: 'Pinion Bearing',
    options: [{ label: 'OK', value: 'ok' }, { label: 'Worn', value: 'worn' }],
    name: 'pinion_bearing',
    note_type_id: 35,
  },
];
