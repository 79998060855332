import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { message } from 'antd';
import { flatten } from 'ramda';
import { useSelector } from 'react-redux';
import api from '.';
import db, { resourceList, recentDataList } from '../db';
import { StyledModal, SyncProgress } from '../components/shared';
import { addLog } from '../db/api';
import { displayInternetRequiredMessage } from '../lib/health';

const { Provider, Consumer } = React.createContext();

const { localStorage } = window;

const total = resourceList.length + recentDataList.length;

const SyncProvider = (props) => {
  const [syncing, setSyncing] = useState(false);
  const [sync_type, setSyncType] = useState(null);
  const [completed, setCompleted] = useState(0);
  const [time_completed, setTimeCompleted] = useState(parseInt(localStorage.getItem('APP_LOAD_SYNC_TIME_COMPLETED'), 10));

  const { isOnline } = useSelector((state) => state.apiHealth);

  const storeResource = (list, database, table, data) => {
    const timestamp = moment().unix();
    return Promise.all([
      database[table].clear(),
      database[table].bulkPut(data),
      database[list]
        .where('name')
        .equals(table)
        .modify({ lastUpdated: timestamp }),
    ]);
  };

  const appLoadSync = async (type) => {
    if (!isOnline) {
      if (type === 'Manual' || displayInternetRequiredMessage()) message.error('Internet is required to sync data!', 5);
      // this needs to post a notification pop up with the below message
      addLog('Internet required for application start.');
      return null;
    }

    setSyncType(type);

    let finished = 0;
    setSyncing(true);
    addLog('Application load sync is starting...');
    const sync_proms = resourceList.map((resource) => new Promise((resolve, reject) => {
      console.log(`${resource.name}: syncing resource...`);
      api.get(`/next/${resource.endpoint}`).then((res) => {
        const { results } = res.data;
        storeResource('resourceList', db, resource.name, results)
          .then(() => {
            console.log(`${resource.name}: finished syncing...`);
            finished++;
            setCompleted(parseInt((finished / total) * 100));
            resolve({ success: true });
          })
          .catch((e) => {
            console.error('Dexie Error on table: ', resource.name);
            reject(e);
          });
      });
    }));

    const recent_proms = recentDataList.map((resource) => new Promise((resolve, reject) => {
      console.log(`${resource.name}: syncing resource...`);
      api.get(`/next/${resource.endpoint}`).then((res) => {
        const { results } = res.data;
        storeResource('recentDataList', db, resource.name, results)
          .then(() => {
            console.log(`${resource.name}: finished syncing...`);
            finished++;
            setCompleted(parseInt((finished / total) * 100));
            resolve({ success: true });
          })
          .catch((e) => {
            console.error('Dexie Error on table: ', resource.name);
            reject(e);
          });
      });
    }));

    return Promise.all(flatten([
      sync_proms,
      recent_proms,
    ])).then(() => {
      addLog('Resource sync complete.');
      setSyncing(false);
      setCompleted(0);
      const completed_time = new Date().getTime();
      localStorage.setItem('APP_LOAD_SYNC_TIME_COMPLETED', completed_time);
      setTimeCompleted(completed_time);
    });
  };

  const getSecondsSinceLastAppLoadSync = () => {
    const lastAppLoadSync = time_completed;
    const now = new Date().getTime();
    return now - lastAppLoadSync;
  };

  useEffect(() => {
    const last_sync = parseInt(localStorage.getItem('APP_LOAD_SYNC_TIME_COMPLETED'), 10);
    const last_sync_timestamp = last_sync || 0;
    setTimeCompleted(last_sync_timestamp);
  }, []);

  return (
    <Provider
      value={{
        appLoadSync,
        syncing,
        total,
        completed,
        time_completed,
        getSecondsSinceLastAppLoadSync,
      }}
    >
      <>
        {props.children}
        <StyledModal
          open={syncing}
          maskClosable={false}
          footer={null}
          closable={false}
          title={`${(sync_type ? `${sync_type} ` : '')}Sync Progress`}
          body={<SyncProgress />}
        />
      </>
    </Provider>
  );
};

export default SyncProvider;
export function syncConsumer(WrappedComp) {
  return (props) => (
    <Consumer>
      { (ctx) => <WrappedComp {...ctx} {...props} /> }
    </Consumer>
  );
}
