import Dexie from 'dexie';

// define the settings for our instance of Dexie
export const dbName = 'floworks';
export const dbVersion = '1.02';
export const dbStores = {
  calltypes: 'id, name, enabled',
  calltype_notetypes: 'client_id, call_type_id, note_type_id, call_type, note_type',
  calltype_workordersections: 'client_id, call_type_id, workorder_section_id, call_type, workorder_section',
  calltype_laborcodes: 'id, call_type_id, labor_code_id',
  current_workorder: 'id, wo_id',
  customer_locations: 'id, customer_id, address_label, city, country, state',
  customers: 'id,name',
  fleet_vehicles: 'id, make, model',
  inventory_transfers: 'localId,date_submitted,transfer_from_mechanic_id,transfer_to_mechanic_id,synced',
  jsas: 'localId',
  laborcodes: 'id,name,code,category_id,category,description',
  leases: 'id, customer_id, address_label, county, state',
  mechanics: 'user_id,first_name,last_name',
  parts: 'id,number,description',
  pipelines: 'id,name',
  pmtemplates: 'id,name',
  probabilities: '++, part_group_id, labor_code_id, proba',
  pump_pm_part_statuses: 'value, label',
  reasons: 'id',
  pumps: 'id',
  resourceList: 'name,lastUpdated,maximumAge',
  recentDataList: 'name,lastUpdated,maximumAge',
  site_logs: '++id, timestamp, display, dismissed',
  site_settings: 'localId',
  timeestimates: '++, factor_type, factor_id, factor_weight, trained_at',
  trucks: 'id,name,company_id',
  units: 'id,number, assigned_mechanic_id',
  unitclassifications: 'id, name',
  unit_transfers: 'localId,date_submitted,synced',
  user_mechanic: 'id',
  user_settings: 'localId',
  vehicle_inspections: 'localId, date_submitted, synced',
  vehicle_inspection_fields: 'id, key, label',
  warehouses: 'id,truck_id,description',
  workorders: 'localId, jsa_id, start_time, end_time, created_at, asset_id, complete, synced',
  recent_inventory_transfers: 'id, date_submitted',
  recent_unit_transfers: 'id, date_submitted',
  recent_vehicle_inspections: 'id, date_submitted',
  recent_workorders: 'id,start_time,end_time',
  recent_workorder_pms: 'workorder_id',
  recent_workorder_notes: 'id,workorder_id,type_id',
  recent_workorder_labor_codes: 'id,workorder_id,labor_code_id',
  recent_workorder_parts: 'id,workorder_id,labor_code_id,part_id',
  recent_workorder_emissions: 'workorder_id',
  recent_workorder_readings: 'workorder_id',
  // "itransfers/recent": "id",
  // "workorders/recent":"id,asset_id,customer_id,mechanic_id,start_time,end_time",
  // "workorders/recent/pms": "workorder_id",
  // "workorders/recent/notes": "id,workorder_id,type_id",
  // "workorders/recent/labor": "id,workorder_id,labor_code_id",
  // "workorders/recent/parts": "id,workorder_id,labor_code_id,part_id",
  // "workorders/recent/emissions": "workorder_id",
  // "workorders/recent/readings": "workorder_id",
  auth: 'name,value',
};

// we still need to research the maximum age for each of these resources
export const resourceList = [
  {
    name: 'calltypes',
    endpoint: 'calltypes',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['name'],
  },
  {
    name: 'calltype_notetypes',
    endpoint: 'calltype_notetypes',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
  },
  {
    name: 'calltype_workordersections',
    endpoint: 'calltype_workordersections',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
  },
  {
    name: 'calltype_laborcodes',
    endpoint: 'calltype_laborcodes',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
  },
  {
    name: 'customers',
    endpoint: 'customers',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['name'],
  },
  {
    name: 'laborcodes',
    endpoint: 'laborcodes',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['name'],
  },
  {
    name: 'leases',
    endpoint: 'customer_locations',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['address_label'],
  },
  {
    name: 'parts',
    endpoint: 'parts',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
    labelCols: ['number', 'description'],
  },
  {
    name: 'pipelines',
    endpoint: 'pipelines',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['name'],
  },
  {
    name: 'pump_pm_part_statuses',
    endpoint: 'pump_pm_part_statuses',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['label'],
  },
  {
    name: 'trucks',
    endpoint: 'trucks',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['number'],
  },
  {
    name: 'units',
    endpoint: 'units',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
    labelCols: ['number', 'type'],
  },
  {
    name: 'customer_locations',
    endpoint: 'customer_locations',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
    labelCols: ['address_label'],
  },
  {
    name: 'warehouses',
    endpoint: 'warehouses',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['description'],
  },
  {
    name: 'mechanics',
    endpoint: 'mechanics',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['first_name', 'last_name'],
  },
  {
    name: 'pmtemplates',
    endpoint: 'pmtemplates',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['name'],
  },
  {
    name: 'probabilities',
    endpoint: 'probabilities',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
  },
  {
    name: 'timeestimates',
    endpoint: 'timeestimates',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
  },
  {
    name: 'unitclassifications',
    endpoint: 'unitclassifications',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['name'],
  },
  {
    name: 'reasons',
    endpoint: 'reasons',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['reason'],
  },
  {
    name: 'pumps',
    endpoint: 'pumps',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
    labelCols: ['pump'],
  },
  {
    name: 'vehicle_inspection_fields',
    endpoint: 'vehicleinspections/fields',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
  },
  {
    name: 'fleet_vehicles',
    endpoint: 'vehicleinspections/vehicles',
    lastUpdated: null,
    maximumAge: 7 * 24 * 60 * 60,
  },
  // NOTE: do not add work orders, inventory transfers, unit transfers, or vehicle inspections
  //   or else data loss can occur
  // Just run manual db gets if respective data is needed (reference RecentWorkorders)
];

export const recentDataList = [
  {
    name: 'recent_inventory_transfers',
    endpoint: 'inventorytransfers/recent',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
  {
    name: 'recent_unit_transfers',
    endpoint: 'unittransfers/recent',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
  {
    name: 'recent_vehicle_inspections',
    endpoint: 'vehicleinspections/recent',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
  // {
  //   name: 'recent_workorders',
  //   endpoint: 'workorders/recent',
  //   lastUpdated: null,
  //   maximumAge: 24 * 60 * 60,
  // },
  {
    name: 'recent_workorder_pms',
    endpoint: 'workorders/recent/pms',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
  {
    name: 'recent_workorder_notes',
    endpoint: 'workorders/recent/notes',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
  {
    name: 'recent_workorder_labor_codes',
    endpoint: 'workorders/recent/labor',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
  {
    name: 'recent_workorder_parts',
    endpoint: 'workorders/recent/parts',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
  {
    name: 'recent_workorder_emissions',
    endpoint: 'workorders/recent/emissions',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
  {
    name: 'recent_workorder_readings',
    endpoint: 'workorders/recent/readings',
    lastUpdated: null,
    maximumAge: 24 * 60 * 60,
  },
];

// create our instance of dexie
const db = new Dexie(dbName);

// attach our version and store settings
db.version(dbVersion).stores(dbStores);

// database upgrades go here

// Adding the resource list with lastUpdated for resource sync
db.resourceList.bulkAdd(resourceList).catch((e) => {
  // we'll just eat this error
});

// Adding the recent data list with lastUpdated for recent data sync
db.recentDataList.bulkAdd(recentDataList).catch((e) => {
  // we'll just eat this error
});

export default db;
