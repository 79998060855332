import api from '.';

export const getWorkOrders = async (filters) => {
  const res = await api.get('/next/workorders', { params: filters });
  return res.data.results;
};

export const getWorkOrder = async (filters) => {
  const res = await api.get('/next/workorder', { params: filters });
  return res.data.results[0];
};
