import uuid from 'uuid/v4';
import { notification as Notification } from 'antd';
import db from '.';

const table = db.site_logs;

export const siteLogsLiveQuery = () => table.toArray();
export const logsLiveQuery = () => table.filter((r) => r.display === false).toArray();
export const notificationsLiveQuery = () => (
  table.filter((r) => r.display === true && r.dismissed === false).toArray()
);

const displayNotification = (notification) => {
  // no default case needed since type is defaulted to 'info' in addSiteLog
  // eslint-disable-next-line default-case
  switch (notification.type) {
    case 'error': return Notification.error(notification);
    case 'success': return Notification.success(notification);
    case 'info': return Notification.info(notification);
  }
};

export const getSiteLog = async () => {
  const curr_settings = await table.toArray();
  return curr_settings[0];
};

export const getSiteLogs = async () => table.toArray();

export const addSiteLog = async (data) => {
  // if the key is provided, make sure there isn't one in the database already
  if (data.key) {
    const logs = await getSiteLogs();
    const log_exists = logs.filter((l) => l.key === data.key).length > 0;
    if (log_exists) return;
  }

  const log = {
    key: uuid(),
    timestamp: Date.now(),
    display: false,
    dismissed: false,
    ...data,
    type: data.type || 'info',
  };

  if (log.display) displayNotification(log);

  await table.add(log);
  return log;
};

export const addSiteNotification = async (data) => {
  const notification = {
    display: true,
    dismissed: false,
    ...data,
  };
  return addSiteLog(notification);
};

// simpler version so you don't have to pass a huge message body every call
export const addNotification = async (message, type) => {
  const notification = { message, type };
  return addSiteNotification(notification);
};

// simpler version so you don't have to pass a huge message body every call
export const addLog = async (message, type) => {
  const notification = { message, type };
  return addSiteLog(notification);
};

export const deleteSiteLog = async (id) => table.delete(id);

export const deleteSiteLogs = async (ids) => {
  // console.log(ids);
  // return;
  table.bulkDelete(ids);
};
