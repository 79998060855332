import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
  Card, Tag, Table,
} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { InfoCircleOutlined } from '@ant-design/icons';
import { pathOr } from 'ramda';
import db from '../../db';
import { PageHeader } from '../shared';
import {
  addKeys, guessSorter, convertCentralToDate, isDefined, removeDuplicateRecords, sortObjectsBy,
} from '../../lib/utils';
import useResources from '../hooks/useResources';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';

const RecentInventoryTransfers = () => {
  const { user_settings } = useContext(UserSettingsContext);

  const [recent_transfers, setRecentTransfers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const { resources, resources_loading } = useResources(['warehouses']);

  const timezone = pathOr('America/Chicago', ['timezone'], user_settings);
  const timezone_label = pathOr('Central Time (US)', ['timezone_label'], user_settings);

  useEffect(() => {
    (async function () {
      const start = moment.tz(timezone).subtract(3, 'months').format();
      const end = moment.tz(timezone).format();
      const completed_transfers = await db.recent_inventory_transfers.toArray();
      const pending_transfers = await db.inventory_transfers
        .where('date_submitted')
        .between(start, end, true, true)
        .toArray();
      const all_transfers = [
        ...completed_transfers,
        ...pending_transfers,
      ];
      const transfers = removeDuplicateRecords(all_transfers, ['flogistix_id']);
      setRecentTransfers(addKeys(transfers));
    })();
  }, []);

  useEffect(() => {
    if (resources_loading) return;

    if (resources.warehouses) {
      const warehouse_list = resources.warehouses.filter((warehouse) => (
        warehouse.description !== ''
      )).reduce((temp, warehouse) => {
        const description = warehouse.description
          + (warehouse.user_id ? ` - ${warehouse.first_name} ${warehouse.last_name}` : '');

        return {
          ...temp,
          [warehouse.id]: description,
        };
      }, {});
      setWarehouses(warehouse_list);
    }
  }, [resources_loading, resources]);

  const cols = [
    {
      title: `Date Submitted (${timezone_label})`,
      key: 'date_submitted',
      render: (date) => moment(convertCentralToDate(date, timezone)).format('L LTS'),
    },
    {
      title: 'Transfer ID',
      key: 'flogistix_id',
    },
    {
      title: 'Transfer From',
      key: 'transfer_from',
      render: (warehouse_id) => warehouses[warehouse_id],
    },
    {
      title: 'Transfer To',
      key: 'transfer_to',
      render: (warehouse_id) => warehouses[warehouse_id],
    },
    {
      title: 'Uploaded',
      key: 'synced',
      width: 100,
      // recentITs don't have this column, default to true since they're from pg (uploaded)
      render: (v) => ((isDefined(v) ? v : true) ? 'Yes' : 'No'),
    },
  ];

  const recent_transfers_cols = cols.map((col) => (
    {
      ...col,
      dataIndex: col.key,
      sorter: (a, b) => guessSorter(a, b, col.key),
    }
  ));

  const expandedRowRender = (row) => {
    const data = addKeys(row.parts, false);
    const columns = [
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Part Number',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Part Description',
        dataIndex: 'description',
        key: 'description',
      },
    ];
    return (
      <Card>
        <Table columns={columns} dataSource={data} pagination={false} />
      </Card>
    );
  };

  return (
    <div>
      <PageHeader
        title="Recent Inventory Transfers"
        extra={(
          <Tag className='float-right' color="processing" icon={<InfoCircleOutlined />}>
            View detailed part info by clicking on the '+'
          </Tag>
        )}
      />
      <Table
        loading={resources_loading}
        dataSource={sortObjectsBy(recent_transfers, 'date_submitted').reverse()}
        columns={recent_transfers_cols}
        expandable={{ expandedRowRender }}
        pagination={{ pageSize: 10 }}
        scroll={{ y: 720 }}
      />
    </div>
  );
};

export default withRouter(RecentInventoryTransfers);
