import { configureStore } from '@reduxjs/toolkit';
import apiHealthReducer from './slices/api-health-slice';
import callTypesReducer from './slices/callTypeSlice';
import userSettingsReducer from './slices/user-settings-slice';

const store = configureStore({
  reducer: {
    apiHealth: apiHealthReducer,
    callTypes: callTypesReducer,
    userSettings: userSettingsReducer,
  },
});

export default store;
