import React, { useContext, useState } from 'react';
import {
  Avatar, Col, Dropdown, Menu, Row, Space, Button, Switch, message, Badge,
} from 'antd';
import { pathOr } from 'ramda';
import {
  SettingOutlined, QuestionCircleOutlined, FileExclamationOutlined,
  FileDoneOutlined, SyncOutlined, NotificationOutlined,
} from '@ant-design/icons';
import { useLiveQuery } from 'dexie-react-hooks';
import HelpAndInfoModal from '../Modals/HelpAndInfoModal';
import PendingUploadsModal from '../Modals/PendingUploadsModal';
import NotificationDrawer from '../NotificationDrawer';
import { AuthContext } from '../../hoc/auth/Provider';
import { StyledModal, Settings } from '../shared';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { workerConsumer } from '../../api/workers/WorkerProvider';
import {
  pendingFilesLiveQueryWO,
  pendingFilesLiveQueryUT,
  pendingFilesLiveQueryIT,
  pendingFilesLiveQueryVI,
  notificationsLiveQuery,
} from '../../db/live_queries';

const { confirm } = window;

const TopBar = (props) => {
  const { logo, appLoadSync } = props;

  const { updateUserSettings, user_settings, user_settings_loading } = useContext(UserSettingsContext);
  const { user, logout } = useContext(AuthContext);
  const { name, picture } = user;

  const pending_work_orders = useLiveQuery(() => pendingFilesLiveQueryWO()) || [];
  const pending_unit_transfers = useLiveQuery(() => pendingFilesLiveQueryUT()) || [];
  const pending_inventory_transfers = useLiveQuery(() => pendingFilesLiveQueryIT()) || [];
  const pending_vehicle_inspections = useLiveQuery(() => pendingFilesLiveQueryVI()) || [];
  const notifications = useLiveQuery(() => notificationsLiveQuery()) || [];

  const [dropdown_visible, setDropdownVisible] = useState(false);
  const [help_modal_visible, setHelpModalVisible] = useState(false);
  const [settings_modal_visible, setSettingsModalVisible] = useState(false);
  const [pending_modal_visible, setPendingModalVisible] = useState(false);
  const [drawer_visible, setDrawerVisible] = useState(false);

  const pending_items = [
    ...pending_work_orders,
    ...pending_unit_transfers,
    ...pending_inventory_transfers,
    ...pending_vehicle_inspections,
  ];

  // only look at error notifications for the display changes
  const notification_count = notifications.filter((n) => n.type === 'error').length;
  const pending_count = pending_items.length;

  const menu = (
    <Menu>
      <Menu.Item onClick={logout} key="1">
        Logout
      </Menu.Item>
    </Menu>
  );

  const updateSettings = (settings) => {
    setSettingsModalVisible(false);
    updateUserSettings(settings);
  };

  const onAutoSyncChange = async (checked) => {
    message.destroy();
    const msg = checked
      ? 'AutoSync is now enabled. Your computer will check to sync data every 3 hours if online'
      : 'AutoSync is now disabled. The Daily Sync will still run every morning if online';
    message.info(msg, 10);

    const new_settings = {
      ...user_settings,
      auto_sync_enabled: checked,
    };
    await updateUserSettings(new_settings);
  };

  const onSyncClick = () => {
    const msg = `This will synchronize ALL resources to your local machine for offline use. 
    Do you wish to continue?`;
    if (!confirm(msg)) return;

    appLoadSync('Manual');
  };

  // wait for user_settings so we can properly initialize the autosync switch with a default
  if (user_settings_loading) return <></>;
  return (
    <Row>
      <Col span={8}>
        <img id="floworks_logo" src={logo} alt="Flogistix Logo" style={{ width: '100%', maxWidth: '250px', marginLeft: '-26px' }} />
      </Col>
      <Col flex="auto">
        <Row justify="end" gutter={8}>
          <Col>
            <Badge count={notification_count} style={{ zIndex: 1 }} className="badge">
              <Button
                ghost
                type="primary"
                title="Notifications"
                danger={notification_count > 0}
                onClick={() => setDrawerVisible(true)}
              >
                <NotificationOutlined />
              </Button>
            </Badge>
          </Col>
          <Col>
            <Button
              ghost
              type="primary"
              title="Help & Information"
              onClick={() => setHelpModalVisible(true)}
            >
              <QuestionCircleOutlined />
            </Button>
          </Col>
          <Col>
            <Badge count={pending_count} style={{ zIndex: 1 }} className="badge">
              <Button
                ghost
                type="primary"
                title="Pending Uploads"
                danger={pending_count > 0}
                onClick={() => setPendingModalVisible(true)}
              >
                {pending_count > 0
                  ? <FileExclamationOutlined />
                  : <FileDoneOutlined />}
              </Button>
            </Badge>
          </Col>
          <Col>
            <Button
              ghost
              type="primary"
              title="Settings"
              onClick={() => setSettingsModalVisible(true)}
            >
              <SettingOutlined />
            </Button>
          </Col>
          <Col>
            <Button
              ghost
              type="primary"
              title="Sync Resources"
              onClick={onSyncClick}
            >
              <SyncOutlined />
            </Button>
          </Col>

          <Col>
            <Switch
              title="Turn on or off the AutoSync that runs every 3 hours"
              style={{ marginLeft: '15px' }} // not worth a full col offset, just need a bit of space
              checkedChildren="AutoSync ON"
              unCheckedChildren="AutoSync OFF"
              defaultChecked={pathOr(true, ['auto_sync_enabled'], user_settings)}
              onChange={onAutoSyncChange}
            />
          </Col>

          <Col style={{ paddingLeft: '3rem' }}>
            <Row justify="end">
              <Dropdown
                overlay={menu}
                onVisibleChange={setDropdownVisible}
                visible={dropdown_visible}
                trigger="click"
              >
                <Space type="horizontal">
                  <Avatar size={30} src={picture} alt={name} />

                  <span
                    className="no-wrap"
                    style={{ whiteSpace: 'nowrap', color: 'rgba(255, 255, 255, 0.65)' }}
                  >
                    {name}
                  </span>
                </Space>
              </Dropdown>
            </Row>
          </Col>
        </Row>
      </Col>
      <StyledModal
        open={settings_modal_visible}
        title="Settings"
        footer={null}
        hideModal={() => setSettingsModalVisible(false)}
        body={(
          <Settings
            save={updateSettings}
            cancel={() => setSettingsModalVisible(false)}
          />
        )}
      />
      <HelpAndInfoModal
        visible={help_modal_visible}
        onCancel={() => setHelpModalVisible(false)}
      />
      <PendingUploadsModal
        pending_items={pending_items}
        visible={pending_modal_visible}
        onCancel={() => setPendingModalVisible(false)}
      />
      <NotificationDrawer visible={drawer_visible} setDrawerVisible={setDrawerVisible} />
    </Row>
  );
};

export default workerConsumer(TopBar);
