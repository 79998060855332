import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const AppSpinner = (props) => {
  const { text = 'Loading' } = props;
  const [loading_text, setLoadingText] = useState(text);

  const animateText = (msg) => {
    const [word, ...curr] = msg.split('.');
    const ellipses = curr.length < 5 ? Array(curr.length + 1).fill('.') : [];
    const output = [word, ...ellipses];
    setLoadingText(output.join(''));
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      animateText(loading_text);
    }, 500);
    return () => clearTimeout(interval);
  }, [loading_text]);

  const loading_icon = <LoadingOutlined style={{ fontSize: 72, color: '#F05C25' }} spin />;

  return (
    <div
      style={{
        position: 'fixed',
        padding: 0,
        margin: 0,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#001529',
      }}
    >
      <div style={{ textAlign: 'center', marginTop: '25%' }}>
        <Spin indicator={loading_icon} />
        <div style={{ fontSize: '2em', color: '#fff' }}>
          {loading_text}
        </div>
      </div>
    </div>
  );
};

export default AppSpinner;
