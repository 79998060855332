import React, { useState, useEffect } from 'react';
import {
  Row, Col, Modal, Button, Input, Table,
} from 'antd';
import { addKeys } from '../../lib/utils';
import filterPartsListRemovingHyphen from '../../lib/helpers/search';

const AddPartModal = ({
  addPart, parts, selected_parts, closeModal, ...props
}) => {
  const [filter, setFilter] = useState(null);
  const [filteredPartsList, setFilteredParts] = useState([]);
  const [available_parts, setAvailableParts] = useState([]);

  const submitPart = (part) => {
    const initialized_part = {
      ...part,
      quantity: 1,
    };
    setFilter(null);
    addPart(initialized_part);
    closeModal();
  };

  const onCancel = () => {
    setFilter(null);
    closeModal();
  };

  useEffect(() => {
    if (filter === null) return false;

    const timeout_id = setTimeout(() => {
      setFilteredParts(filterPartsListRemovingHyphen(filter, available_parts));
    }, 500);
    return () => clearTimeout(timeout_id);
  }, [filter]);

  useEffect(() => {
    const used_part_ids = selected_parts.map((p) => p.id);
    const available = parts.filter((p) => !used_part_ids.includes(p.id));

    setAvailableParts(available);
    setFilteredParts(available);
  }, [selected_parts]);

  const part_cols = [
    {
      title: 'Part Number',
      dataIndex: 'number',
      key: 'number',
      width: '140px',
    },
    {
      title: 'Part Desc',
      dataIndex: 'description',
      key: 'description',
    },
    {
      render: (part) => (
        <Button onClick={() => submitPart(part)}>
          Add Part
        </Button>
      ),
      key: 'transfer_button',
    },
  ];

  return (
    <Modal
      {...props}
      width="75%"
      title="Add Part"
      destroyOnClose
      onCancel={onCancel}
      footer={null}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Type to Filter Part Number or Description"
          />
        </Col>
        <Table
          dataSource={addKeys(filteredPartsList)}
          columns={part_cols}
          style={{ width: '100%' }}
        />
      </Row>
    </Modal>
  );
};

export default AddPartModal;
