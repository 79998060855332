// React Imports
import React, { useEffect, useState, useContext } from 'react';

// Third Party Imports
import * as R from 'ramda';
import 'moment-timezone';
import { Form, notification, Radio } from 'antd';

// Constants Imports
import STATUS_TO_OWNERSHIP from '../../config/constants/ownership_status_enum';

// Component Imports
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { StyledModal, Select, CheckboxGroup } from '../shared';
import { getUnitInfo, h2sLocation } from '../utils/unit';

import { useCallTypes, useWorkOrderHelpers } from '../hooks';

import {
  getUnits,
} from '../../db/api';

const NewWOModal = (props) => {
  const {
    step,
    setStep,
    hideModal,
    syncing,
    history,
  } = props;

  const { user_mechanic } = useContext(UserSettingsContext);

  const {
    options: call_type_options, adjustCallTypes, getCallTypeUnitOwnershipConcern, getCallTypeIdByName,
  } = useCallTypes();
  const { openWorkOrder } = useWorkOrderHelpers({ history });

  const [selected_call_types, setSelectedCallTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [selected_unit, setUnit] = useState({});
  const [ownership_type, setOwnershipType] = useState('Rental');
  const [skipOwnershipType, setOwnershipTypeNeed] = useState(false);
  const [unitsByOwnership, setUnitsByOwnership] = useState([]);

  const clearModal = () => {
    setSelectedCallTypes([]);
    setUnit({});
    hideModal();
  };

  const loadResources = () => {
    getUnits()
      .then((resUnits) => {
        setUnits(resUnits);
      });
  };

  const validateWO = () => {
    const unit_info = getUnitInfo(units, selected_unit.id);
    if (Object.values(unit_info).length === 0) {
      notification.error({
        message: 'Work Order Creation Error',
        key: 'unit_error',
        description: 'You must select a unit to work on. Please select a unit and try again.',
        duration: 8,
      });
      return;
    }
    if (!user_mechanic.h2s_certified && unit_info.h2s_location) {
      notification.error({
        message: 'Work Order Creation Error',
        key: 'h2s_error',
        description: `You are attempting to create a work Order for an H2S site, but are not H2S certified. 
          Please select another unit or contact your Area Manager for further assistance.`,
        duration: 8,
      });
      return;
    }

    notification.destroy();

    const unit = selected_unit;
    clearModal();
    openWorkOrder(unit, selected_call_types);
  };

  const needUnitSelection = (call_types) => (call_types.some((selectedCT) => {
    const selectedOption = call_type_options.filter((option) => option.value === selectedCT).at(0);

    if (step !== 'unit' && selectedOption.value !== getCallTypeIdByName('Indirect')) return setStep('unit');
    return validateWO();
  }));

  const onCallTypeChange = (checked_call_types) => {
    const call_type_to_add = checked_call_types.find((ct) => !selected_call_types.includes(ct));

    const new_call_types = call_type_to_add
      ? adjustCallTypes(selected_call_types, call_type_to_add)
      : checked_call_types;

    setSelectedCallTypes(new_call_types);
    setOwnershipTypeNeed(new_call_types.some((callTypeId) => getCallTypeUnitOwnershipConcern(callTypeId)));
  };

  useEffect(() => {
    const ownership_status = Object.keys(STATUS_TO_OWNERSHIP)
      .filter((key) => STATUS_TO_OWNERSHIP[key] === ownership_type);

    const units_reduced = units.reduce((acc, curr) => {
      let unit_options = [...acc];

      if (!skipOwnershipType || ownership_status.includes(curr.status)) {
        unit_options = [...unit_options,
          {
            value: curr.id,
            label: `${curr.number}${(h2sLocation(curr) ? ' (H2S)' : '')}`,
          },
        ];
      }

      return unit_options;
    }, [])
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;

        return 0;
      });

    setUnitsByOwnership(units_reduced);
  }, [units, ownership_type, selected_call_types]);

  useEffect(() => {
    if (syncing === true) return;

    loadResources();
  }, [syncing]);

  const steps = {
    calltype: {
      title: 'Please Select Work Order Type:',
      okText: 'Next',
      cancelText: 'Cancel',
      onCancel: clearModal,
      onOk: () => needUnitSelection(selected_call_types),
      disabled: selected_call_types.length <= 0,
      comp: (
        <CheckboxGroup
          options={call_type_options}
          value={selected_call_types}
          onChange={onCallTypeChange}
        />
      ),
    },
    unit: {
      title: 'I am working on unit number...',
      okText: 'Create',
      onOk: () => needUnitSelection(selected_call_types),
      cancelText: 'Back',
      onCancel: () => { setStep('calltype'); },
      disabled: !selected_unit.id,
      comp: (
        <Form layout="vertical" initialValues={{ ownership: 'Rental' }}>
          <Form.Item label="Unit Ownership" name="ownership" hidden={!skipOwnershipType}>
            <Radio.Group buttonStyle="solid" onChange={(e) => setOwnershipType(e.target.value)}>
              <Radio.Button value="Rental">Rental</Radio.Button>
              <Radio.Button value="Customer Owned">Customer-Owned</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Unit Number" name="unit">
            <Select
              options={unitsByOwnership}
              value={selected_unit.id}
              onChange={(value, option) => {
                setUnit(R.find(R.compose(R.equals(option.value), R.prop('id')), units));
              }}
            />
          </Form.Item>
        </Form>
      ),
    },
  };

  const currentStep = steps[step];
  return (
    <StyledModal
      open={(step === 'calltype' || step === 'ownership' || step === 'unit')}
      title={currentStep?.title}
      okText={currentStep?.okText}
      cancelText={currentStep?.cancelText}
      onOk={currentStep?.onOk}
      onCancel={currentStep?.onCancel}
      okButtonProps={currentStep?.disabled}
      hideModal={clearModal}
      body={
        currentStep?.comp
      }
    />
  );
};

export default NewWOModal;
