import React from 'react';
import { Divider } from 'antd';
import { AntdFormField } from '../shared';

const HazardPlanning = (props) => {
  const {
    jsa,
    updateJSAValue,
    read_only,
  } = props;

  const onChange = async (field, val) => updateJSAValue(jsa.localId, ['hazards_checklists', field], val);

  return (
    <>
      <Divider>What am I going to do about the hazards?</Divider>
      <br />
      <AntdFormField
        type="textarea"
        name="plan"
        label="Describe the Top 3 Hazards and how you plan to work safely"
        defaults={jsa.hazards_checklists}
        rows={3}
        placeholder="Top 3 reasons..."
        onChange={onChange}
        read_only={read_only}
      />
    </>
  );
};

export default HazardPlanning;
