import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Tabs,
} from 'antd';

const { TabPane } = Tabs;

const TabsList = (props) => {
  const {
    tabNames,
    activeTab,
  } = props;

  const scroll = (el, offset) => {
    const position = el.offsetTop - offset;
    window.scroll({
      top: position,
      behavior: 'smooth',
    });
  };

  return (
    <Tabs tabPosition="left">
      { tabNames.map((tab) => (
        <TabPane
          tab={tab.step}
          key={tab.link}
          to={tab.link}
          active={`#${tab.ref}` === activeTab ? 1 : 0}
          scroll={(el) => scroll(el, 180)}
          errors={tab.hasErrors}
        >
          <tab.comp />
        </TabPane>
      ))}
    </Tabs>
  );
};

TabsList.propTypes = {
  tabNames: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({}),
  vertical: PropTypes.bool,
  width: PropTypes.string,
  activeTab: PropTypes.string,
};

export default withRouter(TabsList);
