import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
  Row, Col, Button, Form, Card, Input, Table,
} from 'antd';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import moment from 'moment';
import 'moment-timezone';
import { pathOr } from 'ramda';
import { Select } from '../shared';
import useResources from '../hooks/useResources';
import { createLocalInventoryTransfer } from '../../db/inventoryTransfers';
import { workerConsumer } from '../../api/workers/WorkerProvider';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { AuthContext } from '../../hoc/auth/Provider';
import { convertDateToCentral } from '../../lib/utils';
import filterPartsListRemovingHyphen from '../../lib/helpers/search';
import { getUserMechanicRecord, addNotification } from '../../db/api';

const token = localStorage.getItem('access_token');

const QuantityInput = styled(Input)`
  width: ${(props) => props.width};
`;

const InventoryTransfer = (props) => {
  const { history, worker } = props;

  const { user_settings } = useContext(UserSettingsContext);
  const { is_online } = useContext(AuthContext);

  const [parts_list, setPartsList] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [mechanic, setMechanic] = useState([]);

  const [filter, setFilter] = useState(null);
  const [filtered_part_list, setFilteredList] = useState([]);
  const [part_quantities, setPartQuantities] = useState({});
  const [form_data, setFormData] = useState({
    transfer_from: null,
    transfer_to: null,
    selected_parts: [],
  });

  const { resources, resources_loading } = useResources(['parts', 'warehouses', 'mechanics']);

  const timezone = pathOr('America/Chicago', ['timezone'], user_settings);

  const validateSelectedParts = async () => {
    if (form_data.selected_parts.length > 0) return;

    throw new Error('Select at least one part to transfer');
  };

  const validateInventoryUnits = async () => {
    if (!form_data.transfer_from || !form_data.transfer_to) return;
    if (form_data.transfer_from !== form_data.transfer_to) return;

    throw new Error('Inventory Units must not match');
  };

  const addPart = (part) => {
    setFormData((state) => (
      {
        ...state,
        selected_parts: [
          ...state.selected_parts,
          {
            ...part,
            key: part.description === 'UNLISTED' ? uuid() : part.key,
            quantity: parseInt(part_quantities[part.id]),
          },
        ],
      }
    ));

    setPartQuantities({
      ...part_quantities,
      [part.id]: 1,
    });

    if (part.description === 'UNLISTED') return;

    const filtered_list = filtered_part_list.filter((filtered_part) => part.id !== filtered_part.id);
    const available_parts = parts_list.filter((filtered_part) => part.id !== filtered_part.id);

    setFilteredList(filtered_list);
    setPartsList(available_parts);
  };

  const removePart = (part) => {
    setFormData((state) => ({
      ...state,
      selected_parts: state.selected_parts.filter((selected_part) => selected_part.id !== part.id),
    }));

    if (part.description === 'UNLISTED') return;

    setFilteredList((state) => [part, ...state]);
    setPartsList((state) => [part, ...state]);
  };

  const onPartQuantityChange = (event, part_id) => {
    setPartQuantities({
      ...part_quantities,
      [part_id]: event.target.value.replace(/\D/g, ''),
    });
  };

  useEffect(() => {
    const timeout_id = setTimeout(() => {
      if (filter === null) return;

      setFilteredList(filterPartsListRemovingHyphen(filter, parts_list));
    }, 500);
    return () => clearTimeout(timeout_id);
  }, [parts_list, filter]);

  useEffect(() => {
    (async function initializeParts() {
      if (resources_loading) return;

      if (resources.parts) {
        const initialized_quantities = resources.parts.reduce((temp, part) => {
          const prev = temp;
          prev[part.id] = 1;

          return prev;
        }, {});

        const parts_with_keys = resources.parts.map((part) => (
          {
            ...part,
            key: part.id,
          }
        ));
        setPartQuantities(initialized_quantities);
        setPartsList(parts_with_keys);
        setFilteredList(parts_with_keys);
      }

      if (resources.warehouses) {
        const warehouse_list = resources.warehouses.filter((warehouse) => (
          warehouse.description !== ''
        )).map((warehouse) => {
          const label = warehouse.description
            + (warehouse.user_id ? ` - ${warehouse.first_name} ${warehouse.last_name}` : '');
          return {
            label,
            value: warehouse.id,
          };
        });
        setWarehouses(warehouse_list);

        if (resources.mechanics) {
          const user_mech = await getUserMechanicRecord();
          const mech = resources.mechanics.find((m) => m.user_id === user_mech.id);
          setMechanic(mech);
        }
      }
    }());
  }, [resources_loading, resources]);

  const transfer_from_columns = [
    {
      title: 'Part Number',
      dataIndex: 'number',
      key: 'number',
      width: '140px',
    },
    {
      title: 'Part Desc',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Qty',
      render: (part) => (
        <QuantityInput
          value={part_quantities[part.id]}
          onChange={(e) => onPartQuantityChange(e, part.id)}
          width="40px"
        />
      ),
    },
    {
      render: (part) => (
        <Button onClick={() => addPart(part)} type="link">
          Add Part
        </Button>
      ),
      key: 'transfer_button',
    },
  ];

  const transfer_to_columns = [
    {
      render: (part) => (
        <Button onClick={() => removePart(part)} danger type="link">
          Remove Part
        </Button>
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Part Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Part Desc',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const onFinish = async () => {
    const id = uuid();
    const version = moment().unix();
    const flogistix_id = `${mechanic.mechanic_number || 'TES1234'}_inventorytransfer_${version}`;

    const inventory_transfer = {
      localId: id,
      id,
      version,
      flogistix_id,
      ...form_data,
      date_submitted: convertDateToCentral(moment(), timezone),
      mechanic_id: mechanic.user_id,
      truck_id: user_settings.truck_id,
      parts: form_data.selected_parts,
      synced: false,
    };
    await createLocalInventoryTransfer(inventory_transfer);

    worker.postMessage({
      type: 'inventory_transfer_upload',
      endpoint: '/next/inventorytransfers',
      token,
      flogistix_id,
      entity_id: inventory_transfer.localId,
      upload_data: { inventory_transfer },
      resource_name: 'inventory_transfers',
    });

    addNotification(`${flogistix_id} has been submitted and is pending upload`);
    if (!is_online) await addNotification('The upload will be attempted once your computer is back online');

    history.push('/');
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <Form layout="vertical" onFinish={onFinish} className="h-100">
        <Row>
          <Col span={12}>
            <h2> Inventory Transfers </h2>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={12}>
            <Card title="Transfer Parts From">
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Inventory Unit"
                    name="From Inventory Unit"
                    rules={[
                      { required: true },
                      { validator: validateInventoryUnits },
                    ]}
                  >
                    <Select
                      options={warehouses}
                      onChange={(value) => setFormData((state) => ({
                        ...state,
                        transfer_from: value,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 12]}>
                <Col span={8}>
                  <Input
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder="Type to Filter Part Number or Description"
                  />
                </Col>
              </Row>
              <Table
                dataSource={filtered_part_list}
                columns={transfer_from_columns}
                size="small"
                scroll={{
                  x: 'auto',
                  y: 'calc(100vh - 520px)',
                }}
              />
            </Card>
          </Col>
          <Col span={11} offset={1}>
            <Card title="Transfer Parts To">
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Inventory Unit"
                    name="To Inventory Unit"
                    rules={[
                      { required: true },
                      { validator: validateInventoryUnits },
                    ]}
                  >
                    <Select
                      options={warehouses}
                      onChange={(value) => setFormData((state) => ({
                        ...state,
                        transfer_to: value,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="Transferred Parts"
                rules={[{ validator: validateSelectedParts }]}
              >
                <Table
                  dataSource={form_data.selected_parts}
                  columns={transfer_to_columns}
                  size="small"
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Button className="bottom-right-btn" size="large" htmlType="submit" type="primary">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default workerConsumer(withRouter(InventoryTransfer));
