import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
  Button, Col, Form, DatePicker, Row, Input,
} from 'antd';
import moment from 'moment';
import { pathOr } from 'ramda';
import uuid from 'uuid/v4';
import UnitTransferForm from './UnitTransferForm';
import { PageHeader, Select } from '../shared';
import useResources from '../hooks/useResources';
import { createLocalUnitTransfer } from '../../db/unitTransfer';
import { workerConsumer } from '../../api/workers/WorkerProvider';
import { convertDateToCentral } from '../../lib/utils';
import { getUserMechanicRecord, addNotification } from '../../db/api';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { AuthContext } from '../../hoc/auth/Provider';

const token = localStorage.getItem('access_token');

const UnitTransfer = (props) => {
  const { history, worker } = props;

  const { user_settings } = useContext(UserSettingsContext);
  const { is_online } = useContext(AuthContext);

  const { resources, resource_loading } = useResources(['units', 'customers', 'customer_locations', 'mechanics']);
  const [form] = Form.useForm();

  const [mechanic, setMechanic] = useState([]);
  const [units, setUnits] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customer_locations, setCustomerLocations] = useState([]);
  const [form_data, setFormData] = useState({});

  const timezone = pathOr('America/Chicago', ['timezone'], user_settings);

  const transferrer_options = [
    { value: 'flogistix', label: 'Flogistix' },
    { value: 'customer', label: 'Customer' },
  ];

  const setFormValue = (key, value) => setFormData((state) => ({
    ...state,
    [key]: value,
  }));

  const validateCustomerLocations = async () => {
    if (!form_data.transfer_from_customer_location_id || !form_data.transfer_to_customer_location_id) return;
    if (form_data.transfer_from_customer_location_id !== form_data.transfer_to_customer_location_id) return;

    throw new Error('Customer locations must not match');
  };

  const unit_transfer_form = {
    customers,
    customer_locations,
    form,
    setFormValue,
    validateCustomerLocations,
  };

  useEffect(() => {
    (async function loadResources() {
      if (resource_loading) return;

      if (resources.customers) setCustomers(resources.customers);
      if (resources.customer_locations) setCustomerLocations(resources.customer_locations);
      if (resources.select_units) setUnits(resources.select_units);

      if (resources.mechanics) {
        const user_mech = await getUserMechanicRecord();
        const mech = resources.mechanics.find((m) => m.user_id === user_mech.id);
        setMechanic(mech);
      }
    }());
  }, [resources, resource_loading]);

  const onFinish = async () => {
    const id = uuid();
    const version = moment().unix();
    const flogistix_id = `${mechanic.mechanic_number || 'TES1234'}_unittransfer_${version}`;

    const unit_transfer = {
      localId: id,
      id,
      version,
      flogistix_id,
      ...form_data,
      date_submitted: convertDateToCentral(moment(), timezone),
      mechanic_id: mechanic.user_id,
      truck_id: user_settings.truck_id,
      synced: false,
    };

    await createLocalUnitTransfer(unit_transfer);

    worker.postMessage({
      type: 'unit_transfer_upload',
      endpoint: '/next/unittransfers',
      token,
      entity_id: unit_transfer.localId,
      flogistix_id,
      upload_data: { unit_transfer },
      resource_name: 'unit_transfers',
    });

    addNotification(`${flogistix_id} has been submitted and is pending upload`);
    if (!is_online) addNotification('The upload will be attempted once your computer is back online');

    history.push('/');
  };

  const formatDate = (date) => {
    setFormValue('transfer_date', convertDateToCentral(date));
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form} className="h-100">
      <PageHeader title="Unit Transfers" />
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item
            name="Unit Number"
            label="Unit Number"
            rules={[{ required: true }]}
          >
            <Select
              onChange={(value) => setFormValue('unit_id', value)}
              placeholder="Select unit"
              options={units}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="Transfer Date"
            label="Transfer Date"
            rules={[{ required: true }]}
          >
            <DatePicker
              onChange={formatDate}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="Transferred By"
            label="Transferred By"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select transferrer"
              onChange={(value) => setFormValue('transferred_by', value)}
              options={transferrer_options}
            />
          </Form.Item>
        </Col>
      </Row>
      <hr style={{ marginBottom: '24px' }} />
      <Row gutter={[0, 16]}>
        <Col span={12}>
          <UnitTransferForm
            title="Original Location:"
            transfer_type="from"
            {...unit_transfer_form}
          />
        </Col>
        <Col span={11} offset={1}>
          <UnitTransferForm
            title="New Location:"
            transfer_type="to"
            {...unit_transfer_form}
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col span={12}>
          <Form.Item label="Transfer Comments">
            <Input.TextArea
              onChange={(e) => setFormValue('transfer_comments', e.target.value)}
              style={{ height: '100px' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button className="bottom-right-btn" size="large" type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};

export default workerConsumer(withRouter(UnitTransfer));
