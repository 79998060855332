import React from 'react';
import {
  List, Card, Avatar, Modal,
} from 'antd';

import AntDesignIcon from '../Icons';

const HelpAndInfoModal = (props) => {
  const it_data = [
    {
      id: 'it_phone',
      icon: <AntDesignIcon icon="PhoneOutlined" />,
      text: <a href="tel: 4055360015">405.536.0015</a>,
    },
    {
      id: 'email_link',
      icon: <AntDesignIcon icon="MailOutlined" />,
      action: 'mailto:support@flogistix.com',
      text: <a href="mailto:support@flogistix.com">support@flogistix.com</a>,
    },
  ];

  const links = [
    {
      id: 'support_url',
      icon: <AntDesignIcon icon="WarningOutlined" />,
      text: (
        <a href="https://support.flogistix.com" target="_blank" rel="noopener noreferrer">
          Flogistix Helpdesk
        </a>
      ),
    },
    {
      id: 'wiki_url',
      icon: <AntDesignIcon icon="InfoCircleOutlined" />,
      text: (
        <a
          href="https://sites.google.com/a/flogistix.com/wiki/field-service?authuser=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fieldservice Wiki
        </a>
      ),
    },
  ];

  const help_card = (data, title) => (
    <Card title={title} size="small" style={{ marginBottom: '15px' }}>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              className="align-center"
              avatar={(
                <Avatar
                  size="large"
                  icon={item.icon}
                  style={{ backgroundColor: '#FFEFE2' }}
                />
              )}
              description={item.text}
            />
          </List.Item>
        )}
      />
    </Card>
  );

  return (
    <Modal footer={null} title="Help & Information" {...props}>
      {help_card(it_data, 'IT Contact')}
      {help_card(links, 'Helpful Links')}
    </Modal>
  );
};

export default HelpAndInfoModal;
