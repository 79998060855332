// React
import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import './assets/styles/base.scss';

// Helpers
import moment from 'moment';
import 'moment-timezone';

// Components
import { Layout } from 'antd';
import { getCallTypes, getUserSettings, getUserMechanic } from './store/actions';
import { Spinner } from './components/shared';
import Dashboard from './components/Dashboard/Dashboard';
import JSA from './components/JSA';
import SideBar from './components/Sidebar';
import TopBar from './components/TopBar';
import WorkOrder from './components/work-order';
import WorkOrderView from './components/work-order/view';
import JsaView from './components/JSA/view';
import VehicleInspection from './components/VehicleInspection';
import NotFound from './components/NotFound';
import InventoryTransfer from './components/InventoryTransfer';
import UnitTransfer from './components/UnitTransfers';
import RecentWorkOrders from './components/recent-work-orders';
import RecentInventoryTransfers from './components/RecentInventoryTransfers';
import RecentUnitTransfers from './components/RecentUnitTransfers';
import AssignedUnits from './components/AssignedUnits';
import WorkOrders from './components/work-orders';
import { NewWOModal } from './components/Modals';
import WorkOrderEditView from './components/work-order/EditView';

// Config
import { AuthContext } from './hoc/auth/Provider';
import MainMenu from './lib/menus';
import { TIMEZONES } from './config/constants/date-time';

// Api and DB Connectors
import { syncConsumer } from './api/resources';
import { workerConsumer } from './api/workers/WorkerProvider';
import { resourceList, recentDataList } from './db';
import { getSiteLogs, deleteSiteLogs } from './db/api';

// Assets
import logo from './assets/floworks-brand-white.png';

const App = (props) => {
  const {
    history,
    location,
    worker,
    appLoadSync,
    getSecondsSinceLastAppLoadSync,
    syncing,
    time_completed,
  } = props;

  const dispatch = useDispatch();

  const { isOnline } = useSelector((state) => state.apiHealth);
  const { settings: user_settings } = useSelector((state) => state.userSettings);

  const user_settings_loading = user_settings?.isPending;

  const { isLoggedIn, login } = useContext(AuthContext);

  const [new_wo_step, setNewWOStep] = useState(undefined);

  const timezone = user_settings?.timezone ?? TIMEZONES.central.value; // update to constant

  const checkAuthentication = async () => {
    if (isOnline && !isLoggedIn) {
      login();
    }
  };

  const handleResourceSync = (type, resource_list = null) => {
    const token = localStorage.getItem('access_token');
    if (resource_list) {
      worker.postMessage({
        type,
        resourceList: resource_list,
        token,
      });
    } else {
      worker.postMessage({
        type,
      });
    }
  };

  const getSyncTimeouts = () => {
    const now = moment().tz(timezone);
    const last_sync = moment.tz(time_completed * 1000, timezone);

    const midnight = now.clone().add(1, 'days').startOf('day').add(1, 'minute');
    const in_three_hours = now.clone().add(3, 'hours');

    const seconds_until_midnight = midnight.diff(last_sync, 'seconds');
    const seconds_until_three_hrs = in_three_hours.diff(last_sync, 'seconds');

    return { seconds_until_midnight, seconds_until_three_hrs };
  };

  // sync if the last full sync was yesterday
  const shouldRunDailySync = () => {
    const now = Date.now();
    const midnight = new Date().setHours(0, 0, 0, 0);
    const seconds_since_midnight = Math.abs(midnight - now);
    return getSecondsSinceLastAppLoadSync() > seconds_since_midnight;
  };

  // sync every 3 hours if autosync is turned on
  const shouldRunAutoSync = () => {
    const threshold = 3 * 60 * 60 * 1000;
    const threshold_met = getSecondsSinceLastAppLoadSync() > threshold;
    const auto_sync_enabled = user_settings?.auto_sync_enabled ?? true;
    return threshold_met && auto_sync_enabled;
  };

  // daily pruning of logs, 30 day logs, 7 day notifications
  const truncateLogs = async () => {
    const site_logs = await getSiteLogs();
    const thirty_days_ago = moment.tz(timezone).subtract(30, 'days');
    const seven_days_ago = moment.tz(timezone).subtract(7, 'days');
    const logs_to_delete = site_logs.filter((l) => (
      moment(l.timestamp).isBefore(thirty_days_ago) && l.display === false
    ));
    const notifications_to_delete = site_logs.filter((l) => (
      moment(l.timestamp).isBefore(seven_days_ago) && l.display === true
    ));
    const ids_to_delete = [...logs_to_delete, ...notifications_to_delete].map((l) => l.id);
    await deleteSiteLogs(ids_to_delete);
  };

  const checkSync = () => {
    checkAuthentication();

    // wait for user_settings to populate so the sync only runs once
    if (user_settings_loading || !user_settings) return;

    if (isLoggedIn) {
      const sendResourceListsToWorker = () => {
        handleResourceSync('resource_sync', resourceList);
        handleResourceSync('recent_sync', recentDataList);
      };

      if (shouldRunDailySync()) {
        truncateLogs();
        appLoadSync('Daily').then(sendResourceListsToWorker);
      } else if (shouldRunAutoSync()) {
        appLoadSync('Automatic (3 Hour)').then(sendResourceListsToWorker);
      } else {
        sendResourceListsToWorker();
      }
    }

    return () => {
      handleResourceSync('delete_resource_sync');
      handleResourceSync('delete_recent_sync');
    };
  };

  useEffect(() => {
    dispatch(getUserSettings());
    dispatch(getCallTypes());
    dispatch(getUserMechanic());
    if (isOnline) checkSync();
  }, []);

  // set timeouts for daily & 3 hour sync everytime the sync runs
  useEffect(() => {
    if (time_completed === 0) return;

    const timeouts = getSyncTimeouts();
    Object.keys(timeouts).map((key) => {
      const interval = setTimeout(() => {
        checkSync();
      }, timeouts[key]);
      return () => clearTimeout(interval);
    });
  }, [time_completed]);

  useEffect(() => {
    checkSync();
  }, [isLoggedIn, isOnline, user_settings_loading, user_settings, location]);

  if (!isLoggedIn) return <Spinner text="Authenticating" />;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <TopBar {...props} logo={logo} />
      </Layout.Header>
      <Layout.Content>
        <SideBar menuItems={MainMenu({ ...props, setNewWOStep, user_settings })} />
        <Layout style={{
          padding: '0 24px 24px', minHeight: 'calc(100vh - 64px)', marginTop: 64, marginLeft: 200, overflowY: 'auto',
        }}
        >
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <Dashboard {...{ ...props, setNewWOStep }} />
              )}
            />
            <Route exact path="/workorder/:wid" component={WorkOrder} />
            <Route exact path="/jsa/:jid" component={JSA} />
            <Route exact path="/vehicleinspection/:viid?" component={VehicleInspection} />
            <Route path="/inventorytransfer" component={InventoryTransfer} />
            <Route path="/unittransfer" component={UnitTransfer} />
            <Route path="/recent/workorders" component={RecentWorkOrders} />
            <Route path="/research/assignedunits" component={AssignedUnits} />
            <Route path="/research/workorders" component={WorkOrders} />
            <Route exact path="/workorder/view/:wid" component={WorkOrderView} />
            <Route exact path="/workorder/edit/:wid" component={WorkOrderEditView} />
            <Route exact path="/jsa/view/:jid" component={JsaView} />
            <Route path="/recent/inventorytransfers" component={RecentInventoryTransfers} />
            <Route path="/recent/unittransfers" component={RecentUnitTransfers} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Layout.Content>
      <NewWOModal
        syncing={syncing}
        step={new_wo_step}
        setStep={setNewWOStep}
        hideModal={() => { setNewWOStep(null); }}
        history={history}
      />
    </Layout>
  );
};

export default syncConsumer(workerConsumer(App));
