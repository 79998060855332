import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';

import { Table } from 'antd';
import moment from 'moment';
import 'moment-timezone';

import db from '../../db';
import { PageHeader } from '../shared';

import {
  addKeys,
  guessSorter,
  convertCentralToDate,
  isDefined,
  removeDuplicateRecords,
  sortObjectsBy,
} from '../../lib/utils';
import { TIMEZONES } from '../../config/constants/date-time';

const RecentUnitTransfers = () => {
  const userSettings = useSelector((state) => state.userSettings);

  const [recentTransfers, setRecentTransfers] = useState([]);
  const [locationMapping, setLocationMapping] = useState([]);
  const [loadingUnitTransfers, setLoadingUnitTransfers] = useState(false);

  const { value: timezone, label: timezone_label } = userSettings?.timezone ?? TIMEZONES.central;

  const getDateSubmitted = (submittedDate, record) => {
    if (submittedDate) return moment(convertCentralToDate(submittedDate, timezone)).format('L');

    // As of 11/29/22 version is a unix datetime stamp
    const versionAsDate = new Date(record.version * 1000);
    return moment(convertCentralToDate(versionAsDate, timezone)).format('L');
  };

  const getCustomerLocationsList = async () => {
    const locations = await db.customer_locations.toArray();
    const mapping = locations.reduce(
      (acc, loc) => ({ ...acc, [loc.id]: `${loc.address_label} (${loc.customer_name})` }),
      {},
    );
    setLocationMapping(mapping);
  };

  const getRecentTransfers = async () => {
    const start = moment.tz(timezone).subtract(3, 'months').format();
    const end = moment.tz(timezone).format();
    const completed_transfers = await db.recent_unit_transfers.toArray();
    const pending_transfers = await db.unit_transfers
      .where('date_submitted')
      .between(start, end, true, true)
      .toArray();
    const all_transfers = [...completed_transfers, ...pending_transfers];
    const transfers = removeDuplicateRecords(all_transfers, ['flogistix_id']);
    setRecentTransfers(addKeys(transfers));
    setLoadingUnitTransfers(false);
  };

  useEffect(() => {
    setLoadingUnitTransfers(true);
    getCustomerLocationsList();
    getRecentTransfers();
  }, []);

  const cols = [
    {
      title: `Date Submitted (${timezone_label})`,
      key: 'date_submitted',
      render: (date) => moment(convertCentralToDate(date, timezone)).format('L LTS'),
    },
    {
      title: 'Transfer ID',
      key: 'flogistix_id',
    },
    {
      title: 'Transfer From',
      key: 'transfer_from_customer_location_id',
      render: (id) => locationMapping[id],
    },
    {
      title: 'Transfer To',
      key: 'transfer_to_customer_location_id',
      render: (id) => locationMapping[id],
    },
    {
      title: 'Transfer Date',
      key: 'transfer_date',
      render: (date, record) => getDateSubmitted(date, record),
    },
    {
      title: 'Uploaded',
      key: 'synced',
      width: 100,
      // recentUTs don't have this column, default to true since they're from pg (uploaded)
      render: (v) => ((isDefined(v) ? v : true) ? 'Yes' : 'No'),
    },
  ];

  const recent_transfers_cols = cols.map((col) => ({
    ...col,
    dataIndex: col.key,
    sorter: (a, b) => guessSorter(a, b, col.key),
  }));

  return (
    <div>
      <PageHeader
        title="Recent Unit Transfers"
        subtext="Showing recent unit transfers for past 3 months"
      />
      <Table
        loading={loadingUnitTransfers}
        dataSource={sortObjectsBy(recentTransfers, 'date_submitted').reverse()}
        columns={recent_transfers_cols}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'auto', y: 720 }}
      />
    </div>
  );
};

export default withRouter(RecentUnitTransfers);
