import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Col, Row, Spin, Form, Button, Alert, message, Empty,
} from 'antd';

import moment from 'moment';
import 'moment-timezone';
import { SearchOutlined } from '@ant-design/icons';
import { AntdFormField, PageHeader, StyledTable } from '../shared';
import { UserSettingsContext } from '../UserSettings/UserSettingsProvider';
import { AuthContext } from '../../hoc/auth/Provider';
import { getWorkOrders } from '../../api/work-order';
import { useResources, useSearchParams } from '../hooks';
import { h2sLocation } from '../utils/unit';
import {
  addKeys,
  removeNullsFromObject,
  convertDateToCentral,
  guessSorter, convertCentralToDate,
} from '../../lib/utils';

import setSearchParams from './set-search-params';

import { TIMEZONES } from '../../config/constants/date-time';

const WorkOrders = (props) => {
  const { history } = props;

  const { user_settings } = useContext(UserSettingsContext);
  const { is_online } = useContext(AuthContext);

  const [form] = Form.useForm();

  const { locationPathname, searchParams } = useSearchParams();
  const { resources } = useResources(['mechanics', 'units', 'customers', 'customer_locations']);
  const {
    mechanics, units, customers, customer_locations,
  } = resources;

  const [loading, setLoading] = useState(false);
  const [work_orders, setWorkOrders] = useState([]);
  const [error, setError] = useState(false);
  const [fields_changed, setFieldsChanged] = useState(true);
  const [noResultsMessage, setNoResultsMessage] = useState('Please search to research work orders.');

  const { value: timezone, label: timezone_label } = user_settings?.timezone ?? TIMEZONES.central; // update to constant

  const searchWorkOrders = (filters) => {
    setLoading(true);
    Promise.all([
      getWorkOrders(filters),
    ]).then((res) => {
      if (res[0].length === 0) setNoResultsMessage('No Results. Please refine your search and try again.');
      setWorkOrders(addKeys(res[0], 'flogistix_id'));
      setLoading(false);
    }).catch(() => {
      setNoResultsMessage('No Results. Please refine your search and try again.');
      setLoading(false);
    });
  };

  const onSearch = () => {
    // don't make a request if there is no internet
    if (!is_online) return message.error('Internet is required to search work orders!', 5);

    // don't make a request if the search criteria hasn't changed to avoid db load
    if (!fields_changed) return false;

    setError(false);
    setFieldsChanged(false);

    const fields = form.getFieldsValue();
    const filtered_fields = removeNullsFromObject(fields);
    const non_mechanic_fields = Object.keys(filtered_fields).filter((key) => (
      key !== 'mechanic_id'
    ));
    if (non_mechanic_fields.length === 0) {
      setError(true);
      return;
    }

    // antd casts everything as a moment obj with an offset, ignoring user_settings
    // have to init a new moment with user_settings tz, then convert to central for DB dates
    const date_from = convertDateToCentral(fields.date_from || '01/01/2000', timezone);
    const date_to = convertDateToCentral(fields.date_to, timezone);

    const params = { ...filtered_fields, date_from, date_to };
    const queryParams = new URLSearchParams(params).toString();
    history.replace({ location: locationPathname, search: queryParams });
    searchWorkOrders(params);
  };

  const onClear = () => {
    history.replace({ location: locationPathname });
    setNoResultsMessage('Please search to research work orders.');
    setWorkOrders([]);
    form.resetFields();
  };

  // allow them to re-search even if values haven't changed, assuming they searched while offline
  useEffect(() => setFieldsChanged(true), [is_online]);
  useEffect(() => {
    if (Object.keys(searchParams).length === 0) return;

    for (const [key, value] of Object.entries(searchParams)) {
      setSearchParams(form, { [key]: value });
      searchWorkOrders(searchParams);
    }
  }, []);

  if (!user_settings || Object.keys(resources).length === 0) return <Spin />;

  const cols = [
    {
      title: `Date Completed (${timezone_label})`,
      key: 'end_time',
      render: (date) => moment(convertCentralToDate(date, timezone)).format('L LTS'),
    },
    {
      title: 'Work Order ID',
      key: 'flogistix_id',
    },
    {
      title: 'Asset',
      key: 'asset_number',
    },
    {
      title: 'Mechanic',
      key: 'mechanic_name',
    },
    {
      title: 'Customer Name',
      key: 'customer_name',
    },
    {
      title: 'View',
      key: 'view',
      width: 100,
      render: (_, row) => (
        <Link to={() => `/workorder/view/${row.flogistix_id}`} className="ant-btn ant-btn-round">
          <SearchOutlined />
        </Link>
      ),
    },
  ];

  const columns = cols.map((col) => (
    {
      ...col,
      dataIndex: col.key,
      sorter: (a, b) => guessSorter(a, b, col.key),
    }
  ));

  const mechanic_options = mechanics.map((m) => (
    { value: m.user_id, label: `${m.first_name} ${m.last_name}` }
  ));
  const unit_options = units.map((u) => (
    { value: u.id, label: u.number + (h2sLocation(u) ? ' (H2S)' : '') }
  ));
  const customer_options = customers.map((c) => (
    { value: c.id, label: c.name }
  ));
  const customer_location_options = customer_locations.map((l) => (
    { value: l.id, label: l.address_label }
  ));

  // keep existing work orders results even if they go offline
  // otherwise display a warning message
  const offline_warning = (
    <Alert
      style={{ marginBottom: '15px' }}
      message="Connection Error"
      type="error"
      showIcon
      description="This page requires that you are connected to the internet. Ensure your connection is stable and try again."
    />
  );

  return (
    <div>
      <PageHeader
        title="Work Order Search"
      />
      { !is_online && work_orders.length === 0 ? offline_warning : (
        <>
          { error
          && (
            <Alert
              type="error"
              message="Error!"
              description="Please provide at least one search criteria besides mechanic selection"
              showIcon
            />
          )}
          <Form
            style={{ marginTop: '15px' }}
            name="SearchWorkOrders"
            form={form}
            layout="vertical"
            onFieldsChange={() => setFieldsChanged(true)}
          >
            <Row gutter={[12, 12]}>
              <Col span={3}>
                <AntdFormField
                  type="select"
                  name="mechanic_id"
                  label="Mechanic"
                  required={false}
                  options={mechanic_options}
                  allowClear
                />
              </Col>
              <Col span={3}>
                <AntdFormField
                  type="multiselect"
                  name="asset_id"
                  label="Unit Number"
                  required={false}
                  options={unit_options}
                />
              </Col>
              <Col span={5}>
                <AntdFormField
                  type="select"
                  name="customer_id"
                  label="Customer"
                  required={false}
                  options={customer_options}
                  allowClear
                />
              </Col>
              <Col span={5}>
                <AntdFormField
                  type="select"
                  name="customer_location_id"
                  label="Customer Location"
                  required={false}
                  options={customer_location_options}
                  allowClear
                />
              </Col>
              <Col span={4}>
                <AntdFormField
                  type="datepicker"
                  name="date_from"
                  label="Date From"
                  required={false}
                />
              </Col>
              <Col span={4}>
                <AntdFormField
                  type="datepicker"
                  name="date_to"
                  label="Date To"
                  required={false}
                />
              </Col>
            </Row>
            <Row gutter={[12, 12]} justify="end">
              <Col>
                <Button
                  onClick={onClear}
                  block
                >
                  Clear
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={onSearch}
                  block
                >
                  Search
                </Button>
              </Col>

            </Row>
          </Form>
          <StyledTable
            zebra
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={noResultsMessage} /> }}
            loading={loading}
            columns={columns}
            dataSource={work_orders}
          />
        </>
      )}
    </div>
  );
};

export default WorkOrders;
