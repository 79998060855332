import {
  GET_USER_SETTINGS, GET_USER_SETTINGS_SUCCESS, GET_USER_SETTINGS_FAILED,
  GET_MECHANIC, GET_MECHANIC_SUCCESS, GET_MECHANIC_FAILED,
  UPDATE_USER_SETTINGS, UPDATE_USER_SETTINGS_SUCCESS, UPDATE_USER_SETTINGS_FAILED,
  UPDATE_MECHANIC, UPDATE_MECHANIC_SUCCESS, UPDATE_MECHANIC_FAILED,
} from '../actionTypes';
import {
  getUserSettings as getUserSettingsDb,
  updateUserSettings as updateUserSettingsDb,
  getUserMechanic as getUserMechanicRecord,
  updateUserMechanic as updateMech,
  getMechanics,
} from '../../db/api';

export const getUserSettings = () => async (dispatch) => {
  dispatch({ type: GET_USER_SETTINGS });
  try {
    const response = await getUserSettingsDb();

    dispatch({ type: GET_USER_SETTINGS_SUCCESS, response });
  } catch (error) {
    dispatch({ type: GET_USER_SETTINGS_FAILED });
  }
};

export const getUserMechanic = () => async (dispatch) => {
  dispatch({ type: GET_MECHANIC });
  try {
    const response = await Promise.all([getMechanics(), getUserMechanicRecord()])
      .then(([mechanicList, userMechanicRes]) => {
        /* getMechanics calls the http://flogistix.com/mechanic/info endpoint.
          * This response appears to be set outside of the API possible firebase. Therefore,
          * We are merging that data with the fieldservice.mechanic table to have all
          * neccessary user/mechanic info available
          */
        const userMech = userMechanicRes.at(0);
        if (!userMech) console.error('No user created');

        const additionalMechData = mechanicList.find((m) => m.user_id === userMech.id);
        const fullMechRecord = { ...userMech, ...additionalMechData };
        if (process.env.NODE_ENV !== 'production' && !userMech?.mechanic_number) {
          fullMechRecord.mechanic_number = 1259;
        }

        return fullMechRecord;
      });

    dispatch({ type: GET_MECHANIC_SUCCESS, response });
  } catch (error) {
    dispatch({ type: GET_MECHANIC_FAILED });
  }
};

export const updateUserSettings = () => async (dispatch) => {
  dispatch({ type: UPDATE_USER_SETTINGS });
  try {
    const response = await updateUserSettingsDb();

    dispatch({ type: UPDATE_USER_SETTINGS_SUCCESS, response });
  } catch (error) {
    dispatch({ type: UPDATE_USER_SETTINGS_FAILED });
  }
};

export const updateMechanic = () => async (dispatch) => {
  dispatch({ type: UPDATE_MECHANIC });
  try {
    const response = await updateMech();

    dispatch({ type: UPDATE_MECHANIC_SUCCESS, response });
  } catch (error) {
    dispatch({ type: UPDATE_MECHANIC_FAILED });
  }
};
