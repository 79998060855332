import {
  update, remove, append, prop, propEq, findIndex, pathOr, assocPath,
} from 'ramda';
import db from '.';

export const getJSA = async (id) => db.jsas.get(id);

export const updateJSAValue = async (jsa_id, path, value) => {
  if (!jsa_id) {
    throw new Error('JSA id is required for update');
  }
  const jsa = await getJSA(jsa_id);
  const updated_jsa = assocPath(path, value, jsa);
  return db.jsas.put(updated_jsa);
};

export const updateJSAList = async (jsa_id, list_path, list_id_prop, value) => {
  if (!jsa_id) {
    throw new Error('JSA id required for update.');
  }
  const jsa = await getJSA(jsa_id);
  const list = pathOr(null, list_path, jsa);
  if (!list) {
    const updated_jsa = assocPath(list_path, [value], jsa);
    // update dexie with updated jsa
    return db.jsas.put(updated_jsa);
  }
  // get index
  const index = findIndex(propEq(list_id_prop, prop(list_id_prop, value)), list);

  if (index === -1) {
    // add new item to list
    const new_list = append(value, list);
    // update wo with new list
    const updated_jsa = assocPath(list_path, new_list, jsa);
    // update dexie with updated jsa
    return db.jsas.put(updated_jsa);
  }
  // update list with new value for value at index
  const new_list = update(index, value, list);
  // update jsa with new list
  const updated_jsa = assocPath(list_path, new_list, jsa);
  // update dexie with updated jsa
  return db.jsas.put(updated_jsa);
};

export const removeItemFromJSAList = async (jsa_id, list_path, list_id_prop, value) => {
  if (!jsa_id) {
    throw new Error('JSA id required for update.');
  }
  const jsa = await getJSA(jsa_id);
  const list = pathOr(null, list_path, jsa);
  if (!list) {

  } else {
    const index = findIndex(propEq(list_id_prop, prop(list_id_prop, value)), list);
    const new_list = remove(index, 1, list);
    const updated_jsa = assocPath(list_path, new_list, jsa);
    return db.jsas.put(updated_jsa);
  }
};
