import React from 'react';
import Workorder from './index';

// Need to put this in a separate component
//   because react-router was not playing well with rendering the same component
//   between the /workorder/:wid path and the /workorder/view/:wid path
// render: () => <Workorder/> would not re-render if jumping between view and edit page
// component: () => <Workorder /> threw console errors about updating state on unmounted components
const WorkorderView = (props) => (
  <Workorder {...props} read_only />
);

export default WorkorderView;
