import React from 'react';
import { Select } from 'antd';
import { addKeys } from '../../lib/utils';

const StyledSelect = ({ label, options: opts = [], ...props }) => {
  const options = opts.length > 0 && Object.keys(opts[0]).includes('key')
    ? opts
    : addKeys(opts);

  const defaults = {
    showSearch: true,
    placeholder: 'Start Typing to Search...',
    optionFilterProp: 'label',
    style: { width: '100%' },
  };
  const select_props = {
    ...defaults,
    ...props,
    options,
  };

  return (
    <div>
      <span>{label}</span>
      <Select
        {...select_props}
      />
    </div>
  );
};

export default StyledSelect;
