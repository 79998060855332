import { useEffect, useState } from 'react';
import BasicInformation from '../work-order/BasicInformation';
import PMChecklist from '../work-order/PMChecklist';
import UnitReadings from '../work-order/UnitReadings';
import Notes from '../work-order/Notes';
import PartsLaborCodes from '../work-order/PartsAndLaborCodes';
import Emissions from '../work-order/Emissions';
import NewsetChecklist from '../work-order/NewsetChecklist';
import PumpPMChecklist from '../work-order/PumpPMChecklist';
import TroubleCall from '../work-order/TroubleCall';

const useWorkOrderTabs = (props) => {
  const {
    jsa, work_order, work_order_call_types, selected_labor_codes, selected_unit, resources, resource_loading,
  } = props;

  const [tabs, setTabs] = useState([]);
  const [data, setData] = useState([]);

  const {
    units,
    customers,
    customer_locations,
    laborcodes: labor_codes,
    calltype_laborcodes: call_type_labor_codes,
    warehouses,
    mechanics,
    pmtemplates: pm_templates,
    calltype_notetypes,
    reasons,
    parts,
    pumps,
  } = data;

  useEffect(() => {
    if (resource_loading === true) return;

    setData(resources);
  }, [resource_loading]);

  useEffect(() => {
    const dynamic_sections = [
      {
        active: [1],
        anchor: 'pm',
        title: 'PM Checklist',
        comp: PMChecklist,
        props: {
          ...props,
          units,
          pm_templates,
        },
      },
      {
        active: [2],
        anchor: 'trouble_call',
        title: 'Call Out Information',
        comp: TroubleCall,
        props: {
          ...props,
          reasons,
        },
      },
      {
        active: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 15],
        anchor: 'units',
        title: 'Unit Readings',
        comp: UnitReadings,
        props: {
          ...props,
        },
      },
      {
        active: [12],
        anchor: 'pump',
        title: 'Pump PM Checklist',
        comp: PumpPMChecklist,
        props: {
          ...props,
          calltype_notetypes,
          pumps,
        },
      },
      {
        active: [6],
        anchor: 'newset',
        title: 'New Set Checklist',
        comp: NewsetChecklist,
        props: {
          ...props,
          mechanics,
          warehouses,
        },
      },
      {
        active: [11],
        anchor: 'emissions',
        title: 'Emissions',
        comp: Emissions,
        props: {
          ...props,
          reasons,
        },
      },
    ];
    const work_order_sections = [
      ...dynamic_sections,
      {
        active: [3],
        anchor: 'labor',
        title: 'Labor Codes',
        comp: PartsLaborCodes,
        props: {
          ...props,
          parts,
          labor_codes,
          call_type_labor_codes,
          selected_labor_codes,
        },
      },
      {
        active: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15],
        anchor: 'parts',
        title: 'Parts/Labor Codes',
        comp: PartsLaborCodes,
        props: {
          ...props,
          parts,
          labor_codes,
          call_type_labor_codes,
        },
      },
      {
        active: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15],
        anchor: 'notes',
        title: 'Notes',
        comp: Notes,
        props: {
          ...props,
          calltype_notetypes,
          work_order_call_types,
          selected_labor_codes,
          per_row: 4,
        },
      },
    ];

    // always have basic information
    const basic_information = {
      active: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15],
      anchor: 'basic',
      title: 'Basic Information',
      comp: BasicInformation,
      props: {
        ...props,
        customers,
        customer_locations,
        mechanics,
        units,
      },
    };

    const filteredTabs = work_order_sections.filter((tab) => {
      const { active } = tab;
      return active.some((i) => work_order_call_types.includes(i));
    });

    // make sure basic info is always visible & on top
    const active_tabs = work_order ? [basic_information, ...filteredTabs] : [];

    setTabs(active_tabs);
  }, [work_order, jsa, work_order_call_types, selected_labor_codes, selected_unit]);

  return tabs;
};

export default useWorkOrderTabs;
