import {
  HomeOutlined,
  BookOutlined,
  ShoppingCartOutlined,
  RetweetOutlined,
  ClockCircleOutlined,
  DiffOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import useWorkOrderHelpers from '../components/hooks/use-work-order-helpers';

const MainMenu = (props) => {
  const { setNewWOStep, history } = props;

  const { openWorkOrder } = useWorkOrderHelpers({ history, setNewWOStep });

  const workOrderAction = async () => {
    openWorkOrder();
  };

  return [
    {
      title: 'Dashboard',
      path: '/',
      icon: HomeOutlined,
    },
    {
      title: 'Work Order',
      action: workOrderAction,
      icon: BookOutlined,
    },
    {
      title: 'Inventory Transfer',
      path: '/inventorytransfer',
      icon: ShoppingCartOutlined,
    },
    {
      title: 'Unit Transfer',
      path: '/unittransfer',
      icon: RetweetOutlined,
    },
    {
      title: 'My Recents',
      icon: ClockCircleOutlined,
      children: [
        {
          title: 'Work Orders',
          path: '/recent/workorders',
        },
        {
          title: 'Inventory Transfers',
          path: '/recent/inventorytransfers',
        },
        {
          title: 'Unit Transfers',
          path: '/recent/unittransfers',
        },
      ],
    },
    {
      title: 'Research',
      icon: DiffOutlined,
      children: [
        {
          title: 'Work Orders',
          path: '/research/workorders',
        },
        {
          title: 'PM Schedule',
          path: '/research/assignedunits',
        },
      ],
    },
    {
      title: 'External Links',
      icon: LinkOutlined,
      children: [
        {
          title: 'Flux',
          path: 'https://flux.axil.ai/',
          external: true,
        },
        {
          title: 'MSDS',
          path: 'https://msdsmanagement.msdsonline.com/ca0cdb17-9054-4841-85fd-67e1cec18d8e/ebinder/?nas=True',
          external: true,
        },
        {
          title: 'KPA',
          path: 'https://flogistix.kpaehs.com/',
          external: true,
        },
        {
          title: 'Paycom',
          path: 'https://www.paycomonline.net/v4/ee/web.php/app/login',
          external: true,
        },
      ],
    },
  ];
};

export default MainMenu;
