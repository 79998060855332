import React from 'react';

import {
  Card, Statistic, Typography, Divider,
} from 'antd';

const TimeDisplayBox = (props) => {
  const {
    name, time, valueStyle,
  } = props;

  return (
    <div className="grid-1" span={5}>
      <Card style={{ height: '100%' }}>
        <Statistic
          title={(
            <>
              <Typography.Title level={5}>{name}</Typography.Title>
              <Divider />
            </>
                )}
          value={time}
          valueStyle={valueStyle}
        />
      </Card>
    </div>
  );
};

export default TimeDisplayBox;
